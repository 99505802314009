import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';


import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class othersDownload extends PureComponent {

    render() {
        document.title = "Download others - Al Quran Technobd";
        return (
            <Fragment>


                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-sm-12 col-md-12">
                        <div className="download-section">
                            <div className="download-section-title">অন্যান্য ডাউনলোড</div>
                            <div className="download-other-section">
                                <div className="row">
                                    {/* <div className="col-sm-6 col-md-6 plr-5">
                                <div className="download-section-inner"> 
                                    <div className="download-heading">
                                        <div className="download-heading-left">
                                            <h5>আল কোরআন</h5>
                                        </div>  
                                    </div>
                                    <div className="para-item download-item">
                                        <ul>
                                            <li>
                                                <div className="download-item-list">
                                                <div className="para-number alquran-icon"></div>
                                                <div className="para-number-no">
                                                    টেক্সট
                                                    <span> Text</span>
                                                </div>
                                                </div> 
                                                
                                                <div className="download-item-para">
                                                    <button className="bangla-download" type="button"><i class="fas fa-cloud-download-alt"></i>ডাউনলোড </button>
                                                </div>
                                                
                                            </li>
                                        </ul>
                                    </div>
                                   
                                 </div> 
                              
                            </div> */}

                                    <div className="col-sm-6 col-md-6 plr-5">
                                        <div className="download-section-inner">
                                            <div className="download-heading">
                                                <div className="download-heading-left">
                                                    <h5>দোয়া</h5>
                                                </div>
                                            </div>
                                            <div className="para-item download-item">
                                                <ul>
                                                    <li>
                                                        <div className="download-item-list">
                                                            <div className="para-number text-icon">
                                                            </div>
                                                            <div className="para-number-no">
                                                                টেক্সট
                                                    <span> Text</span>
                                                            </div>
                                                        </div>

                                                        <div className="download-item-para">
                                                            <button className="bangla-download" type="button"><i class="fas fa-cloud-download-alt"></i>ডাউনলোড </button>
                                                        </div>

                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-sm-6 col-md-6 plr-5">
                                        <div className="download-section-inner">
                                            <div className="download-heading">
                                                <div className="download-heading-left">
                                                    <h5>কালিমা</h5>
                                                </div>
                                            </div>
                                            <div className="para-item download-item">
                                                <ul>
                                                    <li>
                                                        <div className="download-item-list">
                                                            <div className="para-number mp3-icon">
                                                            </div>
                                                            <div className="para-number-no">
                                                                এমপি3
                                                    <span>MP3</span>
                                                            </div>
                                                        </div>

                                                        <div className="download-item-para">
                                                            <button className="bangla-download" type="button"><i class="fas fa-cloud-download-alt"></i>ডাউনলোড </button>
                                                        </div>

                                                    </li>

                                                    <li>
                                                        <div className="download-item-list">
                                                            <div className="para-number mp3-icon">
                                                            </div>
                                                            <div className="para-number-no">
                                                                টেক্সট
                                                    <span>Text</span>
                                                            </div>
                                                        </div>

                                                        <div className="download-item-para">
                                                            <button className="bangla-download" type="button"><i class="fas fa-cloud-download-alt"></i>ডাউনলোড </button>
                                                        </div>

                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-sm-6 col-md-6 plr-5">
                                        <div className="download-section-inner">
                                            <div className="download-heading">
                                                <div className="download-heading-left">
                                                    <h5>হাদিস</h5>
                                                </div>
                                            </div>
                                            <div className="para-item download-item">
                                                <ul>
                                                    <li>
                                                        <div className="download-item-list">
                                                            <div className="para-number text2-icon">
                                                            </div>
                                                            <div className="para-number-no">
                                                                টেক্সট
                                                    <span>Text</span>
                                                            </div>
                                                        </div>

                                                        <div className="download-item-para">
                                                            <button className="bangla-download" type="button"><i class="fas fa-cloud-download-alt"></i>ডাউনলোড </button>
                                                        </div>

                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                        </div>

                    </div>

                </div>



            </Fragment>
        );
    }
}

export default withRouter(othersDownload);