import React, { Component, Fragment } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export class HadithCollections extends Component {

    scrollbars = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            hadithList: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        this.setState({ loading: true });
        const response = await fetch(
            'hadiths.json'
        ).then((response) => response.json());
        this.setState({ loading: false, hadithList:response });
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    render() {
        return (
            <Fragment>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-sm-12 col-md-12">
                        <div className="download-section new-hadith-search-list">
                            <div className="hadith-name-list">
                                <ul>
                                    <li>হাদিস গুচ্ছ </li>
                                </ul>
                            </div>
                            <div className="download-section-inner max-width-100 box-shadow-none">
                                <div className="nano">
                                    <div className="nano-content">
                                        <div className="para-item download-item">
                                            <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                {
                                                    this.state.loading ?
                                                        <div className="loading section-loading"> <i className="fas fa-spinner fa-spin"></i> </div>
                                                        :
                                                        <Fragment>
                                                            {
                                                                this.state.hadithList.length > 0 ?
                                                                    <Fragment>
                                                                        {
                                                                            this.state.hadithList.map((result, hadithList_index) => {
                                                                                return (
                                                                                    result.number.length > 0 ?
                                                                                    <div className="max-width-830 new-hadith-search-details-item" key={`hadith-details-${hadithList_index}`}>
                                                                                        <div className="new-hadith-search-details-bangla">
                                                                                            <p>{result.details}</p>
                                                                                            <div className="text-right">
                                                                                                 <span style={{fontSize:'14px',lineHeight:1.5}}>
                                                                                                     হাদিস নাম্বারঃ {result.number}<br/>
                                                                                                     {result.hadith}
                                                                                                 </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> : null

                                                                                )
                                                                            })
                                                                        }
                                                                    </Fragment>
                                                                    :
                                                                    <li>
                                                                        <div className="no_data_found">No Data Found.</div>
                                                                    </li>
                                                            }
                                                        </Fragment>
                                                }
                                            </Scrollbars>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </Fragment >

        )
    }
}

export default HadithCollections
