import React, { Component, Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { SET_COOKIE } from "../Constants/AppConstants";
import requireAuth from "../Utils/requireAuth";
import Login from "../Components/Auth/Login";
import Registration from "../Components/Auth/Registration";
import Profile from "../Components/Auth/Profile";
import ProfilePasswordChange from "../Components/Auth/ProfilePasswordChange";
// import ServiceLogin from "../Components/Auth/ServiceLogin";
import PasswordReset from "../Components/Auth/PasswordReset";
import NewPasswordReset from "../Components/Auth/NewPasswordReset";

// PublicSite Start
import Home from "../Components/Alquran/Home";
import SuraKrom from "../Components/Alquran/SuraKrom";
import ParaKrom from "../Components/Alquran/ParaKrom";
import Kalema from "../Components/Alquran/kalema";
import AlquranDownload from "../Components/Alquran/AlquranDownload";
import OthersDownload from "../Components/Alquran/OthersDownload";
import Dua from "../Components/Alquran/Dua";
import Hadiths from "../Components/Alquran/Hadiths";
import HadithIndexDetails from "../Components/Alquran/HadithIndexDetails";
import HadithDetailsSingle from "../Components/Alquran/HadithDetailsSingle";
// import Hadiths from "../Components/Alquran/Hadiths";
import HadithsBukhari from "../Components/Alquran/HadithsBukhari";
import HadithsMuslim from "../Components/Alquran/HadithsMuslim";
import NamazTiming from "../Components/Alquran/NamazTiming";
import Disclaimer from "../Components/Alquran/Disclaimer";
import NewHadithSearch from "../Components/Alquran/new-hadith-search";
import NewMuslimHadiths from "../Components/Alquran/new-muslim-hadiths";
import NewBukhariHadiths from "../Components/Alquran/new-bukhari-hadiths";
import NewTirmiziHadiths from "../Components/Alquran/new-tirmizi-hadiths";
import NewHadithSearchList from "../Components/Alquran/new-hadith-search-list";
import NewHadithSearchListDetails from "../Components/Alquran/new-hadith-search-list-details";
import HadithCollections from "../Components/Alquran/HadithCollections";
import privacyPolicy from "../Components/Alquran/privacyPolicy";
import aboutUs from "../Components/Alquran/aboutUs";
import AccountDeletion from "../Components/Alquran/AccountDeletion";
import AccountDeletionConfirm from "../Components/Alquran/AccountDeletionConfirm";
import AccountRestore from "../Components/Alquran/AccountRestore";



import termsAndCondition from "../Components/Alquran/termsAndCondition";
import AlquranSearch from "../Components/Alquran/AlquranSearch";
import HadithSearch from "../Components/Alquran/HadithSearch";
import NamesOfAllah from "../Components/Alquran/NamesOfAllah";
import PageNotFound from "../Components/Pages/PageNotFound";
import ContactUs from "../Components/Alquran/ContactUs";
import Test from "../Components/Pages/Test";

class AlquranRoutes extends Component {
  constructor(props) {
    super(props);
    const url = new URL(window.location.href);
  }

  render() {
    return (
      <Switch>
        <Route path="/login" component={Login} exact strict />
        <Route path="/registration" component={Registration} exact strict />
        <Route path="/profile" component={Profile} exact strict />
        <Route
          path="/change-password"
          component={ProfilePasswordChange}
          exact
          strict
        />
        {/* <Route path="/serviceLogin" component={ServiceLogin} exact strict /> */}
        <Route path="/password-reset" component={PasswordReset} exact strict />
        <Route
          path="/password-reset/:code"
          component={NewPasswordReset}
          exact
          strict
        />
        <Route path="/" component={SuraKrom} exact strict />
        <Route path="/index.html" component={SuraKrom} exact strict />
        <Route path="/bypara" component={ParaKrom} exact strict />
        <Route path="/kalema" component={Kalema} exact strict />
        <Route
          path="/aquran-Download"
          component={AlquranDownload}
          exact
          strict
        />
        <Route
          path="/others-download"
          component={OthersDownload}
          exact
          strict
        />
        <Route path="/dua" component={Dua} exact strict />
        <Route
          path="/hadiths/:hadith_book_id"
          component={Hadiths}
          exact
          strict
        />
        <Route
          path="/hadiths/:hadith_book_id/:index_id"
          component={HadithIndexDetails}
          exact
          strict
        />
        <Route
          path="/hadiths/:hadith_book_id/:index_id/:hadith_no"
          component={HadithIndexDetails}
          exact
          strict
        />
        <Route
          path="/hadiths-collections"
          component={HadithCollections}
          exact
          strict
        />
        {/* <Route path="/hadiths/:hadith_book_id/:index_id/:hadith_no" component={HadithDetailsSingle} exact strict /> */}
        <Route
          path="/search/hadiths/:hadith_book_id"
          component={HadithSearch}
          exact
          strict
        />
        {/* <Route path="/hadiths" component={Hadiths} exact strict /> */}
        {/* <Route path="/hadiths/bukhari" component={HadithsBukhari} exact strict />
                <Route path="/hadiths/muslim" component={HadithsMuslim} exact strict /> */}
        <Route path="/namaz-timing" component={NamazTiming} exact strict />
        <Route path="/disclaimer" component={Disclaimer} exact strict />
        <Route
          path="/new-hadith-search"
          component={NewHadithSearch}
          exact
          strict
        />
        <Route
          path="/new-muslim-hadiths"
          component={NewMuslimHadiths}
          exact
          strict
        />
        <Route
          path="/new-bukhari-hadiths"
          component={NewBukhariHadiths}
          exact
          strict
        />
        <Route
          path="/new-tirmizi-hadiths"
          component={NewTirmiziHadiths}
          exact
          strict
        />
        <Route
          path="/new-hadith-search-list"
          component={NewHadithSearchList}
          exact
          strict
        />
        <Route
          path="/new-hadith-search-list-details"
          component={NewHadithSearchListDetails}
          exact
          strict
        />
        <Route path="/about-us" component={aboutUs} exact strict />
        <Route path="/account-deletion" component={AccountDeletion} exact strict />
        <Route path="/account-deletion/:token" component={AccountDeletionConfirm} exact strict />
        <Route path="/account-restore" component={AccountRestore} exact strict />
        <Route path="/privacy-policy" component={privacyPolicy} exact strict />
        <Route
          path="/terms-and-condition"
          component={termsAndCondition}
          exact
          strict
        />
        <Route path="/alquran-search" component={AlquranSearch} exact strict />
        {/* <Route path="/hadith-search" component={hadithSearch} exact strict /> */}
        <Route
          path="/99-names-of-allah"
          component={NamesOfAllah}
          exact
          strict
        />
        <Route path="/contact-us" component={ContactUs} exact strict />
        <Route path="/test" component={Test} exact strict />
        <Route component={PageNotFound} exact strict />
      </Switch>
    );
  }
}

export default withRouter(AlquranRoutes);
