import React, { PureComponent, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MetisMenu from "metismenujs";
import { logout } from "../../Store/actions/loginActions";
import {
  AJAX_REQUEST,
  AJAX_PUBLIC_REQUEST,
  REMOVE_STORAGE,
  USER,
  DELETE_LOGIN_COOKIE,
} from "../../Constants/AppConstants";
import * as Bootstrap from "react-bootstrap";
import history from "../../history";
import { Scrollbars } from "react-custom-scrollbars";
import { isMobile, isAndroid, isWinPhone, isIOS } from "react-device-detect";

class Sidebar extends PureComponent {
  scrollbars = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      menu_open: false,

      //advanced search
      sura_name: "",
      sura_name2: "",
      ayat_no: "",
      ayat_no2: "",
      para_name: "",
      para_name2: "",

      //hadith menu
      hadith_lists: [],
    };
  }
  componentDidMount() {
    MetisMenu.attach("#menu");
    this.getSidebarHadithMenu();
    // if (isMobile) {
    //     document.querySelector('body').classList.add('sidebar-nav');
    //     this.setState({ menu_open: false })
    // }
  }

  logout = (e) => {
    e.preventDefault();
    // AJAX_REQUEST("POST", "user/logout", {}).then(results => {
    //     if (parseInt(results.response.code) === 1000) { } else {
    //         // console.log(results.response.message);
    //     }
    // });
    this.props.logout();
  };

  addClassToBody = () => {
    document.querySelector("body").classList.add("sidebar-nav");
    this.setState({
      menu_open: false,
    });
  };

  removeClassToBody = () => {
    document.querySelector("body").classList.remove("sidebar-nav");
    this.setState({
      menu_open: true,
    });
  };

  getSidebarHadithMenu = () => {
    AJAX_PUBLIC_REQUEST("POST", "hadith/hadith/list", {}).then((results) => {
      if (results.type === "success") {
        const hadith_lists = results.data;
        this.setState({
          hadith_lists: hadith_lists,
        });
      } else {
        // this.setState({
        //     error: Parser("<p classNameName='text-danger'>" + results.error + "</p>")
        // })
      }
    });
  };

  //advanced search
  toggleModalE = (e, value) => {
    e.preventDefault();
    this.setState({ show_modal: value });
  };
  toggleModal = (value) => {
    this.setState({ show_modal: value });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitAdvancedSearch = (e, finder = "sura") => {
    e.preventDefault();
    if (finder == "sura") {
      if (this.state.sura_name.length > 0) {
        this.setState({ show_modal: false });
        history.push("/alquran-search?sura_name=" + this.state.sura_name);
      }
    }
    if (finder == "sura_ayat") {
      if (this.state.sura_name2.length > 0 && this.state.ayat_no != "") {
        this.setState({ show_modal: false });
        history.push(
          "/alquran-search?sura_name=" +
            this.state.sura_name2 +
            "&ayat_no=" +
            this.state.ayat_no,
        );
      }
    }
    if (finder == "para") {
      if (this.state.para_name.length > 0) {
        this.setState({ show_modal: false });
        history.push("/alquran-search?para_name=" + this.state.para_name);
      }
    }
    if (finder == "para_ayat") {
      if (this.state.para_name2.length > 0 && this.state.ayat_no2 != "") {
        this.setState({ show_modal: false });
        history.push(
          "/alquran-search?para_name=" +
            this.state.para_name2 +
            "&ayat_no=" +
            this.state.ayat_no2,
        );
      }
    }
  };
  // advanced search ended

  logout = (e) => {
    e.preventDefault();
    this.props.logout();
    REMOVE_STORAGE(USER);
    DELETE_LOGIN_COOKIE();
  };

  render() {
    const { user } = this.props.auth;
    return (
      <Fragment>
        <div className="sidebar">
          <div className="sidebar-inner">
            <div className="logo">
              <NavLink className="open-nav" to="/">
                <img
                  src={require("../../Assets/images/logo.png")}
                  alt=""
                  title=""
                />
              </NavLink>
              <NavLink className="close-nav" to="/">
                <img
                  src={require("../../Assets/images/logo1.png")}
                  alt=""
                  title=""
                />
              </NavLink>
            </div>
            <div className="menu-toggle-box">
              <div className="menu-heading">মেনু</div>
              <div className="menu-button">
                {this.state.menu_open ? (
                  <button
                    id="menu_open"
                    onClick={this.addClassToBody}
                    type="button"
                  >
                    <i className="icofont-listing-box"></i>
                  </button>
                ) : (
                  <button
                    id="menu_open"
                    onClick={this.removeClassToBody}
                    type="button"
                  >
                    <i className="icofont-listing-box"></i>
                  </button>
                )}
              </div>
            </div>
            <div className="navbar-scroll-container">
              <div className="nano nano-sidebar">
                <div className="nano-content">
                  <Scrollbars
                    onScroll={this.handleScroll}
                    onScrollStop={this.handleScrollStop}
                    className="CusScrollbars"
                    id="CusScrollbars1"
                    autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={200}
                    style={{ height: "100%" }}
                    ref={this.scrollbars}
                  >
                    <div className="navbar-item">
                      <ul id="menu">
                        <li>
                          <NavLink to="/" activeClassName="active" exact>
                            <i className="item-icon icon-1"></i>
                            <span>আল কুরআন (সুরা ক্রমে)</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/bypara">
                            <i className="item-icon icon-2"></i>
                            <span>আল কুরআন (পারা ক্রমে)</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/alquran-search">
                            <i className="item-icon icon-search"></i>
                            <span>আল কুরআন র্সাচ</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/kalema">
                            <i className="item-icon icon-3"></i>
                            <span>কালেমা</span>
                          </NavLink>
                        </li>

                        {/* <li>
                                                    <a href="#" className="has-arrow" aria-expanded="true">
                                                        <i className="item-icon icon-4"></i>
                                                        <span>সহীহ হাদিস সমুহ</span>
                                                    </a>
                                                    <ul className="sub-menu">
                                                        {/* <li>
                                                            <NavLink to="/new-hadith-search">
                                                                <i className="item-icon icon-search"></i>
                                                                <span>হাদিসে র্সাচ</span>
                                                            </NavLink>
                                                        </li> */}
                        {/* <li>
                                                            <NavLink to="/hadiths/search">
                                                                <i className="item-icon icon-search"></i>
                                                                <span>হাদিসে র্সাচ</span>
                                                            </NavLink>
                                                        </li>
                                                        {
                                                            this.state.hadith_lists.length > 0 ?
                                                                <Fragment>
                                                                    {
                                                                        this.state.hadith_lists.map((hadith_lists_single, h_m_index) => {
                                                                            return (
                                                                                <li key={`haidths-menu-` + h_m_index}>
                                                                                    <NavLink to={`/hadiths/${hadith_lists_single.id}`}>
                                                                                        <i className="item-icon icon-4"></i>
                                                                                        <span>{hadith_lists_single.name_bn}</span>
                                                                                    </NavLink>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </Fragment>
                                                                :
                                                                ''
                                                        }
                                                        <li>
                                                            <NavLink to="/hadiths-collections">
                                                                <i className="item-icon icon-4"></i>
                                                                <span>হাদিস গুচ্ছ </span>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </li>

                                                {/* <li>
                                                    <NavLink to="/hadiths">
                                                        <i className="item-icon icon-4"></i>
                                                        <span>সহীহ হাদিস সমুহ</span>
                                                    </NavLink>
                                                </li> */}

                        <li>
                          <a
                            href="#"
                            onClick={(e) => this.toggleModalE(e, true)}
                          >
                            <i className="item-icon icon-search"></i>
                            <span>এডভান্সড র্সাচ</span>
                          </a>
                        </li>
                        <li>
                          <NavLink to="/99-names-of-allah">
                            <i className="item-icon icon-5"></i>
                            <span>আল্লাহর ৯৯ নাম</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/dua" activeClassName="active">
                            <i className="item-icon icon-6"></i>
                            <span>দোয়া সমুহ</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/namaz-timing" activeClassName="active">
                            <i className="item-icon icon-7"></i>
                            <span>নামাজের সময়সুচী</span>
                          </NavLink>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="has-arrow"
                            aria-expanded="true"
                          >
                            <i className="item-icon icon-13"></i>
                            <span>ডাউনলোড</span>
                          </a>
                          <ul className="sub-menu">
                            <li>
                              <NavLink
                                to="/aquran-download"
                                activeClassName="active"
                              >
                                <i className="fas fa-cloud-download-alt"></i>
                                <span>আল কোরআন</span>
                              </NavLink>
                            </li>
                            {/* <li>
                                                            <NavLink to='/others-download' activeClassName="active">
                                                                <i className="fas fa-cloud-download-alt"></i>
                                                                <span>অন্যান্য</span>
                                                            </NavLink>
                                                        </li> */}
                          </ul>
                        </li>
                        {this.props.isAuthenticated ? (
                          <Fragment>
                            <li className="">
                              <NavLink to="/profile" activeClassName="active">
                                <i className="item-icon icon-14"></i>
                                <span>আমার প্রোফাইল</span>
                              </NavLink>
                            </li>
                            <li className="mb60">
                              <a href="/logout" onClick={this.logout}>
                                <i className="item-icon icon-14"></i>
                                <span>লগ আউট</span>
                              </a>
                            </li>
                          </Fragment>
                        ) : (
                          <li className="mb60">
                            <NavLink to="/login" activeClassName="active">
                              <i className="item-icon icon-14"></i>
                              <span>লগইন</span>
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <NavLink to="/about-us" activeClassName="active">
                            <i className="item-icon icon-11"></i>
                            <span>About Us</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/disclaimer" activeClassName="active">
                            <i className="item-icon icon-11"></i>
                            <span>Disclaimer</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/privacy-policy"
                            activeClassName="active"
                          >
                            <i className="item-icon icon-11"></i>
                            <span>Privacy Policy</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/terms-and-condition"
                            activeClassName="active"
                          >
                            <i className="item-icon icon-11"></i>
                            <span>Terms and Conditions</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/contact-us" activeClassName="active">
                            <i className="item-icon icon-11"></i>
                            <span>যোগাযোগ</span>
                          </NavLink>
                        </li>
                        {/* {
                                                    this.props.isAuthenticated ?
                                                        <li className="mb60">
                                                            <a href="/logout" onClick={this.logout}>
                                                                <i className="item-icon icon-14"></i>
                                                                <span>লগ আউট</span></a>
                                                        </li>
                                                        :
                                                        <li className="mb60">
                                                            <NavLink to="/login" activeClassName="active">
                                                                <i className="item-icon icon-14"></i>
                                                                <span>লগইন</span>
                                                            </NavLink>
                                                        </li>
                                                } */}
                        <li
                          style={{
                            paddingLeft: 28,
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            marginTop: "20px",
                          }}
                        >
                          A Platform by{" "}
                          <a
                            href="https://technobd.com/"
                            target="_blank"
                            style={{
                              fontSize: "14px",
                              paddingLeft: "5px",
                              borderLeft: 0,
                              background: "transparent",
                              marginTop: "2px",
                            }}
                          >
                            Technobd Limited
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Bootstrap.Modal
          className="advanced-search-modal"
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show_modal}
          onHide={() => this.toggleModal(false)}
        >
          <Bootstrap.Modal.Header>
            <Bootstrap.Modal.Title id="contained-modal-title-vcenter">
              এডভান্সড র্সাচ
            </Bootstrap.Modal.Title>
          </Bootstrap.Modal.Header>
          <Bootstrap.Modal.Body>
            <div className="advanced-search">
              <Bootstrap.Form.Row>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="9">
                  <Bootstrap.Form.Label>সুরা</Bootstrap.Form.Label>
                  <Bootstrap.Form.Control
                    type="text"
                    name="sura_name"
                    onChange={this.changeHandler}
                    value={this.state.sura_name}
                    placeholder="সুরার নাম লিখুন..."
                  />
                </Bootstrap.Form.Group>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                  <Bootstrap.Form.Label>
                    &nbsp; &nbsp; &nbsp;
                  </Bootstrap.Form.Label>
                  <Bootstrap.Button
                    type="submit"
                    className="searchresult-btn"
                    onClick={(e) => this.submitAdvancedSearch(e, "sura")}
                  >
                    {" "}
                    খুঁজুন{" "}
                  </Bootstrap.Button>
                </Bootstrap.Form.Group>
              </Bootstrap.Form.Row>

              <Bootstrap.Form.Row>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="6">
                  <Bootstrap.Form.Label>সুরা ও আয়াত</Bootstrap.Form.Label>
                  <Bootstrap.Form.Control
                    type="text"
                    name="sura_name2"
                    onChange={this.changeHandler}
                    value={this.state.sura_name2}
                    placeholder="সুরার নাম লিখুন..."
                  />
                </Bootstrap.Form.Group>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                  <Bootstrap.Form.Label>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </Bootstrap.Form.Label>
                  <Bootstrap.Form.Control
                    type="number"
                    name="ayat_no"
                    onChange={this.changeHandler}
                    value={this.state.ayat_no}
                    placeholder="আয়াত নম্বর"
                  />
                </Bootstrap.Form.Group>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                  <Bootstrap.Form.Label>
                    {" "}
                    &nbsp; &nbsp; &nbsp;
                  </Bootstrap.Form.Label>
                  <Bootstrap.Button
                    type="submit"
                    className="searchresult-btn"
                    onClick={(e) => this.submitAdvancedSearch(e, "sura_ayat")}
                  >
                    {" "}
                    খুঁজুন{" "}
                  </Bootstrap.Button>
                </Bootstrap.Form.Group>
              </Bootstrap.Form.Row>

              <Bootstrap.Form.Row>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="9">
                  <Bootstrap.Form.Label>পারা</Bootstrap.Form.Label>
                  <Bootstrap.Form.Control
                    type="text"
                    name="para_name"
                    onChange={this.changeHandler}
                    value={this.state.para_name}
                    placeholder="পারার নাম লিখুন..."
                  />
                </Bootstrap.Form.Group>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                  <Bootstrap.Form.Label>
                    &nbsp; &nbsp; &nbsp;
                  </Bootstrap.Form.Label>
                  <Bootstrap.Button
                    type="submit"
                    className="searchresult-btn"
                    onClick={(e) => this.submitAdvancedSearch(e, "para")}
                  >
                    {" "}
                    খুঁজুন{" "}
                  </Bootstrap.Button>
                </Bootstrap.Form.Group>
              </Bootstrap.Form.Row>

              <Bootstrap.Form.Row>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="6">
                  <Bootstrap.Form.Label>পারা ও আয়াত</Bootstrap.Form.Label>
                  <Bootstrap.Form.Control
                    type="text"
                    name="para_name2"
                    onChange={this.changeHandler}
                    value={this.state.para_name2}
                    placeholder="পারার নাম লিখুন..."
                  />
                </Bootstrap.Form.Group>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                  <Bootstrap.Form.Label>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </Bootstrap.Form.Label>
                  <Bootstrap.Form.Control
                    type="number"
                    name="ayat_no2"
                    onChange={this.changeHandler}
                    value={this.state.ayat_no2}
                    placeholder="আয়াত নম্বর"
                  />
                </Bootstrap.Form.Group>
                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                  <Bootstrap.Form.Label>&nbsp; &nbsp;</Bootstrap.Form.Label>
                  <Bootstrap.Button
                    type="submit"
                    className="searchresult-btn"
                    onClick={(e) => this.submitAdvancedSearch(e, "para_ayat")}
                  >
                    {" "}
                    খুঁজুন{" "}
                  </Bootstrap.Button>
                </Bootstrap.Form.Group>
              </Bootstrap.Form.Row>
            </div>
          </Bootstrap.Modal.Body>
        </Bootstrap.Modal>

        {/* <a onClick={this.logout} href="#"><i classNameName="fa fa-sign-out" aria-hidden="true"></i> Logout</a> */}
      </Fragment>
    );
  }
}

Sidebar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(mapStateToProps, { logout })(Sidebar);
