import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';
import history from '../../history'
import { toast } from "react-toastify";

class HadithSearch extends PureComponent {

    scrollbars = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            hadith_name: '',
            hadithList: [],
            query_string: '',
            query_string_main: '',
            isEnded: false,
            error: '',
            offset: 0,
            limit: 10,
        }
    }

    componentDidMount() {
        const searchurl = new URL(window.location.href)
        const params = searchurl.searchParams
        const query_string = params.get('query')
        this.setState({ query_string, query_string_main: query_string })
        this.getHadithSearchData(query_string)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps != this.props) {
            const searchurl = new URL(window.location.href)
            const params = searchurl.searchParams
            const query_string = params.get('query')
            this.setState({ query_string, query_string_main: query_string })
            this.getHadithSearchData()
        }
    }

    getHadithSearchData = (query = this.state.query_string) => {
        if (!this.state.isEnded) {
            this.setState({ loading: true, isEnded: false })
            AJAX_PUBLIC_REQUEST("POST", "hadith/search", {
                hadith_book_id: this.props.match.params.hadith_book_id === 'search' ? '' : this.props.match.params.hadith_book_id,
                query_string: query,
                offset: this.state.offset,
                limit: this.state.limit
            }).then(results => {
                if (results.type === "success") {
                    this.setState({
                        hadithList: results.data,
                        hadith_name: results.book_info ? results.book_info.book_name_bn : '',
                        loading: false,
                        offset: this.state.offset + this.state.limit,
                        isEnded: results.data.length > 0 ? false : true,
                        error: results.success
                    });
                } else {
                    this.setState({
                        error: results.error,
                        loading: false,
                        isEnded: false,
                    })
                }
            });
        }
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    searchHadith = (e) => {
        e.preventDefault()
        if (this.state.query_string.length > 2) {
            history.push('/search/hadiths/' + this.props.match.params.hadith_book_id + '?query=' + this.state.query_string)
        } else {
            toast.error('Please type at least 3 character')
        }
    }

    render() {
        document.title = "Search Hadith - Al Quran Technobd";
        const hadith_book_id = this.props.match.params.hadith_book_id
        return (
            <Fragment>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-sm-12 col-md-12">
                        <div className="download-section new-hadith-search-list">
                            <div className="hadith-name-list">
                                <ul>
                                    <li>হাদিসে সার্চ</li>
                                    {
                                        this.state.hadith_name != '' ?
                                            <Fragment>
                                                {
                                                    hadith_book_id != 'search' ?
                                                        <Fragment>
                                                            <li><i className="icofont-simple-right"></i></li>
                                                            <li>{this.state.hadith_name}</li>
                                                        </Fragment>
                                                        : ''
                                                }
                                            </Fragment>
                                            : ''
                                    }

                                    <li><i className="icofont-simple-right"></i></li>
                                    <li>{this.state.query_string_main}</li>
                                </ul>
                            </div>
                            <div className="download-section-inner max-width-830">
                                <div className="new-hadith-search-form">
                                    <Bootstrap.Form>
                                        <Bootstrap.Form.Control type="text" placeholder="হাদিসে সার্চ করুন" onChange={this.changeHandler} name="query_string" value={this.state.query_string} />
                                        <Bootstrap.Button variant="secondary" className="search-custom-btn-new" onClick={this.searchHadith}>
                                            <i className="fas fa-search"></i>
                                        </Bootstrap.Button>
                                    </Bootstrap.Form>
                                </div>
                                <div className="nano">
                                    <div className="nano-content">
                                        <div className="para-item download-item">
                                            <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                <div className="new-hadith-search-list-inner">
                                                    {
                                                        this.state.loading ?
                                                            <div className="loading section-loading"> <i className="fas fa-spinner fa-spin"></i> </div>
                                                            :
                                                            <ul>
                                                                {
                                                                    this.state.hadithList.length > 0 ?
                                                                        <Fragment>
                                                                            {
                                                                                this.state.hadithList.map((hadithList_single, hadithList_index) => {
                                                                                    return (
                                                                                        <li key={`hadiths-${hadithList_single.hadith_book_id}-${hadithList_single.index_id}-${hadithList_index}`}>
                                                                                            <div className="hadith-search-list-inner-block">
                                                                                                <h4><NavLink to={`/hadiths/${hadithList_single.hadith_book_id}/${hadithList_single.index_id}`}>{hadithList_single.index_bn}</NavLink> </h4>
                                                                                                <h5>হাদিস - <NavLink to={`/hadiths/${hadithList_single.hadith_book_id}`}>{hadithList_single.book_name_bn}</NavLink> - <NavLink to={`/hadiths/${hadithList_single.hadith_book_id}/${hadithList_single.index_id}`}>{hadithList_single.index_title_bn}</NavLink></h5>
                                                                                                <p> <NavLink to={`/hadiths/${hadithList_single.hadith_book_id}/${hadithList_single.index_id}/${hadithList_single.hadith_no}`}>{hadithList_single.bangla_details} ({hadithList_single.source})</NavLink>
                                                                                                </p>
                                                                                                <span className="hadith-search-list-number"> হাদিস সংখ্যা - {hadithList_single.total_bangla} </span>
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <li>
                                                                            <div className="no_data_found">{this.state.error}</div>
                                                                        </li>
                                                                }


                                                            </ul>


                                                    }



                                                </div>
                                            </Scrollbars>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>
                        </div>

                    </div>
                </div>

            </Fragment>
        );
    }
}

export default withRouter(HadithSearch);