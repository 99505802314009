import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import Parser from "html-react-parser";
import {
  AJAX_PUBLIC_REQUEST,
  convertEnglishToBanglaNumber,
  convertBanglaToEnglishNumber,
} from "../../Constants/AppConstants";

import * as Bootstrap from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars";

import ReactPlayer from "react-player";
import Duration from "../Common/Duration";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import classnames from "classnames";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AlquranAyatSettings from "./_AlquranAyatSettings";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
} from "react-share";

// Sharect.init();

class AlquranPlayer extends PureComponent {
  scrollbars = React.createRef();
  scrollbars1 = React.createRef();
  scrollbars2 = React.createRef();

  constructor(props) {
    super(props);
    this.indexListRef = React.createRef();
    this.state = {
      showArabic: true,
      showArabicBangla: false,
      showTranslation: true,
      atLeastOneNotification: false,
      isDropdownOpen: false,
      dropdownOpenItem: null,
      loading: true,
      pagination_loading: false,
      player_loading: true,
      para_loading: true,
      sura_loading: true,
      ayat_loading: true,
      ayat_loading_from_click: false,
      offset: 1,
      per_page: 20,
      error: "",
      para_error: "",
      sura_error: "",
      ayat_error: "",
      paraList: [],
      suraList: [],
      ayatList: [],
      tafsirInfo: [],
      tafsirAyatInfo: [],
      activePara: "",
      startingAyat: 1,
      scrollTop: 500,
      activeSura: "",
      activeTafsir: false,
      ayatCopyIndex: null,

      activeAyat: "",
      currently_playing_sl: "",
      currently_playing_ayat_no: "",
      currently_playing_sura_ayat_no: "",
      currently_playing_prev_sura_no: "",
      currently_playing_sura_no: "",
      currently_playing_next_sura_no: "",
      currently_playing_prev_para_no: "",
      currently_playing_para_no: "",
      currently_playing_next_para_no: "",
      currently_playing_sura_name_bn: "",
      currently_playing_sura_name_en: "",
      currently_playing_sura_name_meaning: "",
      currently_playing_sura_total_ayat_bn: "",
      currently_playing_sura_no_bn: "",
      currently_playing_prev_ayat_data: "",
      currently_playing_ayat_data: "",
      currently_playing_next_ayat_data: "",
      currently_playing_mode: "a",
      playing_mode: "ab",
      sura_total_ayat: 0,
      para_total_ayat: 0,
      sura_no: "",
      ayat_search: "",
      //player state
      url: null,
      pip: false,
      playing: false,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,

      //looping area
      show_modal: false,
      loop_type: "off", // off, ayat_single, ayat_range, sura, sura_all
      ayat_range_start: 0,
      ayat_range_end: 0,
      submitted: true,

      // Advanced query and
      query_sura_no: "",
      query_sura_ayat_no: "",
    };
  }

  componentDidMount() {
    document.querySelector("body").scrollIntoView();

    this.setState({
      loading: false,
    });

    const url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    if (params && params.has("sura_no") && params.has("sura_ayat_no")) {
      let data = {};
      let sura_ayat_no = "";
      if (params.has("sura_no")) {
        this.setState({
          query_sura_no: params.get("sura_no"),
          activeSura: params.get("sura_no"),
        });
        data.sura_no = params.get("sura_no");
      }
      if (params.has("sura_ayat_no")) {
        sura_ayat_no = params.get("sura_ayat_no");
        this.setState({
          query_sura_ayat_no: params.get("sura_ayat_no"),
          activeAyat: params.get("sura_ayat_no"),
        });
      }
      this.getSuraList("", false);

      this.setState({ player_loading: true, ayat_loading: true });

      AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then(
        async (results) => {
          if (results.type === "success") {
            const ayat = results.data;
            this.setState({
              ayatList: ayat,
              offset:
                this.state.offset +
                (ayat.length > 0
                  ? parseInt(ayat[0].sura_total_ayat)
                  : this.state.per_page),
              player_loading: false,
              ayat_loading: false,
              activeTafsir: false,
            });
            if (ayat.length > 0) {
              let targeted_ayat_data = [];

              targeted_ayat_data = await this.state.ayatList.filter(
                (first_loop_ayat_data_single, index) => {
                  return (
                    parseInt(first_loop_ayat_data_single.sura_ayat_no) ==
                    sura_ayat_no
                  );
                },
              );

              if (targeted_ayat_data[0] && targeted_ayat_data[0].ayat_no) {
                if (
                  this.state.playing_mode == "ab" ||
                  this.state.playing_mode == "a"
                ) {
                  setTimeout(() => {
                    this.load(
                      targeted_ayat_data[0].ar_audio,
                      targeted_ayat_data[0].ayat_no,
                      targeted_ayat_data[0].sura_no,
                      targeted_ayat_data[0].para_no,
                      false,
                    );
                  }, 100);
                } else if (this.state.playing_mode == "b") {
                  setTimeout(() => {
                    this.load(
                      targeted_ayat_data[0].bn_audio,
                      targeted_ayat_data[0].ayat_no,
                      targeted_ayat_data[0].sura_no,
                      targeted_ayat_data[0].para_no,
                      false,
                    );
                  }, 100);
                }
                //console.log(targeted_ayat_data[0])
              }
            }
          } else {
            this.setState({
              error: Parser(
                "<p classNameName='text-danger'>" + results.error + "</p>",
              ),
              player_loading: false,
              ayat_loading: false,
            });
          }
        },
      );
    } else {
      if (this.props.krom == "para") {
        document.title = "By Para - Al Quran Technobd";
        this.getParaList();
      } else {
        document.title = "By Sura - Al Quran Technobd";
        this.getSuraList();
      }
    }


  }

  adjustAyatListActiveIndex = () => {
    var startingAyat = this.state.startingAyat;
    var current_ayat_id = "ayat_no_" + this.state.activeSura + "_" + startingAyat;
    var ayatListDiv = document.getElementById("AyatListDiv");
    var current = document.getElementById(current_ayat_id);

    if (current != null && ayatListDiv != null) {
      var above = 0;
      if ((current.getBoundingClientRect().top) < ayatListDiv.getBoundingClientRect().top) {
        above = 1;
      }
      while (current != null && ayatListDiv != null) {
        console.log('while');
        if (above) {
          if ((current.getBoundingClientRect().top) >= ayatListDiv.getBoundingClientRect().top) {
            break;
          }
          startingAyat += 1;
          current_ayat_id = "ayat_no_" + this.state.activeSura + "_" + startingAyat;
        } else {

          if (startingAyat <= 1) {
            break;
          }
          current_ayat_id = "ayat_no_" + this.state.activeSura + "_" + (startingAyat - 1);
          current = document.getElementById(current_ayat_id);
          if (current != null && (current.getBoundingClientRect().top) > (ayatListDiv.getBoundingClientRect().top - 5)) {
            startingAyat -= 1;
          } else {
            break;
          }
        }

        current = document.getElementById(current_ayat_id);
      }
      this.setState({
        startingAyat: startingAyat
      });

      let ayat_idex_id = "ayat_index_" + startingAyat;
      if (document
        .getElementById(ayat_idex_id) !== null) {
        document
          .getElementById(ayat_idex_id)
          .scrollIntoView();
      }
    }
  };
  handleAtLeastOneNotification = ()=>{
    this.setState({ atLeastOneNotification: !this.state.atLeastOneNotification });
  }
  handleAyatSettings = (setting) => {
    console.log(setting);

    if (setting == 'showArabic') {
      if(this.state.showArabic && !this.state.showTranslation){
        this.handleAtLeastOneNotification();
      }else{
        this.setState({ showArabic: !this.state.showArabic });
      }

    } else if (setting == 'showTranslation') {
      if(!this.state.showArabic && this.state.showTranslation){
        this.handleAtLeastOneNotification();
      }else{
        this.setState({ showTranslation: !this.state.showTranslation });
      }

    } else if (setting == 'showArabicBangla') {
      this.setState({ showArabicBangla: !this.state.showArabicBangla });
    }

  }
  handleIndexItemClick = async (ayat_no) => {
    if (this.state.ayat_loading) {
      return;
    }
    this.setState({
      ayat_loading_from_click: true
    });
    this.setState({
      ayat_loading: true
    });

    var offset = this.state.offset;
    var results = [];
    while (offset < ayat_no) { // Load 100 items in 5 batches of 20
      let data = {
        offset: offset,
        sura_no: this.state.activeSura,
      };
      // Make the AJAX request
      const result = await AJAX_PUBLIC_REQUEST("POST", 'api/getAyatList', data);
      if (result.type === "success") {
        offset += result.data.length
        // Push the result to the array
        results = results.concat(result.data);
      } else {
        break;
      }

    }
    console.log(results);
    console.log(results.length > 0);
    if (results.length > 0) {
      const old_ayat_list = this.state.ayatList;
      const new_ayat_list = old_ayat_list.concat(results);

      const ayat = results;
      console.log(new_ayat_list);
      this.setState({
        ayatList: new_ayat_list,
        offset: offset,
        pagination_loading: false,
        activeTafsir: false,
      });
    }


    this.setState({ startingAyat: ayat_no });
    var activeSura = this.state.activeSura;
    this.setState({
      ayat_loading: false
    });
    this.setState({
      ayat_loading_from_click: false
    });
    setTimeout(function () {
      if (document
        .getElementById(
          "ayat_no_" + activeSura + "_" + ayat_no
        ) !== null) {
        document
          .getElementById(
            "ayat_no_" + activeSura + "_" + ayat_no
          )
          .scrollIntoView();
      }

    }, 100);


  };


  getParaList = () => {
    this.setState({ para_loading: true });
    AJAX_PUBLIC_REQUEST("POST", "api/getParaList", {}).then((results) => {
      if (results.type === "success") {
        if (results.data.hasOwnProperty("para")) {
          const para = results.data.para;
          this.setState({
            paraList: para,
            para_loading: false,
          });
          if (para.length > 0) {
            const first_para_no = para[0];
            if (first_para_no && first_para_no.para_no) {
              this.setState({ activePara: first_para_no.para_no });
              // this.getSuraList(first_para_no.para_no);
              this.getAyatList(first_para_no.para_no);
            }
          }
        } else {
          this.setState({
            paraList: [],
            error: Parser("<p classNameName='text-danger'>No Data Found</p>"),
            para_loading: false,
          });
        }
      } else {
        this.setState({
          error: Parser(
            "<p classNameName='text-danger'>" + results.error + "</p>",
          ),
          para_loading: false,
        });
      }
    });
  };

  getSuraList = (para_no = "", load_ayat = true) => {
    this.setState({
      activePara: para_no,
      sura_loading: true,
      ayat_loading: true,
      ayat_search: ''
    });
    let data = {};
    if (para_no && para_no != "") {
      data.para_no = para_no;
    }
    AJAX_PUBLIC_REQUEST("POST", "api/getSuraList", data).then((results) => {
      if (results.type === "success") {
        const sura = results.data;
        this.setState({
          suraList: sura,
          sura_loading: false,
        });
        if (sura.length > 0) {
          const first_sura_no = sura[0];
          if (first_sura_no) {
            if (load_ayat) {
              this.setState({ activeSura: first_sura_no.sura_no });
              this.getAyatList(first_sura_no.para_no, first_sura_no.sura_no);
            }
            setTimeout(() => {
              const gotosuraid = document.getElementById(
                "sura_no_" + this.state.activeSura,
              );
              if (gotosuraid) {
                gotosuraid.scrollIntoView();
              }
            }, 100);
          }
        }
      } else {
        this.setState({
          error: Parser(
            "<p classNameName='text-danger'>" + results.error + "</p>",
          ),
          sura_loading: false,
        });
      }
    });
  };
  setStartingAyat = (ayat_no) => {
    this.setState({ startingAyat: ayat_no });
  }
  getAyatList = (para_no, sura_no = false, offset = 1) => {
    this.setState({ activePara: para_no, ayat_loading: true, ayat_search: '' });
    let data = {
      offset: offset,
      para_no: para_no,
    };
    if (sura_no) {
      data.sura_no = sura_no;
    }
    AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then((results) => {
      if (results.type === "success") {
        this.setStartingAyat(1);
        const ayat = results.data;
        this.setState({
          ayatList: ayat,
          offset: this.state.per_page + 1,
          ayat_loading: false,
          activeTafsir: false,
        });
        if (ayat.length > 0) {
          const first_ayat_no = ayat[0];
          if (first_ayat_no) {
            this.setState({
              currently_playing_sura_ayat_no: first_ayat_no.sura_ayat_no,
              currently_playing_sl: first_ayat_no.sl,
              sura_no: first_ayat_no.sura_no,
              activeSura: first_ayat_no.sura_no,
              activeAyat: first_ayat_no.ayat_no,
              sura_total_ayat: first_ayat_no.sura_total_ayat,
              para_total_ayat: first_ayat_no.para_total_ayat,
              player_loading: false,
            });
            // if (para_no != '') {
            //     this.setState({ sura_total_ayat: first_ayat_no.para_total_ayat })
            // }
            if (ayat.length > 1) {
              this.setState({ currently_playing_next_ayat_data: ayat[1] });
            }
            this.load(
              first_ayat_no.ar_audio,
              first_ayat_no.ayat_no,
              first_ayat_no.sura_no,
              first_ayat_no.para_no,
              false,
            );
          }
        }
      } else {
        this.setState({
          error: Parser(
            "<p classNameName='text-danger'>" + results.error + "</p>",
          ),
          ayat_loading: false,
          player_loading: false,
        });
      }
    });
  };

  getTafsirInfo = (info) => {
    if (info && info.id > 0) {
      this.setState({ ayat_loading: true });

      let data = {
        tafsir_id: info.id,
      };

      AJAX_PUBLIC_REQUEST("POST", "api/tafsirDetails", data).then((results) => {
        if (results.type === "success") {
          this.setState({
            tafsirInfo: results.data,
            tafsirAyatInfo: info,
            activeTafsir: true,
            ayat_loading: false,
          });
        } else {
          this.setState({
            error: Parser(
              "<p classNameName='text-danger'>" + results.error + "</p>",
            ),
            ayat_loading: false,
            activeTafsir: false,
          });
        }
      });
    }
  };

  resetTafsir = () => {
    this.setState({
      tafsirInfo: [],
      tafsirAyatInfo: [],
      activeTafsir: false,
      ayat_loading: false,
    });
  };

  // player functions
  load = async (url, ayat_no = 0, sura_no = 1, para_no = 1, playing = true) => {
    this.setState({ playing: false, url: null });
    let activeAyat = parseInt(ayat_no);
    let currently_playing_ayat_no = parseInt(ayat_no);
    let currently_playing_sl = "";
    let currently_playing_sura_ayat_no = "";
    let currently_playing_prev_sura_no =
      parseInt(sura_no) == 1 ? "" : parseInt(sura_no) - 1;
    let currently_playing_sura_no = parseInt(sura_no);
    let currently_playing_next_sura_no =
      parseInt(sura_no) == 114 ? "" : parseInt(sura_no) + 1;
    let currently_playing_prev_para_no =
      parseInt(para_no) == 1 ? "" : parseInt(para_no) - 1;
    let currently_playing_para_no = parseInt(para_no);
    let currently_playing_next_para_no =
      parseInt(para_no) == 30 ? "" : parseInt(para_no) + 1;
    let currently_playing_sura_name_bn = "";
    let currently_playing_sura_name_en = "";
    let currently_playing_sura_name_meaning = "";
    let currently_playing_sura_total_ayat_bn = "";
    let currently_playing_sura_no_bn = "";
    let currently_playing_prev_ayat_data = "";
    let selected_ayat_data = "";
    let currently_playing_next_ayat_data = "";
    let sura_total_ayat = this.state.sura_total_ayat;
    let para_total_ayat = this.state.para_total_ayat;

    let prev_ayat_no = parseInt(ayat_no) - 1;
    let next_ayat_no = parseInt(ayat_no) + 1;

    if (ayat_no == 0) {
      if (this.state.ayatList.length > 1) {
        prev_ayat_no = 0;
        let current_ayat_data_index = 0;
        await this.state.ayatList.map((current_ayat_data_single, index) => {
          if (
            parseInt(current_ayat_data_single.sura_no) == parseInt(sura_no) &&
            parseInt(current_ayat_data_single.ayat_no) == parseInt(ayat_no)
          ) {
            current_ayat_data_index = index;
          }
        });
        const first_ayat = this.state.ayatList[current_ayat_data_index + 1];
        next_ayat_no = parseInt(first_ayat.ayat_no);
      }
    } else {
      if (this.state.ayatList.length > 1) {
        if (this.props.krom == "para") {
          let current_ayat_data_index = 0;
          await this.state.ayatList.map((current_ayat_data_single, index) => {
            if (
              parseInt(current_ayat_data_single.sura_no) == parseInt(sura_no) &&
              parseInt(current_ayat_data_single.ayat_no) == parseInt(ayat_no)
            ) {
              current_ayat_data_index = index;
            }
          });

          const current_ayat_data =
            this.state.ayatList[current_ayat_data_index];
          //console.log('current_ayat_data_index', current_ayat_data_index)
          //console.log('current_ayat_data', current_ayat_data)
          // prev_ayat_no = 0;
          if (current_ayat_data_index + 1 != this.state.ayatList.length) {
            const first_ayat = this.state.ayatList[current_ayat_data_index + 1];
            //console.log('first_ayat', first_ayat)
            //console.log('sura_no', sura_no)
            next_ayat_no = parseInt(first_ayat.ayat_no);
            currently_playing_next_ayat_data = first_ayat;
          }
        }
      }
    }

    if (this.state.ayatList.length > 0) {
      await this.state.ayatList.map((ayatList_single, index) => {
        if (
          parseInt(ayatList_single.sura_no) == parseInt(sura_no) &&
          parseInt(ayatList_single.ayat_no) == parseInt(ayat_no)
        ) {
          selected_ayat_data = ayatList_single;
          // currently_playing_prev_sura_no = parseInt(selected_ayat_data.sura_no) == 1 ? '' : parseInt(selected_ayat_data.sura_no) - 1;
          currently_playing_sl = selected_ayat_data.sl;
          // currently_playing_sura_no = selected_ayat_data.sura_no;
          currently_playing_sura_ayat_no = selected_ayat_data.sura_ayat_no;
          // currently_playing_next_sura_no = parseInt(selected_ayat_data.sura_no) == 114 ? '' : parseInt(selected_ayat_data.sura_no) + 1;
          // currently_playing_prev_para_no = parseInt(this.state.activePara) == 1 ? '' : parseInt(this.state.activePara) - 1;
          // currently_playing_para_no = this.state.activePara;
          // currently_playing_next_para_no = parseInt(this.state.activePara) == 30 ? '' : parseInt(this.state.activePara) + 1;
          currently_playing_sura_name_bn = selected_ayat_data.sura_name_bn;
          currently_playing_sura_name_en = selected_ayat_data.sura_name_en;
          currently_playing_sura_name_meaning =
            selected_ayat_data.sura_meaning_bn;
          currently_playing_sura_total_ayat_bn = convertEnglishToBanglaNumber(
            selected_ayat_data.sura_total_ayat,
          );
          currently_playing_sura_no_bn = selected_ayat_data.bn_sura_no;
          sura_total_ayat = selected_ayat_data.sura_total_ayat;
          para_total_ayat = selected_ayat_data.para_total_ayat;
        }
        if (
          parseInt(ayatList_single.sura_no) == parseInt(sura_no) &&
          parseInt(ayatList_single.ayat_no) == prev_ayat_no
        ) {
          currently_playing_prev_ayat_data = ayatList_single;
        }
        if (
          parseInt(ayatList_single.sura_no) == parseInt(sura_no) &&
          parseInt(ayatList_single.ayat_no) == next_ayat_no
        ) {
          if (currently_playing_next_ayat_data == "") {
            currently_playing_next_ayat_data = ayatList_single;
          }
        }
      });

      // selected_ayat_data = await this.state.ayatList.filter((ayatList_single, index) => {
      //     return parseInt(ayatList_single.ayat_no) === parseInt(ayat_no);
      // })
      // if ((selected_ayat_data != '') && (selected_ayat_data.length > 0)) {
      //     selected_ayat_data = selected_ayat_data[0];
      //     currently_playing_sura_no = selected_ayat_data.sura_no;
      //     currently_playing_para_no = this.state.activePara;
      //     currently_playing_sura_name_bn = selected_ayat_data.sura_name_bn;
      //     currently_playing_sura_name_en = selected_ayat_data.sura_name_en;
      //     currently_playing_sura_no_bn = selected_ayat_data.bn_sura_no;
      //     console.log('selected_ayat_data', selected_ayat_data)
      // }

      document.getElementById("currently_playing_sura_name_bn_top").innerText =
        " " + currently_playing_sura_name_bn;
      setTimeout(() => {
        document
          .getElementById(
            "ayat_no_" + currently_playing_sura_no + "_" + ayat_no,
          )
          .scrollIntoView();
      }, 100);
    }

    if (this.props.krom == "sura") {
      if (
        parseInt(currently_playing_sura_ayat_no) < parseInt(sura_total_ayat) &&
        parseInt(currently_playing_sura_ayat_no) ==
        parseInt(this.state.offset - 1)
      ) {
        if (!this.state.pagination_loading) {
          this.loadSuraNextAyat();
        }
      }
    } else {
      if (
        parseInt(currently_playing_sl) < parseInt(para_total_ayat) &&
        parseInt(currently_playing_sl) == parseInt(this.state.offset - 1)
      ) {
        if (!this.state.pagination_loading) {
          this.loadSuraNextAyat();
        }
      }
    }

    if (this.state.loop_type == "sura" && this.state.submitted) {
      if (
        parseInt(currently_playing_sura_ayat_no) == parseInt(sura_total_ayat)
      ) {
        //('ended and loop')
        if (this.state.ayatList.length > 0) {
          //console.log('ended and loop found')
          // const first_ayat_no = this.state.ayatList.filter((first_loop_ayat_data_single, index) => {
          //     return ((parseInt(first_loop_ayat_data_single.sura_no) == parseInt(currently_playing_sura_no)) && (parseInt(first_loop_ayat_data_single.sura_ayat_no) == 1))
          // })
          const first_ayat_no = this.state.ayatList[0];
          //console.log('ended and loop found data', first_ayat_no)
          if (first_ayat_no) {
            currently_playing_next_ayat_data = first_ayat_no;
          }
        }
      }
    }

    if (this.state.loop_type == "para" && this.state.submitted) {
      if (parseInt(currently_playing_sl) == parseInt(para_total_ayat)) {
        //console.log('ended and loop')
        if (this.state.ayatList.length > 0) {
          //console.log('ended and loop found')
          // const first_ayat_no = this.state.ayatList.filter((first_loop_ayat_data_single, index) => {
          //     return ((parseInt(first_loop_ayat_data_single.sura_no) == parseInt(currently_playing_sura_no)) && (parseInt(first_loop_ayat_data_single.sura_ayat_no) == 1))
          // })
          const first_ayat_no = this.state.ayatList[0];
          //console.log('ended and loop found data', first_ayat_no)
          if (first_ayat_no) {
            currently_playing_next_ayat_data = first_ayat_no;
          }
        }
      }
    }

    if (this.state.loop_type == "ayat_range" && this.state.submitted) {
      if (
        parseInt(currently_playing_sura_ayat_no) ==
        parseInt(this.state.ayat_range_end)
      ) {
        const first_ayat_no = this.state.ayatList.filter(
          (first_loop_ayat_data_single, index) => {
            return (
              parseInt(first_loop_ayat_data_single.sura_no) ==
              parseInt(currently_playing_sura_no) &&
              parseInt(first_loop_ayat_data_single.sura_ayat_no) ==
              this.state.ayat_range_start
            );
          },
        );
        // const first_ayat_no = this.state.ayatList[0];
        //console.log('ended and loop found data', first_ayat_no[0])
        if (first_ayat_no[0] && first_ayat_no[0].ayat_no) {
          currently_playing_next_ayat_data = first_ayat_no[0];
        }
      }
    }

    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
      playing: playing,
      activeAyat: activeAyat,
      currently_playing_sl: currently_playing_sl,
      currently_playing_ayat_no: currently_playing_ayat_no,
      currently_playing_sura_ayat_no: currently_playing_sura_ayat_no,
      currently_playing_prev_sura_no: currently_playing_prev_sura_no,
      currently_playing_sura_no: currently_playing_sura_no,
      currently_playing_next_sura_no: currently_playing_next_sura_no,
      currently_playing_prev_para_no: currently_playing_prev_para_no,
      currently_playing_para_no: currently_playing_para_no,
      currently_playing_next_para_no: currently_playing_next_para_no,
      currently_playing_sura_name_bn: currently_playing_sura_name_bn,
      currently_playing_sura_name_en: currently_playing_sura_name_en,
      currently_playing_sura_name_meaning: currently_playing_sura_name_meaning,
      currently_playing_sura_total_ayat_bn:
        currently_playing_sura_total_ayat_bn,
      currently_playing_sura_no_bn: currently_playing_sura_no_bn,
      currently_playing_prev_ayat_data: currently_playing_prev_ayat_data,
      currently_playing_ayat_data: selected_ayat_data,
      currently_playing_next_ayat_data: currently_playing_next_ayat_data,
      sura_total_ayat: sura_total_ayat,
      para_total_ayat: para_total_ayat,
    });
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url),
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  // handleSetPlaybackRate = e => {
  //     this.setState({ playbackRate: parseFloat(e.target.value) })
  // }
  handleSetPlaybackRate = (e, value) => {
    e.preventDefault();
    this.setState({ playbackRate: parseFloat(value) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    //console.log('onPlay')
    this.setState({ playing: true });
  };

  handleEnablePIP = () => {
    //console.log('onEnablePIP')
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    //console.log('onDisablePIP')
    this.setState({ pip: false });
  };

  handlePause = () => {
    //console.log('onPause')
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };
  handleAyatSearchChange = (e) => {
    this.setState({ ayat_search: e.target.value });
  };
  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  handleProgress = (state) => {
    //console.log('onProgress', state)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = async () => {
    //console.log('onEnded')
    // const currently_playing_ayat_no = this.state.currently_playing_ayat_no;
    const currently_playing_ayat_data = this.state.currently_playing_ayat_data;
    const next_ayat_data = this.state.currently_playing_next_ayat_data;
    const currently_playing_mode = this.state.currently_playing_mode;
    const playing_mode = this.state.playing_mode;

    if (this.state.loop_type == "ayat_single" && this.state.submitted) {
      if (playing_mode == "ab" && currently_playing_mode == "a") {
        if (currently_playing_ayat_data != "") {
          this.setState({ currently_playing_mode: "b" });
          this.load(
            currently_playing_ayat_data.bn_audio,
            currently_playing_ayat_data.ayat_no,
            currently_playing_ayat_data.sura_no,
            currently_playing_ayat_data.para_no,
          );
        }
      } else if (playing_mode == "ab" && currently_playing_mode == "b") {
        if (currently_playing_ayat_data != "") {
          this.setState({ currently_playing_mode: "a" });
          this.load(
            currently_playing_ayat_data.ar_audio,
            currently_playing_ayat_data.ayat_no,
            currently_playing_ayat_data.sura_no,
            currently_playing_ayat_data.para_no,
          );
        }
      } else if (playing_mode == "a") {
        if (currently_playing_ayat_data != "") {
          this.setState({ currently_playing_mode: "a" });
          this.load(
            currently_playing_ayat_data.ar_audio,
            currently_playing_ayat_data.ayat_no,
            currently_playing_ayat_data.sura_no,
            currently_playing_ayat_data.para_no,
          );
          //console.log('currently_playing_ayat_data', currently_playing_ayat_data)
        }
      } else {
        if (currently_playing_ayat_data != "") {
          this.setState({ currently_playing_mode: "b" });
          this.load(
            currently_playing_ayat_data.bn_audio,
            currently_playing_ayat_data.ayat_no,
            currently_playing_ayat_data.sura_no,
            currently_playing_ayat_data.para_no,
          );
          //console.log('currently_playing_ayat_data', currently_playing_ayat_data)
        }
      }
    }
    if (this.state.loop_type == "sura_all") {
      if (
        parseInt(this.state.currently_playing_sura_ayat_no) ==
        parseInt(this.state.sura_total_ayat)
      ) {
        this.setState({ ayat_loading: true, player_loading: true, ayat_search: '' });
        let next_sura_no = parseInt(this.state.activeSura) + 1;
        if (parseInt(this.state.activeSura) == 114) {
          next_sura_no = 1;
        }
        let data = {
          offset: 1,
          sura_no: next_sura_no,
        };
        AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then((results) => {
          if (results.type === "success") {
            const ayat = results.data;
            this.setState({
              ayatList: ayat,
              offset: 1 + this.state.per_page,
              ayat_loading: false,
              player_loading: false,
              activeTafsir: false,
            });
            if (ayat.length > 0) {
              const first_ayat_no = ayat[0];
              if (first_ayat_no) {
                this.setState({
                  currently_playing_sura_ayat_no: first_ayat_no.sura_ayat_no,
                  sura_no: first_ayat_no.sura_no,
                  activeSura: first_ayat_no.sura_no,
                  activeAyat: first_ayat_no.ayat_no,
                  sura_total_ayat: first_ayat_no.sura_total_ayat,
                  para_total_ayat: first_ayat_no.para_total_ayat,
                });
                if (ayat.length > 1) {
                  this.setState({ currently_playing_next_ayat_data: ayat[1] });
                }

                if (
                  this.state.playing_mode == "ab" ||
                  this.state.playing_mode == "a"
                ) {
                  this.load(
                    first_ayat_no.ar_audio,
                    first_ayat_no.ayat_no,
                    first_ayat_no.sura_no,
                    first_ayat_no.para_no,
                  );
                } else {
                  this.load(
                    first_ayat_no.bn_audio,
                    first_ayat_no.ayat_no,
                    first_ayat_no.sura_no,
                    first_ayat_no.para_no,
                  );
                }
              }
            }
          } else {
            this.setState({
              error: Parser(
                "<p classNameName='text-danger'>" + results.error + "</p>",
              ),
              ayat_loading: false,
              player_loading: false,
            });
          }
        });
      }
    }
    if (this.state.loop_type == "para_all") {
      if (
        parseInt(this.state.currently_playing_sl) ==
        parseInt(this.state.para_total_ayat)
      ) {
        this.setState({ ayat_loading: true, player_loading: true });
        let next_para_no = parseInt(this.state.activePara) + 1;
        if (parseInt(this.state.activePara) == 30) {
          next_para_no = 1;
        }
        let data = {
          offset: 1,
          para_no: next_para_no,
        };
        AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then((results) => {
          if (results.type === "success") {
            const ayat = results.data;
            this.setState({
              ayatList: ayat,
              offset: 1 + this.state.per_page,
              ayat_loading: false,
              player_loading: false,
              activeTafsir: false,
            });
            if (ayat.length > 0) {
              const first_ayat_no = ayat[0];
              if (first_ayat_no) {
                this.setState({
                  activePara: next_para_no,
                  currently_playing_sura_ayat_no: first_ayat_no.sura_ayat_no,
                  sura_no: first_ayat_no.sura_no,
                  activeSura: first_ayat_no.sura_no,
                  activeAyat: first_ayat_no.ayat_no,
                  sura_total_ayat: first_ayat_no.sura_total_ayat,
                  para_total_ayat: first_ayat_no.para_total_ayat,
                });
                if (ayat.length > 1) {
                  this.setState({ currently_playing_next_ayat_data: ayat[1] });
                }

                if (
                  this.state.playing_mode == "ab" ||
                  this.state.playing_mode == "a"
                ) {
                  this.load(
                    first_ayat_no.ar_audio,
                    first_ayat_no.ayat_no,
                    first_ayat_no.sura_no,
                    first_ayat_no.para_no,
                  );
                } else {
                  this.load(
                    first_ayat_no.bn_audio,
                    first_ayat_no.ayat_no,
                    first_ayat_no.sura_no,
                    first_ayat_no.para_no,
                  );
                }
              }
            }
          } else {
            this.setState({
              error: Parser(
                "<p classNameName='text-danger'>" + results.error + "</p>",
              ),
              ayat_loading: false,
              player_loading: false,
            });
          }
        });
      }
    }
    if (
      (this.state.loop_type == "off" ||
        this.state.loop_type == "sura" ||
        this.state.loop_type == "sura_all" ||
        this.state.loop_type == "ayat_range" ||
        this.state.loop_type == "para" ||
        this.state.loop_type == "para_all") &&
      this.state.submitted
    ) {
      if (playing_mode == "ab" && currently_playing_mode == "a") {
        if (currently_playing_ayat_data != "") {
          this.setState({ currently_playing_mode: "b" });
          this.load(
            currently_playing_ayat_data.bn_audio,
            currently_playing_ayat_data.ayat_no,
            currently_playing_ayat_data.sura_no,
            currently_playing_ayat_data.para_no,
          );
        }
      } else if (playing_mode == "ab" && currently_playing_mode == "b") {
        if (next_ayat_data != "") {
          this.setState({ currently_playing_mode: "a" });
          this.load(
            next_ayat_data.ar_audio,
            next_ayat_data.ayat_no,
            next_ayat_data.sura_no,
            next_ayat_data.para_no,
          );
          //console.log('next_ayat_data', next_ayat_data)
        }
      } else if (playing_mode == "a") {
        if (next_ayat_data != "") {
          this.setState({ currently_playing_mode: "a" });
          this.load(
            next_ayat_data.ar_audio,
            next_ayat_data.ayat_no,
            next_ayat_data.sura_no,
            next_ayat_data.para_no,
          );
          //console.log('next_ayat_data', next_ayat_data)
        }
      } else {
        if (next_ayat_data != "") {
          this.setState({ currently_playing_mode: "b" });
          this.load(
            next_ayat_data.bn_audio,
            next_ayat_data.ayat_no,
            next_ayat_data.sura_no,
            next_ayat_data.para_no,
          );
          //console.log('next_ayat_data', next_ayat_data)
        }
      }
      // this.setState({ playing: this.state.loop })
    }
  };

  handleDuration = (duration) => {
    //console.log('onDuration', duration)
    this.setState({ duration });
  };

  handleClickFullscreen = () => {
    screenfull.request(findDOMNode(this.player));
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = (player) => {
    this.player = player;
  };

  //custom playing action
  changePlayingMode = (e, value) => {
    e.preventDefault();
    this.setState({ playing_mode: value });
  };

  //scrollbar handler
  handleScrollStop = () => {
    if (this.scrollbars.current) {
      // console.log('viewScrollTop', this.scrollbars.current.viewScrollTop)
      // console.log('clientHeight', this.scrollbars.current.view.clientHeight)
      // console.log('offsetHeight', document.getElementById('CusScrollbars-ul').offsetHeight)
      if (
        this.scrollbars.current.viewScrollTop +
        this.scrollbars.current.view.clientHeight ===
        document.getElementById("CusScrollbars-ul").offsetHeight
      ) {
        if (!this.state.pagination_loading && !this.state.playing) {
          if (this.props.krom == "para") {
            if (this.state.para_total_ayat > this.state.offset) {
              this.setState({ pagination_loading: true });
              setTimeout(() => {
                document.getElementById("scroll_loading").scrollIntoView();
              }, 10);
              let data = {
                offset: this.state.offset,
                para_no: this.state.activePara,
                // sura_no: this.state.activeSura ? this.state.activeSura : ''
              };
              AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then(
                (results) => {
                  if (results.type === "success") {
                    const added_ayat_list = results.data;
                    const old_ayat_list = this.state.ayatList;
                    const new_ayat_list = old_ayat_list.concat(added_ayat_list);

                    const ayat = results.data;
                    this.setState({
                      ayatList: new_ayat_list,
                      offset: this.state.offset + this.state.per_page,
                      pagination_loading: false,
                      activeTafsir: false,
                    });
                    // if (this.state.playing) {
                    //     if (added_ayat_list.length > 0) {
                    //         const first_ayat_no = added_ayat_list[0];
                    //         if (first_ayat_no && first_ayat_no.ayat_no) {
                    //             this.setState({ currently_playing_next_ayat_data: first_ayat_no });
                    //         }
                    //     }
                    // }
                  } else {
                    this.setState({
                      error: Parser(
                        "<p classNameName='text-danger'>" +
                        results.error +
                        "</p>",
                      ),
                      pagination_loading: false,
                      player_loading: false,
                    });
                  }
                },
              );
            }
          } else {
            if (this.state.sura_total_ayat > this.state.offset) {
              this.setState({ pagination_loading: true });
              setTimeout(() => {
                document.getElementById("scroll_loading").scrollIntoView();
              }, 10);
              let data = {
                offset: this.state.offset,
                // para_no: this.state.activePara,
                sura_no: this.state.activeSura ? this.state.activeSura : "",
              };
              AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then(
                (results) => {
                  if (results.type === "success") {
                    const added_ayat_list = results.data;
                    const old_ayat_list = this.state.ayatList;
                    const new_ayat_list = old_ayat_list.concat(added_ayat_list);

                    const ayat = results.data;
                    this.setState({
                      ayatList: new_ayat_list,
                      offset: this.state.offset + this.state.per_page,
                      pagination_loading: false,
                      activeTafsir: false,
                    });
                    // if (this.state.playing) {
                    //     if (added_ayat_list.length > 0) {
                    //         const first_ayat_no = added_ayat_list[0];
                    //         if (first_ayat_no && first_ayat_no.ayat_no) {
                    //             this.setState({ currently_playing_next_ayat_data: first_ayat_no });
                    //         }
                    //     }
                    // }
                  } else {
                    this.setState({
                      error: Parser(
                        "<p classNameName='text-danger'>" +
                        results.error +
                        "</p>",
                      ),
                      pagination_loading: false,
                      player_loading: false,
                    });
                  }
                },
              );
            }
          }
          //console.log('pagination_loading', this.state.pagination_loading);
        }
      }
    }
    this.adjustAyatListActiveIndex();
  };

  loadSuraNextAyat = () => {
    if (this.props.krom == "para") {
      if (this.state.para_total_ayat > this.state.offset) {
        let data = {
          offset: this.state.offset,
          para_no: this.state.activePara,
          // sura_no: this.state.activeSura ? this.state.activeSura : ''
        };
        AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then((results) => {
          if (results.type === "success") {
            const added_ayat_list = results.data;
            const old_ayat_list = this.state.ayatList;
            const new_ayat_list = old_ayat_list.concat(added_ayat_list);

            const ayat = results.data;
            this.setState({
              ayatList: new_ayat_list,
              offset: this.state.offset + this.state.per_page,
              pagination_loading: false,
              activeTafsir: false,
            });
            if (added_ayat_list.length > 0) {
              const first_ayat_no = added_ayat_list[0];
              if (first_ayat_no) {
                this.setState({
                  currently_playing_next_ayat_data: first_ayat_no,
                });
              }
            }
          } else {
            this.setState({
              error: Parser(
                "<p classNameName='text-danger'>" + results.error + "</p>",
              ),
              pagination_loading: false,
              player_loading: false,
            });
          }
        });
      }
    } else {
      if (this.state.sura_total_ayat > this.state.offset) {
        let data = {
          offset: this.state.offset,
          // para_no: this.state.activePara,
          sura_no: this.state.activeSura ? this.state.activeSura : "",
        };
        AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then((results) => {
          if (results.type === "success") {
            const added_ayat_list = results.data;
            const old_ayat_list = this.state.ayatList;
            const new_ayat_list = old_ayat_list.concat(added_ayat_list);

            const ayat = results.data;
            this.setState({
              ayatList: new_ayat_list,
              offset: this.state.offset + this.state.per_page,
              pagination_loading: false,
              activeTafsir: false,
            });
            if (added_ayat_list.length > 0) {
              const first_ayat_no = added_ayat_list[0];
              if (first_ayat_no) {
                this.setState({
                  currently_playing_next_ayat_data: first_ayat_no,
                });
              }
            }
          } else {
            this.setState({
              error: Parser(
                "<p classNameName='text-danger'>" + results.error + "</p>",
              ),
              pagination_loading: false,
              player_loading: false,
            });
          }
        });
      }
    }
  };

  generateIDArray = async () => {
    let sura_no_for_id = this.state.sura_no;
    let scrollspyItems = [];
    if (this.state.ayatList.length > 0) {
      await this.state.ayatList.map((ayatList_single, index) => {
        let need_sura_name = false;
        if (index == 0) {
          need_sura_name = true;
        } else {
          if (sura_no_for_id == ayatList_single.sura_no) {
            need_sura_name = false;
          } else {
            need_sura_name = true;
          }
          sura_no_for_id = ayatList_single.sura_no;
        }

        if (need_sura_name) {
          if (
            !scrollspyItems.includes(
              "playing_sura_name_bn_" + ayatList_single.sura_no,
            )
          ) {
            scrollspyItems.push(
              "playing_sura_name_bn_" + ayatList_single.sura_no,
            );
          }
        }
      });
    }
    return scrollspyItems;
  };

  handleScroll = (e) => {
    const gdata = this.generateIDArray();
    gdata.then((id_arrays) => {
      if (id_arrays.length > 0) {
        // id_arrays.reverse();
        for (var i = 0; i < id_arrays.length; i++) {
          let testID1 = document.getElementById(id_arrays[i]);
          if (testID1) {
            // console.log(testID1.offsetParent)
            // console.log('scrollTop', e.path[0].scrollTop);
            // console.log('testID' + i, testID1.offsetParent.offsetTop)
            if (e.path && e.path[0].scrollTop + 150 >= testID1.offsetParent.offsetTop) {
              // console.log('testID1', testID1.childNodes[1].innerText)
              const innertext = testID1.childNodes[1].innerText;
              document.getElementById(
                "currently_playing_sura_name_bn_top",
              ).innerText = " " + innertext;
              // this.setState({ currently_playing_sura_name_bn_top: innertext })
            }
          }
        }
      }
    });
  };

  toggleModal = (value) => {
    this.setState({ show_modal: value });
  };

  changeHandler = (e) => {
    if (
      e.target.name == "ayat_range_start" ||
      e.target.name == "ayat_range_end"
    ) {
      document.getElementById("ayat_range_loop").click();
    }
    this.setState({
      [e.target.name]: e.target.value,
      submitted: false,
      range_error: false,
    });
  };

  submitLoop = async () => {
    if (this.state.loop_type == "ayat_range") {
      //console.log('ayat_range')
      // const ayat_range_start = document.getElementById('ayat_range_start');
      // const ayat_range_end = document.getElementById('ayat_range_end');
      //console.log(this.state.ayat_range_start)
      //console.log(this.state.ayat_range_end)
      if (
        this.props.krom == "sura" &&
        (parseInt(this.state.ayat_range_start) >
          parseInt(this.state.sura_total_ayat) ||
          parseInt(this.state.ayat_range_end) >
          parseInt(this.state.sura_total_ayat))
      ) {
        //console.log('ayat_range invalid')
        this.setState({ range_error: true });
      } else if (
        this.props.krom == "para" &&
        (parseInt(this.state.ayat_range_start) >
          parseInt(this.state.para_total_ayat) ||
          parseInt(this.state.ayat_range_end) >
          parseInt(this.state.para_total_ayat))
      ) {
        //console.log('ayat_range invalid')
        this.setState({ range_error: true });
      } else if (
        parseInt(this.state.ayat_range_start) < 0 ||
        parseInt(this.state.ayat_range_end) < 0
      ) {
        //console.log('ayat_range invalid')
        this.setState({ range_error: true });
      } else if (
        parseInt(this.state.ayat_range_start) <=
        parseInt(this.state.ayat_range_end)
      ) {
        // if (this.props.krom == 'sura') {
        this.setState({ player_loading: true, ayat_loading: true });
        let data = {};
        if (this.props.krom == "sura") {
          data.sura_no = this.state.activeSura ? this.state.activeSura : "";
        } else {
          data.para_no = this.state.activePara;
        }
        AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then(
          async (results) => {
            if (results.type === "success") {
              const ayat = results.data;
              this.setState({
                ayatList: ayat,
                offset: this.state.offset + this.state.sura_total_ayat,
                player_loading: false,
                ayat_loading: false,
                activeTafsir: false,
              });
              if (ayat.length > 0) {
                let first_ayat_no = [];
                if (this.props.krom == "sura") {
                  first_ayat_no = await this.state.ayatList.filter(
                    (first_loop_ayat_data_single, index) => {
                      return (
                        parseInt(first_loop_ayat_data_single.sura_no) ==
                        parseInt(this.state.activeSura) &&
                        parseInt(first_loop_ayat_data_single.sura_ayat_no) ==
                        this.state.ayat_range_start
                      );
                    },
                  );
                } else {
                  first_ayat_no = await this.state.ayatList.filter(
                    (first_loop_ayat_data_single, index) => {
                      return (
                        parseInt(first_loop_ayat_data_single.sura_no) ==
                        parseInt(this.state.currently_playing_sura_no) &&
                        parseInt(first_loop_ayat_data_single.sura_ayat_no) ==
                        this.state.ayat_range_start
                      );
                    },
                  );
                }

                if (first_ayat_no[0]) {
                  if (
                    this.state.playing_mode == "ab" ||
                    this.state.playing_mode == "a"
                  ) {
                    setTimeout(() => {
                      this.load(
                        first_ayat_no[0].ar_audio,
                        first_ayat_no[0].ayat_no,
                        first_ayat_no[0].sura_no,
                        first_ayat_no[0].para_no,
                      );
                    }, 100);
                  } else if (this.state.playing_mode == "b") {
                    setTimeout(() => {
                      this.load(
                        first_ayat_no[0].bn_audio,
                        first_ayat_no[0].ayat_no,
                        first_ayat_no[0].sura_no,
                        first_ayat_no[0].para_no,
                      );
                    }, 100);
                  }
                  //console.log(first_ayat_no[0])
                }
              }
            } else {
              this.setState({
                error: Parser(
                  "<p classNameName='text-danger'>" + results.error + "</p>",
                ),
                player_loading: false,
                ayat_loading: false,
              });
            }
          },
        );
        // }
        //console.log('ayat_range valid')
        this.setState({
          submitted: true,
          range_error: false,
          show_modal: false,
        });
      } else {
        //console.log('ayat_range invalid')
        this.setState({ range_error: true });
      }
    } else {
      this.setState({ submitted: true, range_error: false, show_modal: false });
    }
  };

  stopLoop = () => {
    this.setState({
      loop_type: "off",
      ayat_range_start: 0,
      ayat_range_end: 0,
      submitted: true,
      range_error: false,
      show_modal: false,
    });
  };

  copyToClipboard = (text, index) => {
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    this.setState({
      ayatCopyIndex: index,
    });
    textField.remove();

    setTimeout(() => {
      this.setState({
        ayatCopyIndex: null,
      });
    }, 2000);
  };

  toggleDropdown = (id) => {
    this.setState({
      dropdownOpenItem: id,
    });
  };

  closeDropdown = () => {
    this.setState({
      dropdownOpenItem: null,
    });
  };

  render() {
    const { isDropdownOpen } = this.state;
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
    } = this.state;
    const SEPARATOR = " · ";

    let sura_no_for_name = this.state.sura_no;
    let sura_no = this.state.sura_no;
    let ayat_class = "col-sm-8 col-md-8 col-lg-8 col-xl-8 plr-2-5 mx-w-69";
    if (this.props.krom == "para") {
      ayat_class = "col-sm-9 col-md-9 col-lg-9 col-xl-9 plr-2-5";
    }

    // let sura_no_for_id = this.state.sura_no;
    // let scrollspyItems = [];
    // if (this.state.ayatList.length > 0) {
    //     this.state.ayatList.map((ayatList_single, index) => {
    //         let need_sura_name = false;
    //         if (index == 0) {
    //             need_sura_name = true;
    //         } else {
    //             if (sura_no_for_id == ayatList_single.sura_no) {
    //                 need_sura_name = false;
    //             } else {
    //                 need_sura_name = true;
    //             }
    //             sura_no_for_id = ayatList_single.sura_no;
    //         }

    //         if (need_sura_name) {
    //             if (!scrollspyItems.includes('playing_sura_name_bn_' + ayatList_single.sura_no)) {
    //                 scrollspyItems.push('playing_sura_name_bn_' + ayatList_single.sura_no);
    //             }

    //         }
    //     })
    // }
    // console.log('scrollspyItems', scrollspyItems)

    return (
      <Fragment>
        {this.state.loading ? (
          <div className="loading container full_page_loader"></div>
        ) : (
          <Fragment>
            {/* player wrapper start */}
            <div style={{ display: "none" }}>
              <div className="player-wrapper">
                <ReactPlayer
                  ref={this.ref}
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={url}
                  pip={pip}
                  playing={playing}
                  controls={controls}
                  light={light}
                  loop={loop}
                  playbackRate={playbackRate}
                  volume={volume}
                  muted={muted}
                  // onReady={() => console.log('onReady')}
                  // onStart={() => console.log('onStart')}
                  onPlay={this.handlePlay}
                  onEnablePIP={this.handleEnablePIP}
                  onDisablePIP={this.handleDisablePIP}
                  onPause={this.handlePause}
                  //onBuffer={() => console.log('onBuffer')}
                  //onSeek={e => console.log('onSeek', e)}
                  onEnded={this.handleEnded}
                  //onError={e => console.log('onError', e)}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                />
              </div>
              <div className="row">
                <div className="col-md-4">
                  <table>
                    <tbody>
                      <tr>
                        <th>Controls</th>
                        <td>
                          <button onClick={this.handleStop}>Stop</button>
                          <button onClick={this.handlePlayPause}>
                            {playing ? "Pause" : "Play"}
                          </button>
                          <button onClick={this.handleClickFullscreen}>
                            Fullscreen
                          </button>
                          {light && (
                            <button onClick={() => this.player.showPreview()}>
                              Show preview
                            </button>
                          )}
                          {ReactPlayer.canEnablePIP(url) && (
                            <button onClick={this.handleTogglePIP}>
                              {pip ? "Disable PiP" : "Enable PiP"}
                            </button>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Speed</th>
                        <td>
                          <button
                            onClick={this.handleSetPlaybackRate}
                            value={1}
                          >
                            1x
                          </button>
                          <button
                            onClick={this.handleSetPlaybackRate}
                            value={1.5}
                          >
                            1.5x
                          </button>
                          <button
                            onClick={this.handleSetPlaybackRate}
                            value={2}
                          >
                            2x
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th>Seek</th>
                        <td>
                          <input
                            type="range"
                            min={0}
                            max={0.999999}
                            step="any"
                            value={played}
                            onMouseDown={this.handleSeekMouseDown}
                            onChange={this.handleSeekChange}
                            onMouseUp={this.handleSeekMouseUp}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Volume</th>
                        <td>
                          <input
                            type="range"
                            min={0}
                            max={1}
                            step="any"
                            value={volume}
                            onChange={this.handleVolumeChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label htmlFor="controls">Controls</label>
                        </th>
                        <td>
                          <input
                            id="controls"
                            type="checkbox"
                            checked={controls}
                            onChange={this.handleToggleControls}
                          />
                          <em>&nbsp; Requires player reload</em>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label htmlFor="muted">Muted</label>
                        </th>
                        <td>
                          <input
                            id="muted"
                            type="checkbox"
                            checked={muted}
                            onChange={this.handleToggleMuted}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label htmlFor="loop">Loop</label>
                        </th>
                        <td>
                          <input
                            id="loop"
                            type="checkbox"
                            checked={loop}
                            onChange={this.handleToggleLoop}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <label htmlFor="light">Light mode</label>
                        </th>
                        <td>
                          <input
                            id="light"
                            type="checkbox"
                            checked={light}
                            onChange={this.handleToggleLight}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Played</th>
                        <td>
                          <progress max={1} value={played} />
                        </td>
                      </tr>
                      <tr>
                        <th>Loaded</th>
                        <td>
                          <progress max={1} value={loaded} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-4">
                  <table>
                    <tbody>
                      <tr>
                        <th>YouTube</th>
                        <td>
                          {this.renderLoadButton(
                            "https://www.youtube.com/watch?v=oUFJJNQGwhk",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://www.youtube.com/watch?v=jNgP6d9HraI",
                            "Test B",
                          )}
                          {this.renderLoadButton(
                            "https://www.youtube.com/playlist?list=PLogRWNZ498ETeQNYrOlqikEML3bKJcdcx",
                            "Playlist",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>SoundCloud</th>
                        <td>
                          {this.renderLoadButton(
                            "https://soundcloud.com/miami-nights-1984/accelerated",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://soundcloud.com/tycho/tycho-awake",
                            "Test B",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Facebook</th>
                        <td>
                          {this.renderLoadButton(
                            "https://www.facebook.com/facebook/videos/10153231379946729/",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://www.facebook.com/FacebookDevelopers/videos/10152454700553553/",
                            "Test B",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Vimeo</th>
                        <td>
                          {this.renderLoadButton(
                            "https://vimeo.com/90509568",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://vimeo.com/169599296",
                            "Test B",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Twitch</th>
                        <td>
                          {this.renderLoadButton(
                            "https://www.twitch.tv/videos/106400740",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://www.twitch.tv/videos/12783852",
                            "Test B",
                          )}
                          {this.renderLoadButton(
                            "https://www.twitch.tv/kronovi",
                            "Test C",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Streamable</th>
                        <td>
                          {this.renderLoadButton(
                            "https://streamable.com/moo",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://streamable.com/ifjh",
                            "Test B",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Wistia</th>
                        <td>
                          {this.renderLoadButton(
                            "https://home.wistia.com/medias/e4a27b971d",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://home.wistia.com/medias/29b0fbf547",
                            "Test B",
                          )}
                          {this.renderLoadButton(
                            "https://home.wistia.com/medias/bq6epni33s",
                            "Test C",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>DailyMotion</th>
                        <td>
                          {this.renderLoadButton(
                            "https://www.dailymotion.com/video/x5e9eog",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://www.dailymotion.com/video/x61xx3z",
                            "Test B",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Mixcloud</th>
                        <td>
                          {this.renderLoadButton(
                            "https://www.mixcloud.com/mixcloud/meet-the-curators/",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://www.mixcloud.com/mixcloud/mixcloud-curates-4-mary-anne-hobbs-in-conversation-with-dan-deacon/",
                            "Test B",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Vidyard</th>
                        <td>
                          {this.renderLoadButton(
                            "https://video.vidyard.com/watch/YBvcF2BEfvKdowmfrRwk57",
                            "Test A",
                          )}
                          {this.renderLoadButton(
                            "https://video.vidyard.com/watch/BLXgYCDGfwU62vdMWybNVJ",
                            "Test B",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Files</th>
                        <td>
                          {this.renderLoadButton(
                            "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4",
                            "mp4",
                          )}
                          {this.renderLoadButton(
                            "https://test-videos.co.uk/vids/bigbuckbunny/webm/vp8/360/Big_Buck_Bunny_360_10s_1MB.webm",
                            "webm",
                          )}
                          {this.renderLoadButton(
                            "https://filesamples.com/samples/video/ogv/sample_640x360.ogv",
                            "ogv",
                          )}
                          {this.renderLoadButton(
                            "https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3",
                            "mp3",
                          )}
                          <br />
                          {this.renderLoadButton(
                            "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
                            "HLS (m3u8)",
                          )}
                          {this.renderLoadButton(
                            "http://dash.edgesuite.net/envivio/EnvivioDash3/manifest.mpd",
                            "DASH (mpd)",
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Custom URL</th>
                        <td>
                          <input
                            ref={(input) => {
                              this.urlInput = input;
                            }}
                            type="text"
                            placeholder="Enter URL"
                          />
                          <button
                            onClick={() =>
                              this.setState({ url: this.urlInput.value })
                            }
                          >
                            Load
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-4">
                  <table>
                    <tbody>
                      <tr>
                        <th>url</th>
                        <td className={!url ? "faded" : ""}>
                          {(url instanceof Array ? "Multiple" : url) || "null"}
                        </td>
                      </tr>
                      <tr>
                        <th>playing</th>
                        <td>{playing ? "true" : "false"}</td>
                      </tr>
                      <tr>
                        <th>volume</th>
                        <td>{volume.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <th>played</th>
                        <td>{played.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <th>loaded</th>
                        <td>{loaded.toFixed(3)}</td>
                      </tr>
                      <tr>
                        <th>duration</th>
                        <td>
                          <Duration seconds={duration} />
                        </td>
                      </tr>
                      <tr>
                        <th>elapsed</th>
                        <td>
                          <Duration seconds={duration * played} />
                        </td>
                      </tr>
                      <tr>
                        <th>remaining</th>
                        <td>
                          <Duration seconds={duration * (1 - played)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* player wrapper end */}
            <div
              className="row wow mlr-2-5 fadeInUp"
              data-wow-duration="500ms"
              data-wow-delay="900ms"
            >
              {this.props.krom == "para" ? (
                <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 plr-2-5">
                  <div className="para-title">পারা সমুহ</div>
                  {this.state.para_loading ? (
                    <div className="loading section-loading">
                      {" "}
                      <i className="fas fa-spinner fa-spin"></i>{" "}
                    </div>
                  ) : (
                    <div className="nano">
                      <div className="nano-content">
                        <div className="para-item">
                          <Scrollbars
                            className="CusScrollbars"
                            id="CusScrollbars2"
                            autoHide
                            autoHideTimeout={1000}
                            autoHideDuration={200}
                            style={{ height: "100%" }}
                            ref={this.scrollbars2}
                          >
                            <ul>
                              {this.state.paraList.length > 0 ? (
                                <Fragment>
                                  {this.state.paraList.map(
                                    (paralist_single, index) => {
                                      return (
                                        <li
                                          onClick={() =>
                                            this.getAyatList(
                                              paralist_single.para_no,
                                            )
                                          }
                                          key={`para_no_${paralist_single.para_no}`}
                                          className={classnames(null, {
                                            sellect:
                                              parseInt(
                                                paralist_single.para_no,
                                              ) ===
                                              parseInt(this.state.activePara),
                                          })}
                                        >
                                          <div className="para-number">
                                            <span>
                                              {paralist_single.bn_para_no}
                                            </span>
                                          </div>
                                          <div className="para-number-no">
                                            <span dir="rtl" lang="ar">
                                              {paralist_single.name_ar}
                                            </span>
                                            <span>
                                              {paralist_single.name_bn}
                                            </span>
                                          </div>
                                        </li>
                                      );
                                    },
                                  )}
                                </Fragment>
                              ) : (
                                <div className="error-msg">
                                  {this.state.para_error}
                                </div>
                              )}
                            </ul>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 plr-2-5">
                    <div className="para-title">সুরা সমুহ</div>
                    {this.state.sura_loading ? (
                      <div className="loading section-loading">
                        {" "}
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      <Fragment>
                        <div className="nano">
                          <div className="nano-content">
                            <div className="para-item surah-item">
                              <Scrollbars
                                className="CusScrollbars"
                                id="CusScrollbars1"
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                                style={{ height: "100%" }}
                                ref={this.scrollbars1}
                              >
                                <ul>
                                  {this.state.suraList.length > 0 ? (
                                    <Fragment>
                                      {this.state.suraList.map(
                                        (suraList_single, index) => {
                                          return (
                                            <li
                                              onClick={() =>
                                                this.getAyatList(
                                                  suraList_single.para_no,
                                                  suraList_single.sura_no,
                                                )
                                              }
                                              id={`sura_no_${suraList_single.sura_no}`}
                                              key={`sura_no_${suraList_single.sura_no}`}
                                              className={classnames(null, {
                                                sellect:
                                                  parseInt(
                                                    suraList_single.sura_no,
                                                  ) ===
                                                  parseInt(this.state.activeSura),
                                              })}
                                            >
                                              <div className="surah-item-left">
                                                <div className="para-number surah-number">
                                                  <span>
                                                    {suraList_single.bn_sura_no}
                                                  </span>
                                                </div>
                                                <div className="surah-number-no">
                                                  {suraList_single.name_bn}
                                                  <span className="meaning_bn">
                                                    {suraList_single.meaning_bn}
                                                  </span>
                                                  <span className="total_ayat_bn">
                                                    মোট আয়াতঃ{" "}
                                                    {
                                                      suraList_single.total_ayat_bn
                                                    }{" "}
                                                    টি
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="surah-item-right">
                                                {suraList_single.name_ar}
                                              </div>
                                            </li>
                                          );
                                        },
                                      )}
                                    </Fragment>
                                  ) : (
                                    <div className="error-msg">
                                      {this.state.sura_error}
                                    </div>
                                  )}
                                </ul>
                              </Scrollbars>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1 plr-2-5 p-1 mw-6">
                    {(!this.state.ayat_loading_from_click && this.state.ayat_loading) ? (

                      <div className="loading section-loading">
                        {" "}
                        <i className="fas fa-spinner fa-spin"></i>
                      </div>
                    ) : (
                      <Fragment>
                        <div class="ayat-item-card">
                          <div className="para-ayat-title">আয়াত নং</div>
                          <input className="para-ayat-title-sub" value={this.state.ayat_search} onChange={this.handleAyatSearchChange} type="text" placeholder="আয়াত" />




                          <div className="nano">
                            <div className="nano-content">
                              <div className="para-item surah-item ayat-item ayat-item-shado-unset">
                                <Scrollbars
                                  className="CusScrollbars"
                                  id="CusScrollbars1"
                                  autoHide
                                  autoHideTimeout={1000}
                                  autoHideDuration={200}
                                  style={{ height: "100%" }}
                                  ref={this.scrollbars1}
                                >
                                  <ul className="text-center ayat-number">
                                    {this.state.sura_total_ayat > 0 ? (
                                      <Fragment>
                                        {Array.from({ length: this.state.sura_total_ayat }, (e, i) => {
                                          let index = i + 1;
                                          if (index.toString().includes(convertBanglaToEnglishNumber(this.state.ayat_search))) {
                                            return (
                                              <li
                                                id={`ayat_index_${index}`}
                                                className={this.state.startingAyat == index ? 'active' : ''}
                                                onClick={() => this.handleIndexItemClick(index)}
                                                key={index}
                                              >
                                                {convertEnglishToBanglaNumber(index)}
                                              </li>
                                            );
                                          } else {
                                            return '';
                                          }

                                        },
                                        )}
                                      </Fragment>
                                    ) : (
                                      <div className="error-msg">
                                        {this.state.sura_error}
                                      </div>
                                    )}

                                  </ul>
                                  <p className="mb-5"></p>
                                </Scrollbars>
                              </div>
                            </div>
                          </div>




                        </div>
                      </Fragment>
                    )}
                  </div>
                </>
              )}

              <div className={ayat_class}>
                <div className="para-title-box">
                  <div className="para-title para-title-new d-flex align-items-center pl-20px">
                    {this.state.activeTafsir ? (
                      <>
                        {" "}
                        <Link
                          href="javascript:void(0)"
                          onClick={() => this.resetTafsir()}
                          className="active"
                        >
                          {" "}
                          আয়াত সমুহ{" "}
                        </Link>{" "}
                        <span className="para-arrow"></span>তাফসীর{" "}
                      </>
                    ) : (
                      <>আয়াত সমুহ</>
                    )}
                  </div>
                  <div className="para-title">
                    {" "}
                    সুরাঃ
                    <span id="currently_playing_sura_name_bn_top">
                      {" "}
                      {this.state.currently_playing_sura_name_bn}{" "}
                    </span>
                    {/* <Scrollspy items={scrollspyItems} rootEl=".para-item ayata-item">

                                                {
                                                    this.state.ayatList.length > 0 ?
                                                        <Fragment>
                                                            {
                                                                this.state.ayatList.map((ayatList_single, index) => {
                                                                    let need_sura_name = false;
                                                                    if (index == 0) {
                                                                        need_sura_name = true;
                                                                    } else {
                                                                        if (sura_no_for_name == ayatList_single.sura_no) {
                                                                            need_sura_name = false;
                                                                        } else {
                                                                            need_sura_name = true;
                                                                        }
                                                                        sura_no_for_name = ayatList_single.sura_no;
                                                                    }

                                                                    if (need_sura_name) {
                                                                        return (<li key={`bn_name_${ayatList_single.sura_no}`}><a href={`#playing_sura_name_bn_${ayatList_single.sura_no}`}>{ayatList_single.sura_name_bn}</a></li>)
                                                                    }
                                                                })
                                                            }
                                                        </Fragment>
                                                        : ''
                                                }
                                            </Scrollspy> */}
                  </div>
                </div>
                {this.state.ayat_loading ? (
                  <div className="loading section-loading">
                    {" "}
                    <i className="fas fa-spinner fa-spin"></i>{" "}
                  </div>
                ) : (
                  <>
                    <div className="ayat-settings-container">

                      <AlquranAyatSettings
                        state={this.state}
                        handleAyatSettings={this.handleAyatSettings}
                        handleAtLeastOneNotification={this.handleAtLeastOneNotification}
                      />
                    </div>

                    <div id="AyatListDiv" className="nano">

                      <div className="nano-content">
                        <div className="para-item ayata-item">

                          {this.state.activeTafsir ? (
                            <Scrollbars
                              onScroll={this.handleScroll}
                              onScrollStop={this.handleScrollStop}
                              className="CusScrollbars"
                              id="CusScrollbars"
                              autoHide
                              autoHideTimeout={1000}
                              autoHideDuration={200}
                              style={{ height: "100%" }}
                              ref={this.scrollbars}
                            >
                              <div className="tafsir-header">
                                <h3>
                                  {this.state.tafsirInfo.tafsir_author_name}
                                </h3>
                                <span>
                                  {this.state.tafsirInfo.sura_name}, তাফসীর আয়াত
                                  নং:{" "}
                                  {this.state.tafsirAyatInfo.ayats.min ==
                                    this.state.tafsirAyatInfo.ayats.max
                                    ? convertEnglishToBanglaNumber(
                                      this.state.tafsirAyatInfo.ayats.min,
                                    )
                                    : convertEnglishToBanglaNumber(
                                      this.state.tafsirAyatInfo.ayats.min,
                                    ) +
                                    "-" +
                                    convertEnglishToBanglaNumber(
                                      this.state.tafsirAyatInfo.ayats.max,
                                    )}
                                </span>
                                <span class="shape-icon"> </span>
                              </div>
                              <div className="tafsir-box tafsir-box-new">
                                {Parser(this.state.tafsirInfo.description)}
                              </div>
                            </Scrollbars>
                          ) : (
                            <>

                              <Scrollbars
                                onScroll={this.handleScroll}
                                scrollTop={this.state.scrollTop}
                                onScrollStop={this.handleScrollStop}
                                className="CusScrollbars"
                                id="CusScrollbars"
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                                style={{ height: "100%" }}
                                ref={this.scrollbars}
                              >

                                <ul id="CusScrollbars-ul" style={{ flex: '1', overflowY: 'auto' }}>
                                  {this.state.ayatList.length > 0 ? (
                                    <Fragment>
                                      {this.state.ayatList.map(
                                        (ayatList_single, index) => {
                                          let need_sura_name = false;
                                          if (this.props.krom == "para") {
                                            if (index == 0) {
                                              need_sura_name = true;
                                            } else {
                                              if (
                                                sura_no == ayatList_single.sura_no
                                              ) {
                                                need_sura_name = false;
                                              } else {
                                                need_sura_name = true;
                                              }
                                              sura_no = ayatList_single.sura_no;
                                            }
                                          } else {
                                            if (index == 0) {
                                              need_sura_name = true;
                                            } else {
                                              need_sura_name = false;
                                            }
                                          }
                                          let sura_name_class = "";
                                          if (need_sura_name) {
                                            sura_name_class = "sura-start-section";
                                          }
                                          let ayatCopyString =
                                            ayatList_single.bn_text +
                                            "\n\n" +
                                            ayatList_single.ar_text +
                                            "\n\nসূরা নম্বর: " +
                                            convertEnglishToBanglaNumber(
                                              ayatList_single.sura_no,
                                            ) +
                                            " আয়াত নম্বর: " +
                                            convertEnglishToBanglaNumber(
                                              ayatList_single.ayat_no,
                                            );

                                          return (
                                            <li
                                              id={`ayat_no_${ayatList_single.sura_no}_${ayatList_single.sura_ayat_no}`}
                                              key={ayatList_single.ayat_no}
                                              className={classnames(
                                                sura_name_class,
                                                {
                                                  sellect:
                                                    parseInt(
                                                      ayatList_single.ayat_no,
                                                    ) ===
                                                    parseInt(this.state.activeAyat),
                                                },
                                              )}
                                            >
                                              {ayatList_single.sura_no == 1 ||
                                                ayatList_single.sura_no == 9 ? (
                                                <Fragment>
                                                  {need_sura_name ? (
                                                    <div
                                                      className="ayata-item-box-sura-info"
                                                      id={`playing_sura_name_bn_${ayatList_single.sura_no}`}
                                                    >
                                                      <div className="sura_name_ar">
                                                        {
                                                          ayatList_single.sura_name_ar
                                                        }
                                                      </div>
                                                      <div className="sura_name_bn">
                                                        {
                                                          ayatList_single.sura_name_bn
                                                        }
                                                      </div>
                                                      <div className="sura_total_ayat">
                                                        মোট আয়াতঃ{" "}
                                                        {convertEnglishToBanglaNumber(
                                                          ayatList_single.sura_total_ayat,
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Fragment>
                                              ) : (
                                                <Fragment>
                                                  {need_sura_name &&
                                                    ayatList_single.bn_sura_ayat_no ==
                                                    "০" ? (
                                                    <div
                                                      className="ayata-item-box-sura-info"
                                                      id={`playing_sura_name_bn_${ayatList_single.sura_no}`}
                                                    >
                                                      <div className="sura_name_ar">
                                                        {
                                                          ayatList_single.sura_name_ar
                                                        }
                                                      </div>
                                                      <div className="sura_name_bn">
                                                        {
                                                          ayatList_single.sura_name_bn
                                                        }
                                                      </div>
                                                      <div className="sura_total_ayat">
                                                        মোট আয়াতঃ{" "}
                                                        {convertEnglishToBanglaNumber(
                                                          ayatList_single.sura_total_ayat,
                                                        )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <Fragment>
                                                      {need_sura_name ? (
                                                        <div
                                                          className="ayata-item-box-sura-info"
                                                          id={`playing_sura_name_bn_${ayatList_single.sura_no}`}
                                                        >
                                                          <div className="sura_name_ar d-none">
                                                            {
                                                              ayatList_single.sura_name_ar
                                                            }
                                                          </div>
                                                          <div className="sura_name_bn d-none">
                                                            {
                                                              ayatList_single.sura_name_bn
                                                            }
                                                          </div>
                                                          <div
                                                            className="sura_name_ar"
                                                            dir="rtl"
                                                            lang="ar"
                                                          >
                                                            بِسۡمِ اللهِ الرَّحۡمٰنِ
                                                            الرَّحِيۡمِ
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </Fragment>
                                                  )}
                                                </Fragment>
                                              )}

                                              <div className="ayata-item-box">
                                                {ayatList_single.bn_sura_ayat_no !=
                                                  "০" ? (
                                                  <div className="ayat_no_single">
                                                    {this.props.krom == "sura" ? (
                                                      <span className="ayat_no_single_left">
                                                        আয়াত নংঃ{" "}
                                                        {
                                                          ayatList_single.bn_sura_ayat_no
                                                        }
                                                      </span>
                                                    ) : (
                                                      <span className="ayat_no_single_left">
                                                        সুরা{" "}
                                                        {
                                                          ayatList_single.sura_name_bn
                                                        }{" "}
                                                        :{" "}
                                                        {
                                                          ayatList_single.bn_sura_ayat_no
                                                        }
                                                      </span>
                                                    )}

                                                    {/* <span className="ayat_option_menu">...</span> */}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {this.state.showArabic ? (
                                                  <div
                                                    className="ayata-title"
                                                    dir="rtl"
                                                    lang="ar"
                                                  >
                                                    {ayatList_single.ar_text}
                                                  </div>
                                                ) : (<></>)}


                                                <div className="ayata-item-start">
                                                  {ayatList_single.bn_sura_ayat_no ==
                                                    "০" ? (
                                                    <ul className="ul-ayata-item">
                                                      {this.state.showArabicBangla ? (
                                                        <li className="bangla-pronunciation">
                                                          {/* <span>১ | </span> */}
                                                          {
                                                            ayatList_single.bn_pronunciation_text
                                                          }
                                                        </li>
                                                      ) : (<></>)}

                                                      {this.state.showTranslation ? (
                                                        <li className="bangla bangla-new">
                                                          {/* <span>২ |</span> */}
                                                          {" "}
                                                          {ayatList_single.bn_text}
                                                        </li>
                                                      ) : (<></>)}


                                                    </ul>
                                                  ) : (
                                                    <ul className="ul-ayata-item">
                                                      {this.state.showArabicBangla ? (
                                                        <li className="bangla-pronunciation">
                                                          {/* <span>১ |</span> */}
                                                          {" "}
                                                          {
                                                            ayatList_single.bn_pronunciation_text
                                                          }
                                                        </li>
                                                      ) : (<></>)}
                                                      {this.state.showTranslation ? (
                                                        <li className="bangla bangla-new">
                                                          {/* <span>২ | </span> */}
                                                          {ayatList_single.bn_text}
                                                        </li>
                                                      ) : (<></>)}


                                                    </ul>
                                                  )}
                                                </div>

                                                <div className="w-100 d-flex align-items-center justify-content-end ">
                                                  <div className="tafsir-group d-flex align-items-center gap-5">
                                                    {ayatList_single.tafsir &&
                                                      ayatList_single.tafsir.id >
                                                      0 ? (
                                                      <Link
                                                        href="javascript:void(0)"
                                                        onClick={() =>
                                                          this.getTafsirInfo(
                                                            ayatList_single.tafsir,
                                                          )
                                                        }
                                                      >
                                                        <div className="tafsir-badge">
                                                          {" "}
                                                          <span className="icon"></span>{" "}
                                                          <span className="text">
                                                            {" "}
                                                            তাফসীর আয়াত{" "}
                                                            {ayatList_single.tafsir
                                                              .ayats.min ==
                                                              ayatList_single.tafsir
                                                                .ayats.max
                                                              ? convertEnglishToBanglaNumber(
                                                                ayatList_single
                                                                  .tafsir.ayats
                                                                  .min,
                                                              )
                                                              : convertEnglishToBanglaNumber(
                                                                ayatList_single
                                                                  .tafsir.ayats
                                                                  .min,
                                                              ) +
                                                              "-" +
                                                              convertEnglishToBanglaNumber(
                                                                ayatList_single
                                                                  .tafsir.ayats
                                                                  .max,
                                                              )}{" "}
                                                          </span>{" "}
                                                          <span className="icon"></span>
                                                        </div>
                                                      </Link>
                                                    ) : null}
                                                    <Link
                                                      href="javascript:void(0)"
                                                      onClick={() =>
                                                        this.copyToClipboard(
                                                          ayatCopyString,
                                                          index,
                                                        )
                                                      }
                                                    >
                                                      {this.state.ayatCopyIndex ==
                                                        index ? (
                                                        <span className="copy-check-icon ">
                                                          {" "}
                                                        </span>
                                                      ) : (
                                                        <span className="copy-text">
                                                          {" "}
                                                        </span>
                                                      )}
                                                    </Link>

                                                    <div class="share-dropdown">
                                                      <Link
                                                        href="javascript:void(0)"
                                                        id="shareIcon"
                                                        onClick={() =>
                                                          this.toggleDropdown(index)
                                                        }
                                                      >
                                                        <span className="share-icon">
                                                          {" "}
                                                        </span>
                                                      </Link>
                                                      <div
                                                        className={`dropdown-list ${this.state
                                                          .dropdownOpenItem ===
                                                          index
                                                          ? "show"
                                                          : ""
                                                          }`}
                                                      >
                                                        <FacebookShareButton
                                                          url="https://alquran-bangla.com/" //eg. https://www.example.com
                                                          quotes={ayatCopyString} //"Your Quotes"
                                                          hashtag="" // #hashTag
                                                        >
                                                          <Link
                                                            href="#"
                                                            className="facebook"
                                                          >
                                                            ফেসবুক
                                                          </Link>
                                                        </FacebookShareButton>

                                                        <TwitterShareButton
                                                          url="https://alquran-bangla.com/" //eg. https://www.example.com
                                                          quotes={ayatCopyString} //"Your Quotes"
                                                          hashtag="" // #hashTag
                                                        >
                                                          <Link
                                                            href="#"
                                                            className="twitter"
                                                          >
                                                            টুইটার
                                                          </Link>
                                                        </TwitterShareButton>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                {this.state.playing_mode == "a" ||
                                                  this.state.playing_mode == "ab" ? (
                                                  this.state.playing &&
                                                    this.state
                                                      .currently_playing_ayat_no ===
                                                    parseInt(
                                                      ayatList_single.ayat_no,
                                                    ) ? (
                                                    <button
                                                      type="button"
                                                      onClick={this.handlePlayPause}
                                                      className="pause-button"
                                                    >
                                                      <span></span>
                                                    </button>
                                                  ) : (
                                                    <Fragment>
                                                      {this.state
                                                        .currently_playing_ayat_no ===
                                                        parseInt(
                                                          ayatList_single.ayat_no,
                                                        ) &&
                                                        this.state.duration > 0 ? (
                                                        <button
                                                          type="button"
                                                          onClick={
                                                            this.handlePlayPause
                                                          }
                                                          className="play-button"
                                                        >
                                                          <span></span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            this.load(
                                                              ayatList_single.ar_audio,
                                                              ayatList_single.ayat_no,
                                                              ayatList_single.sura_no,
                                                              ayatList_single.para_no,
                                                            )
                                                          }
                                                          className="play-button"
                                                        >
                                                          <span></span>
                                                        </button>
                                                      )}
                                                    </Fragment>
                                                  )
                                                ) : (
                                                  ""
                                                )}

                                                {this.state.playing_mode == "b" ? (
                                                  this.state.playing &&
                                                    this.state
                                                      .currently_playing_ayat_no ===
                                                    parseInt(
                                                      ayatList_single.ayat_no,
                                                    ) ? (
                                                    <button
                                                      type="button"
                                                      onClick={this.handlePlayPause}
                                                      className="pause-button"
                                                    >
                                                      <span></span>
                                                    </button>
                                                  ) : (
                                                    <Fragment>
                                                      {this.state
                                                        .currently_playing_ayat_no ===
                                                        parseInt(
                                                          ayatList_single.ayat_no,
                                                        ) &&
                                                        this.state.duration > 0 ? (
                                                        <button
                                                          type="button"
                                                          onClick={
                                                            this.handlePlayPause
                                                          }
                                                          className="play-button"
                                                        >
                                                          <span></span>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            this.load(
                                                              ayatList_single.bn_audio,
                                                              ayatList_single.ayat_no,
                                                              ayatList_single.sura_no,
                                                              ayatList_single.para_no,
                                                            )
                                                          }
                                                          className="play-button"
                                                        >
                                                          <span></span>
                                                        </button>
                                                      )}
                                                    </Fragment>
                                                  )
                                                ) : (
                                                  ""
                                                )}

                                                {/* {
                                                                                                this.state.currently_playing_ayat_no === parseInt(ayatList_single.ayat_no) ?
                                                                                                    <button type="button" onClick={() => this.load('https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3')} className="pause-button">
                                                                                                        <span></span>
                                                                                                    </button>
                                                                                                    :
                                                                                                    <button type="button" onClick={() => this.load('https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3')} className="play-button">
                                                                                                        <span></span>
                                                                                                    </button>
                                                                                            } */}
                                              </div>
                                            </li>
                                          );
                                        },
                                      )}
                                    </Fragment>
                                  ) : (
                                    <div className="error-msg">
                                      {this.state.sura_error}
                                    </div>
                                  )}
                                </ul>

                                {this.state.pagination_loading ? (
                                  <div
                                    id="scroll_loading"
                                    className="text-center message_scroll_loading"
                                  >
                                    <p className="text-center">
                                      <i className="fas fa-spinner fa-spin fa-2x fa-fw"></i>
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Scrollbars>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div></div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="full-play">
                  <div className="full-play-inner">
                    {this.state.player_loading ? (
                      <div className="loading player-loading">
                        {" "}
                        <i className="fas fa-spinner fa-spin"></i>{" "}
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-3">
                          <div className="full-play-surah">
                            <div className="para-number">
                              <span>
                                {this.state.currently_playing_sura_no_bn}
                              </span>
                            </div>
                            <div className="para-number-no full-play-name">
                              {this.state.currently_playing_sura_name_bn}
                              <span className="meaning_bn">
                                {this.state.currently_playing_sura_name_meaning}
                              </span>
                              <span className="total_ayat_bn">
                                মোট আয়াতঃ{" "}
                                {
                                  this.state
                                    .currently_playing_sura_total_ayat_bn
                                }{" "}
                                টি
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="full-play-btn-area">
                            <ul>
                              <li>
                                {this.state.currently_playing_prev_ayat_data !=
                                  "" ? (
                                  <Fragment>
                                    {this.state.playing_mode == "ab" ||
                                      this.state.playing_mode == "a" ? (
                                      <button
                                        className="full-play-btn-left"
                                        onClick={() =>
                                          this.load(
                                            this.state
                                              .currently_playing_prev_ayat_data
                                              .ar_audio,
                                            this.state
                                              .currently_playing_prev_ayat_data
                                              .ayat_no,
                                            this.state
                                              .currently_playing_prev_ayat_data
                                              .sura_no,
                                            this.state
                                              .currently_playing_prev_ayat_data
                                              .para_no,
                                          )
                                        }
                                      ></button>
                                    ) : (
                                      <button
                                        className="full-play-btn-left"
                                        onClick={() =>
                                          this.load(
                                            this.state
                                              .currently_playing_prev_ayat_data
                                              .bn_audio,
                                            this.state
                                              .currently_playing_prev_ayat_data
                                              .ayat_no,
                                            this.state
                                              .currently_playing_prev_ayat_data
                                              .sura_no,
                                            this.state
                                              .currently_playing_prev_ayat_data
                                              .para_no,
                                          )
                                        }
                                      ></button>
                                    )}
                                  </Fragment>
                                ) : (
                                  <button className="full-play-btn-left"></button>
                                )}
                              </li>
                              <li>
                                <div className="pause-dropdown-block">
                                  {playing ? (
                                    <button
                                      type="button"
                                      onClick={this.handlePlayPause}
                                      className="pause-button"
                                    >
                                      <span></span>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={this.handlePlayPause}
                                      className="full-play-btn-play-pause"
                                    ></button>
                                  )}

                                  <Bootstrap.DropdownButton
                                    className="dropdown-basic-button"
                                    id="dropdown-basic-button"
                                    title={
                                      this.state.playing_mode == "a"
                                        ? "আরবি তেলাওয়াত "
                                        : this.state.playing_mode == "b"
                                          ? "বাংলা অর্থ "
                                          : "আরবি তেলাওয়াত এবং বাংলা অর্থ "
                                    }
                                    drop="up"
                                  >
                                    <Bootstrap.Dropdown.Item
                                      href="#"
                                      onClick={(e) =>
                                        this.changePlayingMode(e, "b")
                                      }
                                    >
                                      বাংলা অর্থ{" "}
                                    </Bootstrap.Dropdown.Item>
                                    <Bootstrap.Dropdown.Item
                                      href="#"
                                      onClick={(e) =>
                                        this.changePlayingMode(e, "a")
                                      }
                                    >
                                      আরবি তেলাওয়াত{" "}
                                    </Bootstrap.Dropdown.Item>
                                    <Bootstrap.Dropdown.Item
                                      href="#"
                                      onClick={(e) =>
                                        this.changePlayingMode(e, "ab")
                                      }
                                    >
                                      আরবি তেলাওয়াত এবং বাংলা অর্থ{" "}
                                    </Bootstrap.Dropdown.Item>
                                  </Bootstrap.DropdownButton>
                                </div>
                              </li>
                              <li>
                                {this.state.currently_playing_next_ayat_data !=
                                  "" ? (
                                  <Fragment>
                                    {this.state.playing_mode == "ab" ||
                                      this.state.playing_mode == "a" ? (
                                      <button
                                        className="full-play-btn-right"
                                        onClick={() =>
                                          this.load(
                                            this.state
                                              .currently_playing_next_ayat_data
                                              .ar_audio,
                                            this.state
                                              .currently_playing_next_ayat_data
                                              .ayat_no,
                                            this.state
                                              .currently_playing_next_ayat_data
                                              .sura_no,
                                            this.state
                                              .currently_playing_next_ayat_data
                                              .para_no,
                                          )
                                        }
                                      ></button>
                                    ) : (
                                      <button
                                        className="full-play-btn-right"
                                        onClick={() =>
                                          this.load(
                                            this.state
                                              .currently_playing_next_ayat_data
                                              .bn_audio,
                                            this.state
                                              .currently_playing_next_ayat_data
                                              .ayat_no,
                                            this.state
                                              .currently_playing_next_ayat_data
                                              .sura_no,
                                            this.state
                                              .currently_playing_next_ayat_data
                                              .para_no,
                                          )
                                        }
                                      ></button>
                                    )}
                                  </Fragment>
                                ) : (
                                  <button className="full-play-btn-right"></button>
                                )}
                              </li>
                              <li>
                                <span>
                                  <Duration seconds={duration * played} /> /{" "}
                                  <Duration seconds={duration} />
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="play-normal">
                            <div className="speed-control">
                              <Bootstrap.DropdownButton
                                className="dropdown-basic-button"
                                id="dropdown-basic-button"
                                title=""
                                drop="up"
                              >
                                <Bootstrap.Dropdown.Item href="#">
                                  {" "}
                                  Speed{" "}
                                </Bootstrap.Dropdown.Item>
                                <Bootstrap.Dropdown.Item
                                  href="#"
                                  onClick={(e) =>
                                    this.handleSetPlaybackRate(e, 0.25)
                                  }
                                >
                                  0.25{" "}
                                </Bootstrap.Dropdown.Item>
                                <Bootstrap.Dropdown.Item
                                  href="#"
                                  onClick={(e) =>
                                    this.handleSetPlaybackRate(e, 0.5)
                                  }
                                >
                                  0.5{" "}
                                </Bootstrap.Dropdown.Item>
                                <Bootstrap.Dropdown.Item
                                  href="#"
                                  onClick={(e) =>
                                    this.handleSetPlaybackRate(e, 0.75)
                                  }
                                >
                                  0.75{" "}
                                </Bootstrap.Dropdown.Item>
                                <Bootstrap.Dropdown.Item
                                  href="#"
                                  onClick={(e) =>
                                    this.handleSetPlaybackRate(e, 1)
                                  }
                                >
                                  Normal
                                </Bootstrap.Dropdown.Item>
                                <Bootstrap.Dropdown.Item
                                  href="#"
                                  onClick={(e) =>
                                    this.handleSetPlaybackRate(e, 1.25)
                                  }
                                >
                                  1.25{" "}
                                </Bootstrap.Dropdown.Item>
                                <Bootstrap.Dropdown.Item
                                  href="#"
                                  onClick={(e) =>
                                    this.handleSetPlaybackRate(e, 1.5)
                                  }
                                >
                                  1.5{" "}
                                </Bootstrap.Dropdown.Item>
                                <Bootstrap.Dropdown.Item
                                  href="#"
                                  onClick={(e) =>
                                    this.handleSetPlaybackRate(e, 2.0)
                                  }
                                >
                                  2.0{" "}
                                </Bootstrap.Dropdown.Item>
                              </Bootstrap.DropdownButton>
                              <div className="speed-control-text">
                                <span>
                                  {this.state.playbackRate === 1
                                    ? "Normal"
                                    : this.state.playbackRate + "x"}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* {this.state.loop ?
                                                                <button className="active-loop" onClick={this.handleToggleLoop}></button>
                                                                :
                                                                <button className="no-Loop" onClick={this.handleToggleLoop}></button>
                                                            } */}
                          <button
                            className="no-Loop"
                            onClick={() => this.toggleModal(true)}
                          ></button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <Bootstrap.Modal
              className="advanced-search-modal Loop-modal"
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.show_modal}
              onHide={() => this.toggleModal(false)}
            >
              <Bootstrap.Modal.Header>
                <Bootstrap.Modal.Title id="contained-modal-title-vcenter">
                  পুনরায় শোনার জন্য যেকোন একটি <br /> আপশন নিবাচন করুন -
                </Bootstrap.Modal.Title>
              </Bootstrap.Modal.Header>
              <Bootstrap.Modal.Body>
                <div className="Loop-moda-section">
                  <ul>
                    <li>
                      <label className="Loop-radio">
                        {" "}
                        <span>আয়াত বার বার শুনুন</span>
                        <input
                          type="radio"
                          name="loop_type"
                          value="ayat_single"
                          onChange={this.changeHandler}
                          defaultChecked={this.state.loop_type == "ayat_single"}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>

                    {this.props.krom == "sura" ? (
                      <Fragment>
                        <li>
                          <div className="ranges-Loop">
                            <label className="Loop-radio">
                              {" "}
                              <span>
                                নির্দিষ্ট আয়াত রেঞ্জ নির্বাচন করে শুনুন
                              </span>
                              {this.state.range_error ? (
                                <p className="text-left text-danger">
                                  Ayat range invalid
                                </p>
                              ) : (
                                ""
                              )}
                              <input
                                type="radio"
                                id="ayat_range_loop"
                                name="loop_type"
                                value="ayat_range"
                                onChange={this.changeHandler}
                                defaultChecked={
                                  this.state.loop_type == "ayat_range"
                                }
                              />
                              <span className="checkmark"></span>
                              <Bootstrap.Form.Row>
                                <Bootstrap.Form.Group
                                  as={Bootstrap.Col}
                                  md="4"
                                  className="mr-15"
                                >
                                  <Bootstrap.Form.Control
                                    type="number"
                                    id="ayat_range_start"
                                    placeholder="আয়াত নম্বর শুরু"
                                    name="ayat_range_start"
                                    value={this.state.ayat_range_start}
                                    onChange={this.changeHandler}
                                  />
                                </Bootstrap.Form.Group>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="4">
                                  <Bootstrap.Form.Control
                                    type="number"
                                    id="ayat_range_end"
                                    placeholder="আয়াত নম্বর শেষ"
                                    name="ayat_range_end"
                                    value={this.state.ayat_range_end}
                                    onChange={this.changeHandler}
                                  />
                                </Bootstrap.Form.Group>
                              </Bootstrap.Form.Row>
                            </label>
                          </div>
                        </li>
                        <li>
                          <label className="Loop-radio">
                            {" "}
                            <span>এই সুরা টি বার বার শুনুন</span>
                            <input
                              type="radio"
                              name="loop_type"
                              value="sura"
                              onChange={this.changeHandler}
                              defaultChecked={this.state.loop_type == "sura"}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="Loop-radio">
                            {" "}
                            <span>আল কুরআনের সব সুরা শুনুতে থাকুন</span>
                            <input
                              type="radio"
                              name="loop_type"
                              value="sura_all"
                              onChange={this.changeHandler}
                              defaultChecked={
                                this.state.loop_type == "sura_all"
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <li>
                          <label className="Loop-radio">
                            {" "}
                            <span>এই পারাটি বার বার শুনুন</span>
                            <input
                              type="radio"
                              name="loop_type"
                              value="para"
                              onChange={this.changeHandler}
                              defaultChecked={this.state.loop_type == "para"}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                        <li>
                          <label className="Loop-radio">
                            {" "}
                            <span>আল কুরআনের সব পারা শুনুতে থাকুন</span>
                            <input
                              type="radio"
                              name="loop_type"
                              value="para_all"
                              onChange={this.changeHandler}
                              defaultChecked={
                                this.state.loop_type == "para_all"
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </li>
                      </Fragment>
                    )}
                    <li>
                      <label className="Loop-radio">
                        {" "}
                        <span>রিপিট আপসন টি বন্ধ করন</span>
                        <input
                          type="radio"
                          name="loop_type"
                          value="off"
                          onChange={this.changeHandler}
                          defaultChecked={this.state.loop_type == "off"}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </li>
                  </ul>
                </div>
              </Bootstrap.Modal.Body>

              <Bootstrap.Modal.Footer>
                <Bootstrap.Button variant="secondary" onClick={this.stopLoop}>
                  {" "}
                  বাতিল{" "}
                </Bootstrap.Button>
                <Bootstrap.Button variant="primary" onClick={this.submitLoop}>
                  {" "}
                  ঠিক আছে{" "}
                </Bootstrap.Button>
              </Bootstrap.Modal.Footer>
            </Bootstrap.Modal>
          </Fragment>
        )}
        {this.state.dropdownOpenItem != null && (
          <div className="overlay" onClick={this.closeDropdown}></div>
        )}
      </Fragment>
    );
  }
}

export default withRouter(AlquranPlayer);
