import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';
import { toast } from "react-toastify";

export class HadithIndexDetails extends Component {

    scrollbars = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            hadith_name: '',
            index_name_bn: '',
            index_name_ar: '',
            hadithList: [],
            query_string: '',
            isEnded: false,
            error: '',
            offset: 0,
            limit: 10,
        }
    }


    componentDidMount() {
        this.getHadithIndexData()
    }

    getHadithIndexData = (query = this.state.query_string) => {
        if (!this.state.isEnded) {
            this.setState({ loading: true, isEnded: false })
            AJAX_PUBLIC_REQUEST("POST", "hadith/topic/details", {
                hadith_book_id: this.props.match.params.hadith_book_id === 'search' ? '' : this.props.match.params.hadith_book_id,
                index_id: this.props.match.params.index_id,
                hadith_no: this.props.match.params.hadith_no,
                query_string: query,
                offset: this.state.offset,
                limit: this.state.limit
            }).then(results => {
                if (results.type === "success") {
                    this.setState({
                        hadithList: results.data,
                        hadith_name: results.book_info ? results.book_info.book_name_bn : '',
                        index_name_bn: results.book_info ? results.book_info.index_name_bn : '',
                        index_name_ar: results.book_info ? results.book_info.index_name_ar : '',
                        loading: false,
                        offset: this.state.offset + this.state.limit,
                        isEnded: results.data.length > 0 ? false : true,
                        error: results.success
                    });
                } else {
                    this.setState({
                        error: results.error,
                        loading: false,
                        isEnded: false,
                    })
                }
            });
        }
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    goToSearchDetails = (e) => {
        if (this.state.query_string.length > 2) {
            this.getHadithIndexData()
        } else {
            toast.error('Please type at least 3 character')
        }
    }

    render() {
        return (
            <Fragment>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-sm-12 col-md-12">
                        <div className="download-section new-hadith-search-list">
                            <div className="hadith-name-list">
                                <ul>
                                    <li>হাদিস</li>
                                    {
                                        this.state.hadith_name != '' ?
                                            <Fragment>
                                                <li><i className="icofont-simple-right"></i></li>
                                                <li>{this.state.hadith_name}</li>
                                                <li><i className="icofont-simple-right"></i></li>
                                                <li> {this.state.index_name_bn} <span dir="rtl" lang="ar">({this.state.index_name_ar})</span></li>
                                            </Fragment>
                                            : ''
                                    }

                                </ul>
                            </div>
                            <div className="download-section-inner max-width-100 box-shadow-none">
                                {
                                    this.props.match.params.hadith_no ?
                                        ''
                                        :
                                        <div className="max-width-830 new-hadith-search-form-details">
                                            <div className="new-hadith-search-form">
                                                <Bootstrap.Form>
                                                    <Bootstrap.Form.Control type="text" placeholder="হাদিসে সার্চ করুন" onChange={this.changeHandler} name="query_string" value={this.state.query_string} />
                                                    <Bootstrap.Button variant="secondary" className="search-custom-btn-new" onClick={this.goToSearchDetails}>
                                                        <i className="fas fa-search"></i>
                                                    </Bootstrap.Button>
                                                </Bootstrap.Form>
                                            </div>
                                        </div>
                                }

                                <div className="nano">
                                    <div className="nano-content">
                                        <div className="para-item download-item">
                                            <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                {
                                                    this.state.loading ?
                                                        <div className="loading section-loading"> <i className="fas fa-spinner fa-spin"></i> </div>
                                                        :
                                                        <Fragment>
                                                            {
                                                                this.state.hadithList.length > 0 ?
                                                                    <Fragment>
                                                                        {
                                                                            this.state.hadithList.map((hadithList_single, hadithList_index) => {
                                                                                return (
                                                                                    <div className="max-width-830 new-hadith-search-details-item" key={`hadith-details-${hadithList_index}`}>
                                                                                        <div className="heading">
                                                                                            <h4>{hadithList_single.bangla_title}</h4>
                                                                                            <h5>{hadithList_single.sub_title_bn}</h5>
                                                                                        </div>
                                                                                        <NavLink className="details-item-info" to='/#'><i className="fas fa-info-circle"></i></NavLink>
                                                                                        <div className="new-hadith-search-details-rtl">
                                                                                            <p dir="rtl" lang="ar">{hadithList_single.arabic_details}</p>
                                                                                        </div>
                                                                                        <div className="new-hadith-search-details-bangla">
                                                                                            <p>{hadithList_single.bangla_details} ({hadithList_single.source})</p>
                                                                                        </div>
                                                                                    </div>

                                                                                )
                                                                            })
                                                                        }
                                                                    </Fragment>
                                                                    :
                                                                    <li>
                                                                        <div className="no_data_found">{this.state.error}</div>
                                                                    </li>
                                                            }
                                                        </Fragment>
                                                }

                                            </Scrollbars>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </Fragment >

        )
    }
}

export default HadithIndexDetails
