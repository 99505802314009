import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import Parser from "html-react-parser";
import {
  AJAX_PUBLIC_REQUEST,
  convertEnglishToBanglaNumber,
} from "../../Constants/AppConstants";

import * as Bootstrap from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars";

import ReactPlayer from "react-player";
import Duration from "../Common/Duration";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import classnames from "classnames";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
} from "react-share";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// Sharect.init();

class AlquranAyatSettings extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isChecked: false,
      isChecked2: true,
    };
    console.log(this.state);

  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  handleCheckboxChange2 = () => {
    this.setState({ isChecked2: !this.state.isChecked2 });
  };


  render() {
    console.log(this.props.state);
    const { show } = this.state;
    const { isChecked } = this.state;
    const { isChecked2 } = this.state;
    return (
      <Fragment>
        <div className="custom-checkbox-form-group pl-20px">

          <div className="custom-checkbox-form-group-inner" onClick={this.props.handleAyatSettings.bind(this,'showArabic')}>
            <div className="tafsir-input-area">
              {this.props.state.showArabic ? (
                <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
              ) : (
                <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
              )}
            </div>
            <label htmlFor="ayatSettings">আরবি</label>
          </div>

          <div className="custom-checkbox-form-group-inner" onClick={this.props.handleAyatSettings.bind(this,'showTranslation')}>
            <div className="tafsir-input-area">
              {this.props.state.showTranslation ? (
                <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
              ) : (
                <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
              )}
            </div>
            <label htmlFor="ayatSettings">বাংলা অনুবাদ</label>
            {/* <img className="chnage-icon" src={require('../../Assets/images/change-icon.svg')} alt="icon" title="" /> */}
          </div>

          <div className="custom-checkbox-form-group-inner" onClick={this.props.handleAyatSettings.bind(this,'showArabicBangla')}>
            {/* <input type="checkbox" id="ayatSettings" /> */}
            <div className="tafsir-input-area">
              {this.props.state.showArabicBangla ? (
                <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
              ) : (
                <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
              )}
            </div>
            <label htmlFor="ayatSettings">বাংলা উচ্চারণ</label>
          </div>



          </div>
          <Button variant="primary" onClick={this.handleShow}>
            modal
          </Button>
          <Modal show={this.props.state.atLeastOneNotification} onHide={this.props.handleAtLeastOneNotification} size="md" className="modal-main-sec">
            <Modal.Header closeButton className="modal-head">
              <Modal.Title className="ayat-modal-title">এলার্ট</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 max-height-399">
              <p></p>
              <p><b className="bold-stong">আরবি</b> এবং <b className="bold-stong">বাংলা অর্থ</b> এই দুইটির মধ্যে যে কোনো একটি সিলেক্ট থাকতে হবে </p>
            </Modal.Body>
            <Modal.Footer className="modal-cus-footer">
              <Button variant="primary" onClick={this.props.handleAtLeastOneNotification} className="modal-cus-footer-btn">
                ঠিক আছে
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={show} onHide={this.handleClose} size="md" className="modal-main-sec">
            <Modal.Header closeButton className="modal-head">
              <Modal.Title className="ayat-modal-title">বাংলা অনুবাদক নির্বাচন করুন</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 max-height-399">
              <div className="list-main">
                <ul className="list-items">
                  <li className="list" onClick={this.handleCheckboxChange}>
                    <div className="input-area">
                      {isChecked ? (
                        <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
                      ) : (
                        <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
                      )}
                    </div>
                    <div className="img-area">
                      <img className="prifile-img" src={require('../../Assets/images/profile-pic-img.svg')} alt="icon" title="" />
                    </div>
                    <label>মহীউদ্দিন খান</label>
                </li>
                <li className="list" onClick={this.handleCheckboxChange}>
                      <div className="input-area">
                        {isChecked ? (
                          <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
                        ) : (
                          <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
                        )}
                      </div>
                      <div className="img-area">
                        <img className="prifile-img" src={require('../../Assets/images/profile-pic-img.svg')} alt="icon" title="" />
                      </div>
                      <label>তাইসিরুল কুরআন</label>
                </li>
                <li className="list" onClick={this.handleCheckboxChange}>
                      <div className="input-area">
                        {isChecked ? (
                          <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
                        ) : (
                          <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
                        )}
                      </div>
                       <div className="img-area">
                        <img className="prifile-img" src={require('../../Assets/images/profile-pic-img.svg')} alt="icon" title="" />
                        </div>
                      <label>জহুরুল হক</label>
                </li>
                <li className="list" onClick={this.handleCheckboxChange}>
                      <div className="input-area">
                        {isChecked ? (
                          <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
                        ) : (
                          <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
                        )}
                      </div>
                       <div className="img-area">
                        <img className="prifile-img" src={require('../../Assets/images/profile-pic-img.svg')} alt="icon" title="" />
                        </div>
                      <label>জহুরুল হক</label>
                </li>
                <li className="list" onClick={this.handleCheckboxChange}>
                      <div className="input-area">
                        {isChecked ? (
                          <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
                        ) : (
                          <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
                        )}
                      </div>
                       <div className="img-area">
                        <img className="prifile-img" src={require('../../Assets/images/profile-pic-img.svg')} alt="icon" title="" />
                        </div>
                      <label>জহুরুল হক</label>
                </li>
                <li className="list" onClick={this.handleCheckboxChange}>
                      <div className="input-area">
                        {isChecked ? (
                          <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
                        ) : (
                          <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
                        )}
                      </div>
                       <div className="img-area">
                        <img className="prifile-img" src={require('../../Assets/images/profile-pic-img.svg')} alt="icon" title="" />
                        </div>
                      <label>জহুরুল হক</label>
                </li>
                <li className="list" onClick={this.handleCheckboxChange}>
                      <div className="input-area">
                        {isChecked ? (
                          <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
                        ) : (
                          <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
                        )}
                      </div>
                       <div className="img-area">
                        <img className="prifile-img" src={require('../../Assets/images/profile-pic-img.svg')} alt="icon" title="" />
                        </div>
                      <label>জহুরুল হক</label>
                </li>
                <li className="list" onClick={this.handleCheckboxChange}>
                      <div className="input-area">
                        {isChecked ? (
                          <img className="check-img" src={require('../../Assets/images/check-icon-img.svg')} alt="Checked Icon" title="Checked" />
                        ) : (
                          <img className="uncheck-img" src={require('../../Assets/images/uncheck-icon-img.svg')} alt="Unchecked Icon" title="Unchecked" />
                        )}
                      </div>
                       <div className="img-area">
                        <img className="prifile-img" src={require('../../Assets/images/profile-pic-img.svg')} alt="icon" title="" />
                        </div>
                      <label>জহুরুল হক</label>
                  </li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer className="modal-cus-footer">
              <Button variant="primary" onClick={this.handleClose} className="modal-cus-footer-btn">
                ঠিক আছে
              </Button>
            </Modal.Footer>
          </Modal>
      </Fragment>
    );
  }
}

export default withRouter(AlquranAyatSettings);
