import React, { PureComponent, Fragment } from 'react';
import Parser from 'html-react-parser';

import AWS from 'aws-sdk';

const spacesEndpoint = new AWS.Endpoint('nyc3.digitaloceanspaces.com');
const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    // accessKeyId: process.env.SPACES_KEY,
    // secretAccessKey: process.env.SPACES_SECRET
    accessKeyId: 'PE3LNNU4D3M6RWQSGOLD',
    secretAccessKey: 'Qq7TKaFsjcgThA8C+jfvkEtCxOMJ14v9FG3AbcutWJ0'
});

class Test extends PureComponent {
    constructor(props) {
        super(props)

    }

    componentDidMount() {
        console.log('s3', s3);


        // var params = {
        //     Bucket: "example-space-name"
        // };

        // s3.createBucket(params, function (err, data) {
        //     if (err) console.log(err, err.stack);
        //     else console.log(data);
        // });

        var params = {
            Bucket: "technobd-test",
        };

        s3.listObjects(params, function (err, data) {
            if (err) console.log(err, err.stack);
            else {
                data['Contents'].forEach(function (obj) {
                    console.log(obj['Key']);
                })
            };
        });
    }

    render() {
        return (
            <div>This is a test page</div>
        );
    }
}

export default Test;