import React, { PureComponent, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { passwordResetRequest } from '../../Store/actions/passwordResetActions';
import { addFlashMessage } from '../../Store/actions/flashMessages';
import { logout } from '../../Store/actions/loginActions';
import history from '../../history';
import PasswordResetForm from './PasswordResetForm';
import { AJAX_REQUEST, TITLE, GET_STORAGE, USER } from '../../Constants/AppConstants';

class PasswordReset extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: true
        }
        document.title = "Password Reset - " + TITLE;
    }

    changeHandler = (e) => {
        [e.target.name] = e.target.value;
    }

    async componentDidMount() {
        document.querySelector("body").scrollIntoView();
        const cur_storage = await JSON.parse(GET_STORAGE(USER));
        if (cur_storage) {
            history.push('/profile');
        }
        this.setState({
            loading: false
        });
    }

    render() {
        const { passwordResetRequest, addFlashMessage } = this.props;
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <React.Fragment>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <main>
                                            <div className="page-content entry-content">
                                                <PasswordResetForm passwordResetRequest={passwordResetRequest} addFlashMessage={addFlashMessage} />
                                            </div>
                                        </main>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                }
            </Fragment>
        );
    }
}

PasswordReset.propTypes = {
    passwordResetRequest: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

export default connect(mapStateToProps, { passwordResetRequest })(PasswordReset);