import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class Home extends PureComponent {

    scrollbars = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pagination_loading: false,
            player_loading: true,
            para_loading: true,
            sura_loading: true,
            ayat_loading: true,
            offset: 0,
            per_page: 20,
            error: '',
            para_error: '',
            sura_error: '',
            ayat_error: '',
            paraList: [],
            suraList: [],
            ayatList: [],
            activePara: '',
            activeSura: '',

            activeAyat: '',
            currently_playing_ayat_no: '',
            currently_playing_sura_no: '',
            currently_playing_para_no: '',
            currently_playing_sura_name_bn: '',
            currently_playing_sura_name_en: '',
            currently_playing_sura_no_bn: '',
            currently_playing_prev_ayat_data: '',
            currently_playing_ayat_data: '',
            currently_playing_next_ayat_data: '',
            currently_playing_mode: "a",
            playing_mode: "ab",
            sura_total_ayat: 0,


            //player state
            url: null,
            pip: false,
            playing: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false
        }
        document.title = "Al Quran - By Sura";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.setState({
            loading: false
        });
        this.getSuraList();
    }

    // getParaList = () => {
    //     this.setState({ para_loading: true })
    //     AJAX_PUBLIC_REQUEST("POST", "getParaList", {}).then(results => {
    //         if (results.type === "success") {
    //             if (results.data.hasOwnProperty('para')) {
    //                 const para = results.data.para;
    //                 this.setState({
    //                     paraList: para,
    //                     para_loading: false,
    //                 });
    //                 if (para.length > 0) {
    //                     const first_para_no = para[0];
    //                     if (first_para_no && first_para_no.para_no) {
    //                         this.setState({ activePara: first_para_no.para_no })
    //                         // this.getSuraList(first_para_no.para_no);
    //                         this.getAyatList(first_para_no.para_no);
    //                     }
    //                 }
    //             } else {
    //                 this.setState({
    //                     paraList: [],
    //                     error: Parser("<p classNameName='text-danger'>No Data Found</p>"),
    //                     para_loading: false,
    //                 });
    //             }
    //         } else {
    //             this.setState({
    //                 error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
    //                 para_loading: false,
    //             })
    //         }
    //     });
    // }

    getSuraList = (para_no = '') => {
        this.setState({ activePara: para_no, sura_loading: true, ayat_loading: true });
        let data = {};
        if (para_no && (para_no != '')) {
            data.para_no = para_no
        }
        AJAX_PUBLIC_REQUEST("POST", "api/getSuraList", data).then(results => {
            if (results.type === "success") {
                const sura = results.data;
                this.setState({
                    suraList: sura,
                    sura_loading: false,
                });
                if (sura.length > 0) {
                    const first_sura_no = sura[0];
                    if (first_sura_no && first_sura_no.sura_no) {
                        this.setState({ activeSura: first_sura_no.sura_no })
                        this.getAyatList(para_no, first_sura_no.sura_no);
                    }
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    sura_loading: false,
                })
            }
        });
    }

    getAyatList = (para_no, sura_no = false, offset = 0) => {
        this.setState({ activePara: para_no, activeSura: sura_no, ayat_loading: true });
        let data = {
            offset: offset,
            para_no: para_no
        }
        if (sura_no) {
            data.sura_no = sura_no
        }
        AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then(results => {
            console.log(results)
            if (results.type === "success") {
                const ayat = results.data;
                this.setState({
                    ayatList: ayat,
                    offset: this.state.per_page,
                    ayat_loading: false,
                });
                if (ayat.length > 0) {
                    const first_ayat_no = ayat[0];
                    if (first_ayat_no && first_ayat_no.ayat_no) {
                        this.setState({ activeAyat: first_ayat_no.ayat_no, sura_total_ayat: first_ayat_no.sura_total_ayat, player_loading: false });
                        if (ayat.length > 1) {
                            this.setState({ currently_playing_next_ayat_data: ayat[1] });
                        }
                        this.load(first_ayat_no.ar_audio, first_ayat_no.ayat_no, false)
                    }
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    ayat_loading: false,
                    player_loading: false
                })
            }
        });
    }


    // player functions
    load = async (url, ayat_no, playing = true) => {
        let activeAyat = parseInt(ayat_no);
        let currently_playing_ayat_no = parseInt(ayat_no);
        let currently_playing_sura_no = '';
        let currently_playing_para_no = '';
        let currently_playing_sura_name_bn = '';
        let currently_playing_sura_name_en = '';
        let currently_playing_sura_no_bn = '';
        let currently_playing_prev_ayat_data = '';
        let selected_ayat_data = '';
        let currently_playing_next_ayat_data = '';

        const prev_ayat_no = parseInt(ayat_no) - 1;
        const next_ayat_no = parseInt(ayat_no) + 1;


        if (this.state.ayatList.length > 0) {
            await this.state.ayatList.map((ayatList_single, index) => {
                if (parseInt(ayatList_single.ayat_no) === parseInt(ayat_no)) {
                    selected_ayat_data = ayatList_single;
                    currently_playing_sura_no = selected_ayat_data.sura_no;
                    currently_playing_para_no = this.state.activePara;
                    currently_playing_sura_name_bn = selected_ayat_data.sura_name_bn;
                    currently_playing_sura_name_en = selected_ayat_data.sura_name_en;
                    currently_playing_sura_no_bn = selected_ayat_data.bn_sura_no;
                }
                if (parseInt(ayatList_single.ayat_no) === prev_ayat_no) {
                    currently_playing_prev_ayat_data = ayatList_single;
                }
                if (parseInt(ayatList_single.ayat_no) === next_ayat_no) {
                    currently_playing_next_ayat_data = ayatList_single;
                }
            })

            // selected_ayat_data = await this.state.ayatList.filter((ayatList_single, index) => {
            //     return parseInt(ayatList_single.ayat_no) === parseInt(ayat_no);
            // })
            // if ((selected_ayat_data != '') && (selected_ayat_data.length > 0)) {
            //     selected_ayat_data = selected_ayat_data[0];
            //     currently_playing_sura_no = selected_ayat_data.sura_no;
            //     currently_playing_para_no = this.state.activePara;
            //     currently_playing_sura_name_bn = selected_ayat_data.sura_name_bn;
            //     currently_playing_sura_name_en = selected_ayat_data.sura_name_en;
            //     currently_playing_sura_no_bn = selected_ayat_data.bn_sura_no;
            //     console.log('selected_ayat_data', selected_ayat_data)
            // }
        }

        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false,
            playing: playing,
            activeAyat: activeAyat,
            currently_playing_ayat_no: currently_playing_ayat_no,
            currently_playing_sura_no: currently_playing_sura_no,
            currently_playing_para_no: currently_playing_para_no,
            currently_playing_sura_name_bn: currently_playing_sura_name_bn,
            currently_playing_sura_name_en: currently_playing_sura_name_en,
            currently_playing_sura_no_bn: currently_playing_sura_no_bn,
            currently_playing_prev_ayat_data: currently_playing_prev_ayat_data,
            currently_playing_ayat_data: selected_ayat_data,
            currently_playing_next_ayat_data: currently_playing_next_ayat_data
        })
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    handleStop = () => {
        this.setState({ url: null, playing: false })
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }

    handleSetPlaybackRate = e => {
        this.setState({ playbackRate: parseFloat(e.target.value) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {
        console.log('onPlay')
        this.setState({ playing: true })
    }

    handleEnablePIP = () => {
        console.log('onEnablePIP')
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        console.log('onDisablePIP')
        this.setState({ pip: false })
    }

    handlePause = () => {
        console.log('onPause')
        this.setState({ playing: false })
    }

    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = async () => {
        console.log('onEnded')
        const currently_playing_ayat_no = this.state.currently_playing_ayat_no;
        const currently_playing_mode = this.state.currently_playing_mode;
        const playing_mode = this.state.playing_mode;
        if ((playing_mode == 'ab') && (currently_playing_mode == 'a')) {
            const currently_playing_ayat_data = this.state.currently_playing_ayat_data;
            if (currently_playing_ayat_data != '') {
                this.setState({ currently_playing_mode: 'b' });
                this.load(currently_playing_ayat_data.bn_audio, currently_playing_ayat_data.ayat_no);
            }
        }
        if ((playing_mode == 'ab') && (currently_playing_mode == 'b')) {
            const currently_playing_ayat_data = this.state.currently_playing_ayat_data;
            //check next ayat available or not
            const next_ayat_data = await this.state.ayatList.filter((ayatList_single, index) => {
                return parseInt(ayatList_single.ayat_no) === parseInt(currently_playing_ayat_no) + 1;
            })

            if ((next_ayat_data != '') && (next_ayat_data.length > 0)) {
                next_ayat_data = next_ayat_data[0];
                this.setState({ currently_playing_mode: 'a' });
                this.load(next_ayat_data.ar_audio, next_ayat_data.ayat_no);
                console.log('next_ayat_data', next_ayat_data)

            }
        }
        this.setState({ playing: this.state.loop })
    }

    handleDuration = (duration) => {
        console.log('onDuration', duration)
        this.setState({ duration })
    }

    handleClickFullscreen = () => {
        screenfull.request(findDOMNode(this.player))
    }

    renderLoadButton = (url, label) => {
        return (
            <button onClick={() => this.load(url)}>
                {label}
            </button>
        )
    }

    ref = player => {
        this.player = player
    }


    //custom playing action


    //scrollbar handler
    handleScrollStop = () => {
        if (this.scrollbars.current) {
            console.log('viewScrollTop', this.scrollbars.current.viewScrollTop)
            console.log('clientHeight', this.scrollbars.current.view.clientHeight)
            console.log('offsetHeight', document.getElementById('CusScrollbars-ul').offsetHeight)
            if ((this.scrollbars.current.viewScrollTop + this.scrollbars.current.view.clientHeight) === document.getElementById('CusScrollbars-ul').offsetHeight) {
                if (!this.state.pagination_loading) {
                    if (this.state.sura_total_ayat > this.state.offset) {
                        this.setState({ pagination_loading: true });

                        let data = {
                            offset: this.state.offset,
                            para_no: this.state.activePara,
                            sura_no: this.state.activeSura
                        }
                        AJAX_PUBLIC_REQUEST("POST", "api/getAyatList", data).then(results => {
                            if (results.type === "success") {

                                const added_ayat_list = results.data;
                                const old_ayat_list = this.state.ayatList;
                                const new_ayat_list = old_ayat_list.concat(added_ayat_list);

                                const ayat = results.data;
                                this.setState({
                                    ayatList: new_ayat_list,
                                    offset: this.state.offset + this.state.per_page,
                                    pagination_loading: false,
                                });
                            } else {
                                this.setState({
                                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                                    pagination_loading: false,
                                    player_loading: false
                                })
                            }
                        });

                        // AJAX_SOCKET_CLIENT_REQUEST('post', 'getConversationList', { offset: this.state.offset, gid: this.state.gid }).then(results => {
                        //     if (results.status == 'success') {
                        //         const added_conversation_list = results.data.messages;
                        //         const old_conversation_list = this.state.conversation_list;
                        //         const new_conversation_list = added_conversation_list.concat(old_conversation_list);
                        //         this.setState({
                        //             conversation_list: new_conversation_list,
                        //             loading: false,
                        //             offset: this.state.offset + results.data.messages.length,
                        //             pagination_loading: false,
                        //         });
                        //         const chatIndex = added_conversation_list[results.data.messages.length - 1];
                        //         const createdId = 'chatDetails-' + chatIndex.id;

                        //         setTimeout(function () {
                        //             if (document.getElementById(createdId)) {
                        //                 // document.getElementById(createdId).scrollIntoView();
                        //                 document.getElementById(createdId).scrollTop = document.getElementById(createdId).scrollHeight + 100;
                        //                 document.getElementById(createdId).scrollIntoView();
                        //             }
                        //         }.bind(this), 100)
                        //     } else {
                        //         // pagination_loading=false;
                        //         this.setState({
                        //             loading: false,
                        //             pagination_loading: false,
                        //         });
                        //     }
                        // });
                    }
                    console.log('pagination_loading', this.state.pagination_loading);
                }
            }
        }
    }

    render() {

        const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state
        const SEPARATOR = ' · '

        return (
            <Fragment>
                {
                    (this.state.loading) ?
                        <div className="loading container full_page_loader"></div>
                        :
                        <Fragment>

                            {/* player wrapper start */}
                            <div style={{ display: 'none' }}>
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        ref={this.ref}
                                        className='react-player'
                                        width='100%'
                                        height='100%'
                                        url={url}
                                        pip={pip}
                                        playing={playing}
                                        controls={controls}
                                        light={light}
                                        loop={loop}
                                        playbackRate={playbackRate}
                                        volume={volume}
                                        muted={muted}
                                        onReady={() => console.log('onReady')}
                                        onStart={() => console.log('onStart')}
                                        onPlay={this.handlePlay}
                                        onEnablePIP={this.handleEnablePIP}
                                        onDisablePIP={this.handleDisablePIP}
                                        onPause={this.handlePause}
                                        onBuffer={() => console.log('onBuffer')}
                                        onSeek={e => console.log('onSeek', e)}
                                        onEnded={this.handleEnded}
                                        onError={e => console.log('onError', e)}
                                        onProgress={this.handleProgress}
                                        onDuration={this.handleDuration}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Controls</th>
                                                    <td>
                                                        <button onClick={this.handleStop}>Stop</button>
                                                        <button onClick={this.handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>
                                                        <button onClick={this.handleClickFullscreen}>Fullscreen</button>
                                                        {light &&
                                                            <button onClick={() => this.player.showPreview()}>Show preview</button>}
                                                        {ReactPlayer.canEnablePIP(url) &&
                                                            <button onClick={this.handleTogglePIP}>{pip ? 'Disable PiP' : 'Enable PiP'}</button>}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Speed</th>
                                                    <td>
                                                        <button onClick={this.handleSetPlaybackRate} value={1}>1x</button>
                                                        <button onClick={this.handleSetPlaybackRate} value={1.5}>1.5x</button>
                                                        <button onClick={this.handleSetPlaybackRate} value={2}>2x</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Seek</th>
                                                    <td>
                                                        <input
                                                            type='range' min={0} max={0.999999} step='any'
                                                            value={played}
                                                            onMouseDown={this.handleSeekMouseDown}
                                                            onChange={this.handleSeekChange}
                                                            onMouseUp={this.handleSeekMouseUp}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Volume</th>
                                                    <td>
                                                        <input type='range' min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label htmlFor='controls'>Controls</label>
                                                    </th>
                                                    <td>
                                                        <input id='controls' type='checkbox' checked={controls} onChange={this.handleToggleControls} />
                                                        <em>&nbsp; Requires player reload</em>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label htmlFor='muted'>Muted</label>
                                                    </th>
                                                    <td>
                                                        <input id='muted' type='checkbox' checked={muted} onChange={this.handleToggleMuted} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label htmlFor='loop'>Loop</label>
                                                    </th>
                                                    <td>
                                                        <input id='loop' type='checkbox' checked={loop} onChange={this.handleToggleLoop} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <label htmlFor='light'>Light mode</label>
                                                    </th>
                                                    <td>
                                                        <input id='light' type='checkbox' checked={light} onChange={this.handleToggleLight} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Played</th>
                                                    <td><progress max={1} value={played} /></td>
                                                </tr>
                                                <tr>
                                                    <th>Loaded</th>
                                                    <td><progress max={1} value={loaded} /></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="col-md-4">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>YouTube</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.youtube.com/watch?v=oUFJJNQGwhk', 'Test A')}
                                                        {this.renderLoadButton('https://www.youtube.com/watch?v=jNgP6d9HraI', 'Test B')}
                                                        {this.renderLoadButton('https://www.youtube.com/playlist?list=PLogRWNZ498ETeQNYrOlqikEML3bKJcdcx', 'Playlist')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>SoundCloud</th>
                                                    <td>
                                                        {this.renderLoadButton('https://soundcloud.com/miami-nights-1984/accelerated', 'Test A')}
                                                        {this.renderLoadButton('https://soundcloud.com/tycho/tycho-awake', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Facebook</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.facebook.com/facebook/videos/10153231379946729/', 'Test A')}
                                                        {this.renderLoadButton('https://www.facebook.com/FacebookDevelopers/videos/10152454700553553/', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Vimeo</th>
                                                    <td>
                                                        {this.renderLoadButton('https://vimeo.com/90509568', 'Test A')}
                                                        {this.renderLoadButton('https://vimeo.com/169599296', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Twitch</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.twitch.tv/videos/106400740', 'Test A')}
                                                        {this.renderLoadButton('https://www.twitch.tv/videos/12783852', 'Test B')}
                                                        {this.renderLoadButton('https://www.twitch.tv/kronovi', 'Test C')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Streamable</th>
                                                    <td>
                                                        {this.renderLoadButton('https://streamable.com/moo', 'Test A')}
                                                        {this.renderLoadButton('https://streamable.com/ifjh', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Wistia</th>
                                                    <td>
                                                        {this.renderLoadButton('https://home.wistia.com/medias/e4a27b971d', 'Test A')}
                                                        {this.renderLoadButton('https://home.wistia.com/medias/29b0fbf547', 'Test B')}
                                                        {this.renderLoadButton('https://home.wistia.com/medias/bq6epni33s', 'Test C')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>DailyMotion</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.dailymotion.com/video/x5e9eog', 'Test A')}
                                                        {this.renderLoadButton('https://www.dailymotion.com/video/x61xx3z', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Mixcloud</th>
                                                    <td>
                                                        {this.renderLoadButton('https://www.mixcloud.com/mixcloud/meet-the-curators/', 'Test A')}
                                                        {this.renderLoadButton('https://www.mixcloud.com/mixcloud/mixcloud-curates-4-mary-anne-hobbs-in-conversation-with-dan-deacon/', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Vidyard</th>
                                                    <td>
                                                        {this.renderLoadButton('https://video.vidyard.com/watch/YBvcF2BEfvKdowmfrRwk57', 'Test A')}
                                                        {this.renderLoadButton('https://video.vidyard.com/watch/BLXgYCDGfwU62vdMWybNVJ', 'Test B')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Files</th>
                                                    <td>
                                                        {this.renderLoadButton('https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4', 'mp4')}
                                                        {this.renderLoadButton('https://test-videos.co.uk/vids/bigbuckbunny/webm/vp8/360/Big_Buck_Bunny_360_10s_1MB.webm', 'webm')}
                                                        {this.renderLoadButton('https://filesamples.com/samples/video/ogv/sample_640x360.ogv', 'ogv')}
                                                        {this.renderLoadButton('https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3', 'mp3')}
                                                        <br />
                                                        {this.renderLoadButton('https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8', 'HLS (m3u8)')}
                                                        {this.renderLoadButton('http://dash.edgesuite.net/envivio/EnvivioDash3/manifest.mpd', 'DASH (mpd)')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Custom URL</th>
                                                    <td>
                                                        <input ref={input => { this.urlInput = input }} type='text' placeholder='Enter URL' />
                                                        <button onClick={() => this.setState({ url: this.urlInput.value })}>Load</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="col-md-4">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>url</th>
                                                    <td className={!url ? 'faded' : ''}>
                                                        {(url instanceof Array ? 'Multiple' : url) || 'null'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>playing</th>
                                                    <td>{playing ? 'true' : 'false'}</td>
                                                </tr>
                                                <tr>
                                                    <th>volume</th>
                                                    <td>{volume.toFixed(3)}</td>
                                                </tr>
                                                <tr>
                                                    <th>played</th>
                                                    <td>{played.toFixed(3)}</td>
                                                </tr>
                                                <tr>
                                                    <th>loaded</th>
                                                    <td>{loaded.toFixed(3)}</td>
                                                </tr>
                                                <tr>
                                                    <th>duration</th>
                                                    <td><Duration seconds={duration} /></td>
                                                </tr>
                                                <tr>
                                                    <th>elapsed</th>
                                                    <td><Duration seconds={duration * played} /></td>
                                                </tr>
                                                <tr>
                                                    <th>remaining</th>
                                                    <td><Duration seconds={duration * (1 - played)} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            {/* player wrapper end */}
                            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">

                                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 plr-2-5">
                                    <div className="para-title">সুরা সমুহ</div>
                                    {
                                        this.state.sura_loading ?
                                            <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i></div>
                                            :
                                            <Fragment>
                                                <div className="nano">
                                                    <div className="nano-content">
                                                        <div className="para-item surah-item">
                                                            <ul>
                                                                {
                                                                    this.state.suraList.length > 0 ?
                                                                        <Fragment>
                                                                            {
                                                                                this.state.suraList.map((suraList_single, index) => {
                                                                                    return (
                                                                                        <li onClick={() => this.getAyatList(suraList_single.para_no, suraList_single.sura_no)} key={`sura_no_${suraList_single.sura_no}`} className={classnames(null, { 'sellect': parseInt(suraList_single.sura_no) === parseInt(this.state.activeSura) })}>
                                                                                            <div className="surah-item-left">
                                                                                                <div className="para-number surah-number">
                                                                                                    <span>{suraList_single.bn_sura_no}</span>
                                                                                                </div>
                                                                                                <div className="surah-number-no">
                                                                                                    {suraList_single.name_bn}
                                                                                                    <span>{suraList_single.name_en}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="surah-item-right">
                                                                                                {suraList_single.name_ar}
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <div className="error-msg">{this.state.sura_error}</div>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="boxscroll2">

                                                </div>
                                            </Fragment>
                                    }
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 plr-2-5">
                                    <div className="para-title-box">
                                        <div className="para-title">আয়াত সমুহ</div>
                                        <div className="para-title"> সুরা:<span> আল ফাতিহা </span></div>
                                    </div>
                                    {
                                        this.state.ayat_loading ?
                                            <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                            :
                                            <div className="nano">
                                                <div className="nano-content">
                                                    <div className="para-item ayata-item">
                                                        <Scrollbars onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" style={{ height: '100%' }} ref={this.scrollbars}>
                                                            <ul id="CusScrollbars-ul">
                                                                {
                                                                    this.state.ayatList.length > 0 ?
                                                                        <Fragment>
                                                                            {
                                                                                this.state.ayatList.map((ayatList_single, index) => {
                                                                                    return (
                                                                                        <li key={`ayat_no_${ayatList_single.ayat_no}`} className={classnames(null, { 'sellect': parseInt(ayatList_single.ayat_no) === parseInt(this.state.activeAyat) })}>
                                                                                            <div className="ayata-item-box">
                                                                                                <div className="ayata-title">{ayatList_single.ar_text}</div>
                                                                                                <div className="ayata-item-start">
                                                                                                    <ul>
                                                                                                        <li><span>{ayatList_single.bn_sura_ayat_no} ।</span>{ayatList_single.bn_pronunciation_text}</li>
                                                                                                        <li className="bangla"><span>{ayatList_single.bn_sura_ayat_no} ।</span> {ayatList_single.bn_text}
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>

                                                                                                {
                                                                                                    (this.state.playing_mode == 'a') || (this.state.playing_mode == 'ab') ? (this.state.playing && (this.state.currently_playing_ayat_no === parseInt(ayatList_single.ayat_no)) ?
                                                                                                        <button type="button" onClick={this.handlePlayPause} className="pause-button">
                                                                                                            <span></span>
                                                                                                        </button>
                                                                                                        :
                                                                                                        <button type="button" onClick={() => this.load(ayatList_single.ar_audio, ayatList_single.ayat_no)} className="play-button">
                                                                                                            <span></span>
                                                                                                        </button>
                                                                                                    )
                                                                                                        : ''
                                                                                                }

                                                                                                {
                                                                                                    (this.state.playing_mode == 'b') ? (this.state.playing && (this.state.currently_playing_ayat_no === parseInt(ayatList_single.ayat_no)) ?
                                                                                                        < button type="button" onClick={this.handlePlayPause} className="pause-button">
                                                                                                            <span></span>
                                                                                                        </button>
                                                                                                        :
                                                                                                        < button type="button" onClick={() => this.load(ayatList_single.bn_audio, ayatList_single.ayat_no)} className="play-button">
                                                                                                            <span></span>
                                                                                                        </button>
                                                                                                    )

                                                                                                        : ''
                                                                                                }

                                                                                                {/* {
                                                                                                this.state.currently_playing_ayat_no === parseInt(ayatList_single.ayat_no) ?
                                                                                                    <button type="button" onClick={() => this.load('https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3')} className="pause-button">
                                                                                                        <span></span>
                                                                                                    </button>
                                                                                                    :
                                                                                                    <button type="button" onClick={() => this.load('https://storage.googleapis.com/media-session/elephants-dream/the-wires.mp3')} className="play-button">
                                                                                                        <span></span>
                                                                                                    </button>
                                                                                            } */}


                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Fragment>
                                                                        :
                                                                        <div className="error-msg">{this.state.sura_error}</div>
                                                                }
                                                            </ul>
                                                            {
                                                                this.state.pagination_loading ?
                                                                    <div className="text-center message_scroll_loading">
                                                                        <p className="text-center"><i className="fas fa-spinner fa-spin fa-2x fa-fw"></i></p>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </Scrollbars>
                                                    </div>

                                                </div>
                                            </div>
                                    }
                                </div>

                                <div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div className="full-play">
                                        <div className="full-play-inner">
                                            {
                                                this.state.player_loading ?
                                                    <div className="loading player-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                                    :
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="full-play-surah">
                                                                <div className="para-number">
                                                                    <span>{this.state.currently_playing_sura_no_bn}</span>
                                                                </div>
                                                                <div className="para-number-no full-play-name">
                                                                    {this.state.currently_playing_sura_name_bn}
                                                                    <span>{this.state.currently_playing_sura_name_en}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="full-play-btn-area">
                                                                <ul>
                                                                    <li>
                                                                        {
                                                                            this.state.currently_playing_prev_ayat_data != '' ?
                                                                                <Fragment>
                                                                                    {
                                                                                        ((this.state.playing_mode == 'ab') || (this.state.playing_mode == 'a')) ?
                                                                                            <button className="full-play-btn-left" onClick={() => this.load(this.state.currently_playing_prev_ayat_data.ar_audio, this.state.currently_playing_prev_ayat_data.ayat_no)}></button>
                                                                                            :
                                                                                            <button className="full-play-btn-left" onClick={() => this.load(this.state.currently_playing_prev_ayat_data.bn_audio, this.state.currently_playing_prev_ayat_data.ayat_no)}></button>
                                                                                    }
                                                                                </Fragment>
                                                                                :
                                                                                <button className="full-play-btn-left"></button>
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        <div className="pause-dropdown-block">
                                                                            {
                                                                                playing ?
                                                                                    <button type="button" onClick={this.handlePlayPause} className="pause-button">
                                                                                        <span></span>
                                                                                    </button>
                                                                                    :
                                                                                    <button type="button" onClick={this.handlePlayPause} className="full-play-btn-play-pause"></button>
                                                                            }

                                                                            <Bootstrap.DropdownButton className="dropdown-basic-button" id="dropdown-basic-button" title="আরবি তেলাওয়াত এবং বাংলা অর্থ " drop='up'>
                                                                                <Bootstrap.Dropdown.Item href="#/action-1">বাংলা অর্থ </Bootstrap.Dropdown.Item>
                                                                                <Bootstrap.Dropdown.Item href="#/action-2">আরবি তেলাওয়াত </Bootstrap.Dropdown.Item>
                                                                                <Bootstrap.Dropdown.Item href="#/action-3">আরবি তেলাওয়াত এবং বাংলা অর্থ </Bootstrap.Dropdown.Item>
                                                                            </Bootstrap.DropdownButton>
                                                                        </div>


                                                                    </li>
                                                                    <li>
                                                                        {
                                                                            this.state.currently_playing_next_ayat_data != '' ?
                                                                                <Fragment>
                                                                                    {
                                                                                        ((this.state.playing_mode == 'ab') || (this.state.playing_mode == 'a')) ?
                                                                                            <button className="full-play-btn-right" onClick={() => this.load(this.state.currently_playing_next_ayat_data.ar_audio, this.state.currently_playing_next_ayat_data.ayat_no)}></button>
                                                                                            :
                                                                                            <button className="full-play-btn-right" onClick={() => this.load(this.state.currently_playing_next_ayat_data.bn_audio, this.state.currently_playing_next_ayat_data.ayat_no)}></button>
                                                                                    }
                                                                                </Fragment>
                                                                                :
                                                                                <button className="full-play-btn-right"></button>
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        <span><Duration seconds={duration * played} /> / <Duration seconds={duration} /></span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="play-normal">
                                                                {
                                                                    this.state.playbackRate === 1 ?
                                                                        <button onClick={this.handleSetPlaybackRate} value={1.5} className="play-normal-btn"></button>
                                                                        : (
                                                                            this.state.playbackRate === 1.5 ?
                                                                                <button onClick={this.handleSetPlaybackRate} value={2} className="play-normal-btn"></button>
                                                                                :
                                                                                <button onClick={this.handleSetPlaybackRate} value={1} className="play-normal-btn"></button>
                                                                        )
                                                                }
                                                                <span>{this.state.playbackRate === 1 ? 'Normal' : (this.state.playbackRate === 1.5 ? 'Medium' : 'Fast')}</span>
                                                            </div>
                                                            {this.state.loop ?
                                                                <button className="active-loop" onClick={this.handleToggleLoop}></button>
                                                                :
                                                                <button className="no-loop" onClick={this.handleToggleLoop}></button>
                                                            }

                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Fragment>
                }
            </Fragment>
        );
    }
}

export default withRouter(Home);