import history from "../history";
import $ from "jquery";

export const APP_VERSION = "2.0.8";
export const TITLE = "Al Quran - Technobd";

//For Dev
// export const BASE_URL = 'https://dev.alquran-bangla.com/';
// export const BASE_DOMAIN = '.alquran-bangla.com';
// export const API_URL = 'https://dev-manage.alquran-bangla.com/api/';

//For Live
export const BASE_URL = "https://alquran-bangla.com/";
export const BASE_DOMAIN = ".alquran-bangla.com";
export const API_URL = "https://manage.alquran-bangla.com/api/";

//For local
// export const API_URL = 'https://al-quran.technobdapis.com/api/';
// export const BASE_URL = 'http://localhost:3000/'; // Nayan Vai
// export const BASE_DOMAIN = '.localhost';
// export const API_URL = 'http://localhost/technobd/al-quran-aws/api/';

export const API_KEY = "^)@$!";
export const DOWNTIME_ACCESS_CODE = "wewillbeup";

export const SITEDOWN_DATA = {
  downtime_status: 1,
  server_down: 1,
  downtime_access_validity: "invalid",
  downtime_message:
    "<h3>A technical error has occurred</h3><p>Please try again in a few minutes. Thanks!</p>",
};

export const SET_STORAGE = (name, value) => {
  return localStorage.setItem(name, value);
};
export const GET_STORAGE = (name) => {
  return localStorage.getItem(name);
};
export const REMOVE_STORAGE = (name) => {
  return localStorage.removeItem(name);
};
export const USER = "aqu";
export const DELETION_USER = "deletion_user";

let COOKIE_EXP_DAY_P = 5;
if (GET_STORAGE("settings")) {
  const settings = JSON.parse(GET_STORAGE("settings"));
  COOKIE_EXP_DAY_P = settings.cookie_exp_day
    ? parseInt(settings.cookie_exp_day)
    : 7;
}
export const COOKIE_EXP_DAY = COOKIE_EXP_DAY_P;

export function SET_COOKIE(cname, cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + COOKIE_EXP_DAY * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function SET_LOGIN_COOKIE(data) {
  let expireAfter = new Date();
  //setting up cookie expire date after 10 minutes
  expireAfter.setMinutes(expireAfter.getMinutes() + 10);
  //now setup cookie
  document.cookie =
    USER +
    "=" +
    data +
    "; domain=" +
    BASE_DOMAIN +
    "; expires=" +
    expireAfter.toUTCString() +
    "; path=/";
}

export function DELETE_LOGIN_COOKIE() {
  let expireAfter = new Date();
  const exdays = -1;
  //setting up cookie expire date after 10 minutes
  expireAfter.setTime(expireAfter.getTime() + exdays * 24 * 60 * 60 * 1000);
  //now setup cookie
  document.cookie =
    USER +
    "='data'; domain=" +
    BASE_DOMAIN +
    "; expires=" +
    expireAfter.toUTCString() +
    "; path=/";
}

export function GET_COOKIE(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function DELETE_COOKIE(cname) {
  var cvalue = "",
    exdays = -1;
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function MATCH_REPLACE(string, query) {
  if (string.length > 0) {
    var replaced_string = string.replace(
      query,
      "<strong>" + query + "</strong>",
    );
    return replaced_string;
  } else {
    return "<span></span>";
  }
}

export function AJAX_REQUEST(type = "GET", additional_url, data) {
  const c_user = JSON.parse(GET_STORAGE(USER));
  data.api_key = API_KEY;
  if (c_user) {
    // data.user_token = c_user.token;
  } else {
    history.push("/login");
  }

  let rdata = "";
  let promise = $.Deferred();
  $.ajax({
    type: type,
    url: API_URL + additional_url,
    data: data,
    headers: {
      Authorization: `Bearer ${c_user.token}`,
    },
    timeout: 60000,
    success: function (resp) {
      if (resp) {
        if (typeof resp === "object") {
          if (resp.hasOwnProperty("type")) {
            promise.resolve(resp);
          } else {
            const respo = {
              code: 100,
              data: {},
              error: "Something went wrong. Please try again.",
              type: "error",
            };
            promise.resolve(respo);
          }
        } else {
          const respo = {
            code: 100,
            data: {},
            error: "Something went wrong. Please try again.",
            type: "error",
          };
          promise.resolve(respo);
        }
      } else {
        const respo = {
          code: 100,
          data: {},
          error: "Something went wrong. Please refresh the page again.",
          type: "error",
        };
        promise.resolve(respo);
      }
    },
    error: function (resp, textStatus) {
      if (textStatus === "timeout") {
        const respo = {
          code: 100,
          data: {},
          error: "Connection timeout. Please try again.",
          type: "error",
        };
        promise.resolve(respo);
      } else {
        if (resp.status === 500) {
          const respo = {
            code: 100,
            data: {},
            error: "Something went wrong. Please try again.",
            type: "error",
          };
          promise.resolve(respo);
        } else {
          SET_STORAGE("settings", JSON.stringify(SITEDOWN_DATA));
          history.push("/error");
        }
      }
    },
  });
  rdata = promise.promise();
  return rdata;
}

export function AJAX_REQUEST_WITH_FILE(type = "GET", additional_url, data) {
  const c_user = JSON.parse(GET_STORAGE(USER));
  data.append("api_key", API_KEY);
  // if (c_user) {
  //     data.append("user_token", c_user.token);
  // }

  let promise = $.Deferred();
  if (c_user) {
    $.ajax({
      type: type,
      url: API_URL + additional_url,
      data: data,
      headers: {
        Authorization: `Bearer ${c_user.token}`,
      },
      dataType: "JSON",
      processData: false,
      contentType: false,
      timeout: 120000,
      success: function (resp) {
        if (resp) {
          if (typeof resp === "object") {
            if (resp.hasOwnProperty("type")) {
              promise.resolve(resp);
            } else {
              const respo = {
                code: 100,
                data: {},
                error: "Something went wrong. Please try again.",
                type: "error",
              };
              promise.resolve(respo);
            }
          } else {
            const respo = {
              code: 100,
              data: {},
              error: "Something went wrong. Please try again.",
              type: "error",
            };
            promise.resolve(respo);
          }
        } else {
          const respo = {
            code: 100,
            data: {},
            error: "Something went wrong. Please refresh the page again.",
            type: "error",
          };
          promise.resolve(respo);
        }
      },
      error: function (resp, textStatus) {
        if (textStatus === "timeout") {
          const respo = {
            code: 100,
            data: {},
            error: "Connection timeout. Please try again.",
            type: "error",
          };
          promise.resolve(respo);
        } else {
          if (resp.status === 500) {
            const respo = {
              code: 100,
              data: {},
              error: "Something went wrong. Please try again.",
              type: "error",
            };
            promise.resolve(respo);
          } else {
            SET_STORAGE("settings", JSON.stringify(SITEDOWN_DATA));
            history.push("/error");
          }
        }
      },
    });
  } else {
    $.ajax({
      type: type,
      url: API_URL + additional_url,
      data: data,
      dataType: "JSON",
      processData: false,
      contentType: false,
      timeout: 120000,
      success: function (resp) {
        if (resp) {
          if (typeof resp === "object") {
            if (resp.hasOwnProperty("type")) {
              promise.resolve(resp);
            } else {
              const respo = {
                code: 100,
                data: {},
                error: "Something went wrong. Please try again.",
                type: "error",
              };
              promise.resolve(respo);
            }
          } else {
            const respo = {
              code: 100,
              data: {},
              error: "Something went wrong. Please try again.",
              type: "error",
            };
            promise.resolve(respo);
          }
        } else {
          const respo = {
            code: 100,
            data: {},
            error: "Something went wrong. Please refresh the page again.",
            type: "error",
          };
          promise.resolve(respo);
        }
      },
      error: function (resp, textStatus) {
        if (textStatus === "timeout") {
          const respo = {
            code: 100,
            data: {},
            error: "Connection timeout. Please try again.",
            type: "error",
          };
          promise.resolve(respo);
        } else {
          if (resp.status === 500) {
            const respo = {
              code: 100,
              data: {},
              error: "Something went wrong. Please try again.",
              type: "error",
            };
            promise.resolve(respo);
          } else {
            SET_STORAGE("settings", JSON.stringify(SITEDOWN_DATA));
            history.push("/error");
          }
        }
      },
    });
  }

  return promise.promise();
  // return $.ajax({
  //     type: type,
  //     url: API_URL + additional_url,
  //     data: data,
  //     dataType: "JSON",
  //     processData: false,
  //     contentType: false
  // });
}

export function AJAX_SERVICE_LOGIN_REQUEST(type = "GET", additional_url, data) {
  let new_data = new FormData();
  new_data.append("api_key", API_KEY);

  let promise = $.Deferred();
  $.ajax({
    type: type,
    url: API_URL + additional_url,
    // data: data,
    data: new_data,
    headers: {
      Authorization: `Bearer ${data.user_token}`,
    },
    dataType: "JSON",
    processData: false,
    contentType: false,
    timeout: 60000,
    success: function (resp) {
      if (resp) {
        if (typeof resp === "object") {
          if (resp.hasOwnProperty("type")) {
            promise.resolve(resp);
          } else {
            const respo = {
              code: 100,
              data: {},
              error: "Something went wrong. Please try again.",
              type: "error",
            };
            promise.resolve(respo);
          }
        } else {
          const respo = {
            code: 100,
            data: {},
            error: "Something went wrong. Please try again.",
            type: "error",
          };
          promise.resolve(respo);
        }
      } else {
        const respo = {
          code: 100,
          data: {},
          error: "Something went wrong. Please refresh the page again.",
          type: "error",
        };
        promise.resolve(respo);
      }
    },
    error: function (resp, textStatus) {
      if (textStatus === "timeout") {
        const respo = {
          code: 100,
          data: {},
          error: "Connection timeout. Please try again.",
          type: "error",
        };
        promise.resolve(respo);
      } else {
        if (resp.status === 500) {
          const respo = {
            code: 100,
            data: {},
            error: "Something went wrong. Please try again.",
            type: "error",
          };
          promise.resolve(respo);
        } else {
          SET_STORAGE("settings", JSON.stringify(SITEDOWN_DATA));
          history.push("/error");
        }
      }
    },
  });

  return promise.promise();
}

export function AJAX_PUBLIC_REQUEST(type = "GET", additional_url, data) {
  data.api_key = API_KEY;

  let promise = $.Deferred();
  $.ajax({
    type: type,
    url: API_URL + additional_url,
    data: data,
    timeout: 60000,
    success: function (resp) {
      if (resp) {
        if (typeof resp === "object") {
          if (resp.hasOwnProperty("type")) {
            promise.resolve(resp);
          } else {
            const respo = {
              code: 100,
              data: {},
              error: "Something went wrong. Please try again.",
              type: "error",
            };
            promise.resolve(respo);
          }
        } else {
          const respo = {
            code: 100,
            data: {},
            error: "Something went wrong. Please try again.",
            type: "error",
          };
          promise.resolve(respo);
        }
      } else {
        const respo = {
          code: 100,
          data: {},
          error: "Something went wrong. Please refresh the page again.",
          type: "error",
        };
        promise.resolve(respo);
      }
    },
    error: function (resp, textStatus) {
      if (textStatus === "timeout") {
        const respo = {
          code: 100,
          data: {},
          error: "Connection timeout. Please try again.",
          type: "error",
        };
        promise.resolve(respo);
      } else {
        if (resp.status === 500) {
          const respo = {
            code: 100,
            data: {},
            error: "Something went wrong. Please try again.",
            type: "error",
          };
          promise.resolve(respo);
        } else {
          SET_STORAGE("settings", JSON.stringify(SITEDOWN_DATA));
          history.push("/error");
        }
      }
    },
  });
  return promise.promise();

  // return $.ajax({
  //     type: type,
  //     url: API_URL + additional_url,
  //     data: data
  // });
}

export const convertEnglishToBanglaNumber = (str) => {
  if (typeof str != "string") {
    str = str.toString();
  }
  let banglaNumber = {
    0: "০",
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯",
  };
  for (var x in banglaNumber) {
    str = str.replace(new RegExp(x, "g"), banglaNumber[x]);
  }
  return str;
};
export const convertBanglaToEnglishNumber = (str) => {
  if (typeof str != "string") {
    str = str.toString();
  }
  let banglaNumber = {
    0: "০",
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯",
  };
  for (var x in banglaNumber) {
    str = str.replace(new RegExp(banglaNumber[x], "g"), x);
  }
  return str;
};

export function is_Mobile() {
  if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
    return "mac";
  } else {
    return "window";
  }
}
