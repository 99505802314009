import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class Dua extends PureComponent {

    scrollbars = React.createRef();
    scrollbars1 = React.createRef();
    scrollbars2 = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            category_loading: true,
            dualist_loading: true,
            dua_loading: true,
            categoryList: [],
            duaList: [],
            activeCategoryData: '',
            activeDuaListData: '',
        }
        document.title = "Dua - Al Quran Technobd";
    }


    componentDidMount() {
        this.getDoyaCategory();
    }

    getDoyaCategory = () => {
        this.setState({ category_loading: true })
        AJAX_PUBLIC_REQUEST("POST", "api/getDoyaCategory", {}).then(results => {
            if (results.type === "success") {
                const category = results.data;
                this.setState({
                    categoryList: category,
                    category_loading: false,
                });
                if (category.length > 0) {
                    const first_category = category[0];
                    if (first_category) {
                        this.getDoyaList(first_category.id, first_category);
                    }
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    category_loading: false,
                })
            }
        });
    }

    getDoyaList = (category_id, categoryData) => {
        this.setState({ dualist_loading: true, dua_loading: true, activeDuaListData: '', activeCategoryData: categoryData })
        AJAX_PUBLIC_REQUEST("POST", "api/getDoyaList", { category_id: category_id }).then(results => {
            if (results.type === "success") {
                const dua = results.data;
                this.setState({
                    duaList: dua,
                    dualist_loading: false,
                });
                if (dua.length > 0) {
                    const first_dua = dua[0];
                    if (first_dua) {
                        this.setState({ activeDuaListData: first_dua, dua_loading: false })
                    }
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    dualist_loading: false,
                    dua_loading: false
                })
            }
        });
    }

    updateActiveDua = (id) => {
        this.setState({ dua_loading: true, activeDuaListData: '' })
        const activeDua = this.state.duaList.filter((duaListLS, index) => {
            return duaListLS.id == id;
        })
        if (activeDua && (activeDua.length > 0)) {
            this.setState({ dua_loading: false, activeDuaListData: activeDua[0] })
        }
    }


    render() {

        return (
            <Fragment>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 plr-2-5">
                        <div className="para-title">ক্যাটাগরি</div>
                        {
                            this.state.category_loading ?
                                <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                :
                                <div className="nano nano-dua">
                                    <div className="nano-content">
                                        <div className="para-item">
                                            <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                <ul>
                                                    {
                                                        this.state.categoryList.length > 0 ?
                                                            <Fragment>
                                                                {
                                                                    this.state.categoryList.map((categoryList_single, index) => {
                                                                        const active_cat_id = this.state.activeCategoryData.id ? this.state.activeCategoryData.id : '';
                                                                        return (
                                                                            <li key={`category_id_${categoryList_single.id}`} onClick={() => this.getDoyaList(categoryList_single.id, categoryList_single)} className={classnames(null, { 'sellect': parseInt(categoryList_single.id) === parseInt(active_cat_id) })}>
                                                                                <div className="para-number">
                                                                                    {categoryList_single.sl_bn}
                                                                                </div>
                                                                                <div className="para-number-no">
                                                                                    {categoryList_single.name}
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </Fragment>
                                                            : ''
                                                    }
                                                </ul>
                                            </Scrollbars>
                                        </div>

                                    </div>
                                </div>
                        }

                    </div>

                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 plr-2-5">
                        <div className="para-title">দোয়া সমুহ</div>
                        {
                            this.state.dualist_loading ?
                                <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                :
                                <div className="nano nano-dua">
                                    <div className="nano-content">
                                        <div className="para-item">
                                            <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars1" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars1}>
                                                <ul>
                                                    {
                                                        this.state.duaList.length > 0 ?
                                                            <Fragment>
                                                                {
                                                                    this.state.duaList.map((duaList_single, index) => {
                                                                        const active_dua_id = this.state.activeDuaListData.id ? this.state.activeDuaListData.id : '';
                                                                        return (
                                                                            <li key={`dua_id_${duaList_single.id}`} onClick={() => this.updateActiveDua(duaList_single.id)} className={classnames(null, { 'sellect': parseInt(duaList_single.id) === parseInt(active_dua_id) })}>
                                                                                <div className="para-number">
                                                                                    {duaList_single.sl_bn}
                                                                                </div>
                                                                                <div className="para-number-no">
                                                                                    {duaList_single.name}
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </Fragment>
                                                            : ''
                                                    }
                                                </ul>
                                            </Scrollbars>
                                        </div>

                                    </div>
                                </div>
                        }
                    </div>

                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 plr-2-5">
                        {
                            this.state.dua_loading && (this.state.activeDuaListData == '') ?
                                <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                :
                                <Fragment>
                                    <div className="para-title">{this.state.activeDuaListData.name}</div>
                                    <div className="nano nano-dua">
                                        <div className="nano-content">
                                            <div className="para-item ayata-item">
                                                <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars2" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars2}>
                                                    <ul>
                                                        <li className="sellect">
                                                            <div className="ayata-item-box">
                                                                <div className="ayata-title" dir="rtl" lang="ar">{this.state.activeDuaListData.arabic}</div>
                                                                <div className="ayata-item-start">
                                                                    <div className="hadiths-details">
                                                                        <div className="hadiths-text mb-20">
                                                                            <strong>বাংলা উচ্চারণ</strong>
                                                                            {this.state.activeDuaListData.bangla}
                                                                        </div>

                                                                        <div className="hadiths-text">
                                                                            <strong>বাংলা অর্থ</strong>
                                                                            {this.state.activeDuaListData.bangla_meaning}

                                                                        </div>
                                                                    </div>
                                                                    <ul>
                                                                        <li>
                                                                            {/* <span>{this.state.activeDuaListData.sl_bn} ।</span> */}
                                                                            {/* {this.state.activeDuaListData.bangla} */}
                                                                        </li>
                                                                        <li className="bangla">
                                                                            {/* <span>{this.state.activeDuaListData.sl_bn} ।</span> */}
                                                                            {/* {this.state.activeDuaListData.bangla_meaning} */}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </Scrollbars>
                                            </div>


                                        </div>
                                    </div>
                                </Fragment>
                        }
                    </div>

                    <div>
                    </div>

                </div>


            </Fragment>
        );
    }
}

export default withRouter(Dua);