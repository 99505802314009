import validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateLogin(data) {
  let errors = {};

  if (validator.isEmpty(data.user_email)) {
    errors.user_email = 'This field is required';
  }
  if (!validator.isEmail(data.user_email)) {
    errors.user_email = 'Email is invalid';
  }
  if (validator.isEmpty(data.user_password)) {
    errors.user_password = 'This field is required';
  }

  return {
    errors,
    isValid: isEmpty(errors),
    isFormValid: isEmpty(errors)
  }
}