import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';
import { NavLink } from 'react-router-dom'

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class Disclaimer extends PureComponent {

  scrollbars = React.createRef();

  render() {
    document.title = "Disclaimer - Al Quran Technobd";
    return (
      <Fragment>
        <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
          <div className="col-sm-12 col-md-12">
            <div className="download-section new-hadith-search">
              <div className="hadith-name-list">
                <ul>
                  <li>হাদিস সমুহ</li>
                  <li><i class="icofont-simple-right"></i></li>
                  <li>বুখারী শরীফ</li>
                </ul>
              </div>
              <div className="download-section-inner max-width-100">
                <div className="new-hadith-search-form">
                  <Bootstrap.Form>
                    <Bootstrap.Form.Control type="text" placeholder="হাদিসে সার্চ করুন" />
                    <Bootstrap.Button variant="secondary" className="search-custom-btn-new">
                      <i class="fas fa-search"></i>
                    </Bootstrap.Button>
                  </Bootstrap.Form>
                </div>
                <div className="nano">
                  <div className="nano-content">
                    <div className="para-item download-item">
                      <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                        <div className="new-hadith-search-content new-bukhari-hadiths">
                          <ul>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>ওয়াহ্‌য়ীর সূচনা (كتاب بدء الوحى)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>ঈমান (বিশ্বাস) (كتاب الإيمان)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>আল-ইলম (ধর্মীয় জ্ঞান) (كتاب العلم)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>উযূ (كتاب الوضوء)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>মাক্কাহ ও মাদীনাহর মসজিদে সালাতের মর্যাদা (كتاب فضلالصلاة فى مسجد مكة والمدينة) </strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong> কুরআন তিলাওয়াতের সিজদা্ (كتاب سجود القرآن)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>সালাতের সময়সমূহ (كتاب واقيت الصل)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>ওয়াহ্‌য়ীর সূচনা (كتاب بدء الوحى)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>ঈমান (বিশ্বাস) (كتاب الإيمان)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>আল-ইলম (ধর্মীয় জ্ঞান) (كتاب العلم)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>উযূ (كتاب الوضوء)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>মাক্কাহ ও মাদীনাহর মসজিদে সালাতের মর্যাদা (كتاب فضلالصلاة فى مسجد مكة والمدينة) </strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong> কুরআন তিলাওয়াতের সিজদা্ (كتاب سجود القرآن)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>সালাতের সময়সমূহ (كتاب واقيت الصل)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>ওয়াহ্‌য়ীর সূচনা (كتاب بدء الوحى)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>ঈমান (বিশ্বাস) (كتاب الإيمان)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>আল-ইলম (ধর্মীয় জ্ঞান) (كتاب العلم)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>উযূ (كتاب الوضوء)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>মাক্কাহ ও মাদীনাহর মসজিদে সালাতের মর্যাদা (كتاب فضلالصلاة فى مسجد مكة والمدينة) </strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong> কুরআন তিলাওয়াতের সিজদা্ (كتاب سجود القرآن)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/new-hadith-search-list-details">
                                <div>
                                  <strong>সালাতের সময়সমূহ (كتاب واقيت الصل)</strong>
                                  <span>হাদিস সংখ্যা ৭ টি | নম্বর ১-৭ পর্যন্ত</span>
                                </div>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </Scrollbars>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div>
            </div>

          </div>
        </div>

      </Fragment>
    );
  }
}

export default withRouter(Disclaimer);