import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { AJAX_PUBLIC_REQUEST } from "../../Constants/AppConstants";
import { API_KEY, API_URL,REMOVE_STORAGE,DELETE_LOGIN_COOKIE,USER } from "../../Constants/AppConstants";
import { logout } from '../../Store/actions/loginActions';
import { toast } from "react-toastify";
import history from '../../history';
class AccountDeletionConfirm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      user_info: false,
      Message: "",
      errorMessage: "",
      isLoading: false,
    };
  }
  logout = () => {
      REMOVE_STORAGE(USER);
      DELETE_LOGIN_COOKIE();
  }
  getTokenData = async () => {
    const { token } = this.props.match.params;
    const formData = new FormData(); // Create FormData object
    formData.append("code", token); // Append form fields to FormData object
    formData.append("api_key", API_KEY); // Append form fields to FormData object
    try {
      // Make your API call here
      const response = await fetch(API_URL + "api/accountDeletionVerify", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }
      const responseData = await response.json();
      console.log(response);
      console.log(responseData);
      if (responseData.code == "200") {
        this.setState({
          submitted: true,
          user_info: responseData.data,

          errorMessage: "",
        });
      } else {
        let error_message = "";
        if (typeof responseData.message != "undefined") {
          error_message = responseData.message;
        } else {
          error_message = responseData.message;
        }
        this.setState({ submitted: false, errorMessage: error_message });
      }
      // If API call is successful, update state to show success message
    } catch (error) {
      // If there's an error, update state to show error message
      this.setState({ submitted: false, errorMessage: error.message });
    }
  };
  componentDidMount() {
    // Check if token is defined and has a value
    const { token } = this.props.match.params;
    if (token) {
      // Store token in local storage
      localStorage.setItem("delete_account_token", token);
      this.setState({ token: token });
      this.getTokenData();
    }
  }
  // Event handler for input change
  handleInputChange = (e) => {
    this.setState({ email: e.target.value });
  };

  // Event handler for form submission
  handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const { email } = this.state;
    const formData = new FormData(); // Create FormData object
    formData.append("user_email", this.state.email); // Append form fields to FormData object
    formData.append("api_key", API_KEY); // Append form fields to FormData object
    try {
      // Make your API call here
      const response = await fetch(API_URL + "api/accountDeletionRequest", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }
      const responseData = await response.json();
      console.log(response);
      console.log(responseData);
      if (responseData.code == "200") {
        this.setState({
          submitted: true,
          Message: responseData.message,
          errorMessage: "",
        });
      } else {
        this.setState({ submitted: false, errorMessage: responseData.message });
      }
      // If API call is successful, update state to show success message
    } catch (error) {
      // If there's an error, update state to show error message
      this.setState({ submitted: false, errorMessage: error.message });
    }
  };

  onSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const { email } = this.state;
    const { token } = this.props.match.params;
    const formData = new FormData(); // Create FormData object
    //formData.append("user_email", this.state.email); // Append form fields to FormData object
    formData.append("code", token); // Append form fields to FormData object
    formData.append("type", "code"); // Append form fields to FormData object
    formData.append("api_key", API_KEY); // Append form fields to FormData object

    try {
      this.setState({ isLoading: true });
      // Make your API call here
      const response = await fetch(API_URL + "api/accountDeletionConfirm", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }
      const responseData = await response.json();
      if (responseData.code == 200) {
        toast.success(responseData.message);
        this.logout();
        history.push("/login");
      } else {
        this.setState({ submitted: false, errorMessage: responseData.message });
      }
      // If API call is successful, update state to show success message
    } catch (error) {
      // If there's an error, update state to show error message
      this.setState({ submitted: false, errorMessage: error.message });
    }
  };

  render() {
    const { submitted, Message, errorMessage } = this.state;
    console.log(this.props.match.params.token);
    // If the form has been submitted successfully, show success message
    if (submitted) {
      return (
        <Fragment>
          <div
            className="row wow mlr-2-5 fadeInUp"
            data-wow-duration="500ms"
            data-wow-delay="900ms"
          >
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <main className="auth-section">
                <div className="para-title text-center">
                  Account Deletion Confirmation
                </div>
                {this.state.Message ? (
                  <p>{this.state.Message}</p>
                ) : (
                  <div className="auth-section-inner">
                    <form className="action_form" onSubmit={this.onSubmit}>
                      <div className="auth-profile">
                        <ul>
                          <li>
                            <label>Name</label>
                            {this.state.user_info.name}
                          </li>
                          <li>
                            <label>আমার ইমেইল</label>
                            {this.state.user_info.email}
                          </li>
                        </ul>
                      </div>
                      <div className="form-group mt-5 text-center">
                        <button
                          style={{
                            backgroundColor: "red",
                            border: "1px solid red",
                            width: "180px",
                          }}
                          type="submit"
                          disabled={this.state.isLoading}
                          className="auth-button"
                          value="confirm"
                        >
                          {this.state.isLoading
                            ? "Please wait..."
                            : "Delete Account"}
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                <div className="clearfix"></div>
              </main>
            </div>
          </div>
        </Fragment>
      );
    }

    // If there's an error, show error message
    let additionalContent = null;
    console.log(errorMessage);
    if (errorMessage) {
      additionalContent = (
        <div className="account-deletion-error">
          <p>{errorMessage}</p>
        </div>
      );
    }

    // Default render: show the form
    return (
      <Fragment>
        <div className="account-deletion-page">
          <p>Loading....</p>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AccountDeletionConfirm);
