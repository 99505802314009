import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class Disclaimer extends PureComponent {

  scrollbars = React.createRef();

  render() {
    document.title = "Disclaimer - Al Quran Technobd";
    return (
      <Fragment>
        <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
          <div className="col-sm-12 col-md-12">
            <div className="download-section new-hadith-search-list">
              <div className="hadith-name-list">
                <ul>
                  <li>হাদিসে সার্চ</li>
                  <li><i class="icofont-simple-right"></i></li>
                  <li>সালাত</li>
                </ul>
              </div>
              <div className="download-section-inner max-width-830">
                <div className="new-hadith-search-form">
                  <Bootstrap.Form>
                    <Bootstrap.Form.Control type="text" placeholder="হাদিসে সার্চ করুন" />
                    <Bootstrap.Button variant="secondary" className="search-custom-btn-new">
                      <i class="fas fa-search"></i>
                    </Bootstrap.Button>
                  </Bootstrap.Form>
                </div>
                <div className="nano">
                  <div className="nano-content">
                    <div className="para-item download-item">
                      <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                        <div className="new-hadith-search-list-inner">
                          <ul>
                            <li>
                              <div className="hadith-search-list-inner-block">
                                <h4>পরিচ্ছেদঃ <NavLink to='/new-hadith-search-list-details'>সালাতের ওয়াক্ত।</NavLink> </h4>
                                <h5>হাদিস - <NavLink to='/new-hadith-search-list-details'>সহিহ মুসলিম</NavLink> - <NavLink to='/new-hadith-search-list-details'>নামায</NavLink></h5>
                                <p> <NavLink to='/new-hadith-search-list-details'>হান্নাদ ইবনুুস সারী (রহঃ) .... ইবনু আব্বাস রাদিয়াল্লাহু আনহু থেকে বর্ণনা করেন যে, নবী করীম সাল্লাল্লাহু আলাইহি ওয়াসাল্লাম বলেন জিবরীল (আঃ) বায়তুল্লাহর
                                কাছে দুইদিন আমার ইমামমত করেছেন। এর প্রথম দিন তিনি যুহরের সালাত আদায় করেছেন যখন একটি বস্তুর ছায়া জুতার ফিতার মত সামান্য লম্বা হয় আসরের সালাত
                                আদায় করেছেন যখন একটি বস্তুর ছাড়া তাঁর সমান হয়; মাগরিবের সালাত আদায় করেছেন যখন সূর্য ডুবে যায় এবং রোযাদার ইফতাঁর করে ইশার সালাত...</NavLink>
                                </p>
                                <span className="hadith-search-list-number"> হাদিস সংখ্যা - ১২৩৪৫ </span>
                              </div>
                            </li>
                            <li>
                              <div className="hadith-search-list-inner-block">
                                <h4>পরিচ্ছেদঃ <NavLink to='/new-hadith-search-list-details'>সালাতের ওয়াক্ত।</NavLink> </h4>
                                <h5>হাদিস - <NavLink to='/new-hadith-search-list-details'>সহিহ মুসলিম</NavLink> - <NavLink to='/new-hadith-search-list-details'>নামায</NavLink></h5>
                                <p> <NavLink to='/new-hadith-search-list-details'>হান্নাদ ইবনুুস সারী (রহঃ) .... ইবনু আব্বাস রাদিয়াল্লাহু আনহু থেকে বর্ণনা করেন যে, নবী করীম সাল্লাল্লাহু আলাইহি ওয়াসাল্লাম বলেন জিবরীল (আঃ) বায়তুল্লাহর
                                কাছে দুইদিন আমার ইমামমত করেছেন। এর প্রথম দিন তিনি যুহরের সালাত আদায় করেছেন যখন একটি বস্তুর ছায়া জুতার ফিতার মত সামান্য লম্বা হয় আসরের সালাত
                                আদায় করেছেন যখন একটি বস্তুর ছাড়া তাঁর সমান হয়; মাগরিবের সালাত আদায় করেছেন যখন সূর্য ডুবে যায় এবং রোযাদার ইফতাঁর করে ইশার সালাত...</NavLink>
                                </p>
                                <span className="hadith-search-list-number"> হাদিস সংখ্যা - ১২৩৪৫ </span>
                              </div>
                            </li>
                            <li>
                              <div className="hadith-search-list-inner-block">
                                <h4>পরিচ্ছেদঃ <NavLink to='/new-hadith-search-list-details'>সালাতের ওয়াক্ত।</NavLink> </h4>
                                <h5>হাদিস - <NavLink to='/new-hadith-search-list-details'>সহিহ মুসলিম</NavLink> - <NavLink to='/new-hadith-search-list-details'>নামায</NavLink></h5>
                                <p> <NavLink to='/new-hadith-search-list-details'>হান্নাদ ইবনুুস সারী (রহঃ) .... ইবনু আব্বাস রাদিয়াল্লাহু আনহু থেকে বর্ণনা করেন যে, নবী করীম সাল্লাল্লাহু আলাইহি ওয়াসাল্লাম বলেন জিবরীল (আঃ) বায়তুল্লাহর
                                কাছে দুইদিন আমার ইমামমত করেছেন। এর প্রথম দিন তিনি যুহরের সালাত আদায় করেছেন যখন একটি বস্তুর ছায়া জুতার ফিতার মত সামান্য লম্বা হয় আসরের সালাত
                                আদায় করেছেন যখন একটি বস্তুর ছাড়া তাঁর সমান হয়; মাগরিবের সালাত আদায় করেছেন যখন সূর্য ডুবে যায় এবং রোযাদার ইফতাঁর করে ইশার সালাত...</NavLink>
                                </p>
                                <span className="hadith-search-list-number"> হাদিস সংখ্যা - ১২৩৪৫ </span>
                              </div>
                            </li>

                          </ul>




                        </div>
                      </Scrollbars>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div>
            </div>

          </div>
        </div>

      </Fragment>
    );
  }
}

export default withRouter(Disclaimer);