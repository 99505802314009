import React, { Fragment, Component } from "react";
import { AJAX_PUBLIC_REQUEST, SET_STORAGE, GET_STORAGE, APP_VERSION, is_Mobile } from "./Constants/AppConstants";
import { isMobile, isAndroid, isWinPhone, isIOS } from 'react-device-detect';
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import './Assets/css/fontawesome-pro.css';
import './Assets/fonts/fonts.css';
//template css
import "./Assets/css/animate.css";
import "./Assets/css/icofont.css";
import "./Assets/css/meanmenu.css";
import "./Assets/css/metisMenu.css";
import "./Assets/css/nanoscroller.css";
import "./Assets/css/preset.css";
import "./Assets/css/responsive.css";
import "./Assets/css/styles.css";

// import metisMenu from './Assets/js/metisMenu.min.js';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import Sharect from 'sharect';

import Downtime from "./Components/Pages/Downtime";
import Sidebar from "./Components/Layouts/Sidebar";
import Header from "./Components/Layouts/Header";
// import Footer from "./Components/Layouts/Footer";
import MobileView from './Components/Alquran/MobileView';
import AlquranRoutes from "./Routes/AlquranRoutes";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            downtime: false,
            downtime_msg:
                "<h3>Down for maintenance</h3><p>Site is temporarily unavailable due to planned maintenance.</p>"
        };
        console.log('App Version: ' + APP_VERSION);
    }

    componentDidMount() {
        //set setting data
        /*AJAX_PUBLIC_REQUEST("POST", "user/getSettings", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let response = results.response.data;

                SET_STORAGE("settings", JSON.stringify(response));
                if (parseInt(results.response.data.downtime_status) === 1) {
                    if (GET_STORAGE("settings")) {
                        const settings_data = JSON.parse(GET_STORAGE("settings"));
                        if (settings_data.downtime_access_validity) {
                            if (settings_data.downtime_access_validity === "valid") {
                                this.setState({
                                    loading: false,
                                    downtime_msg: results.response.data.downtime_message,
                                    downtime: false
                                });
                            } else {
                                this.setState({
                                    loading: false,
                                    downtime_msg: results.response.data.downtime_message,
                                    downtime: true
                                });
                            }
                        } else {
                            this.setState({
                                loading: false,
                                downtime_msg: results.response.data.downtime_message,
                                downtime: true
                            });
                        }
                    } else {
                        this.setState({
                            loading: false,
                            downtime_msg: results.response.data.downtime_message,
                            downtime: true
                        });
                    }
                } else {
                    if (GET_STORAGE("settings")) {
                        let set_settings = JSON.parse(GET_STORAGE("settings"));
                        set_settings.downtime_access_validity = "valid";
                        set_settings.server_down = 0;
                        set_settings.downtime_status = 0;
                        SET_STORAGE("settings", JSON.stringify(set_settings));
                    }
                    this.setState({ loading: false });
                }
            } else if (parseInt(results.response.code) === 4444) {
                this.setState({ loading: false, downtime: true });
            } else {
                this.setState({ loading: false });
            }
        });*/
        this.setState({ loading: false });

        Sharect.config({
            facebook: true,
            twitter: true,
            backgroundColor: '#2a4722',
            // iconColor: '#fff',
        }).init();
    }

    disableDowntime = () => {
        this.setState({ downtime: false })
    }

    render() {
        // if (isAndroid) {
        //     alert('i am android')
        // }
        // if (isIOS) {
        //     alert('i am iOS')
        // }
        const cur_url = window.location.href;
        if (isMobile && !cur_url.includes("contact-us")) {
            return (<MobileView />);
        }
        // else if (isIOS) {
        //     return (<MobileView />);
        // } 
        else {
            return (
                <Fragment>
                    <NotificationContainer />
                    {this.state.downtime ? (
                        <Downtime downtime_msg={this.state.downtime_msg} disableDowntime={this.disableDowntime} />
                    ) : (
                            <Fragment>
                                {this.state.loading ? (
                                    <div className="home-loading" />
                                ) : (
                                        <Fragment>
                                            <section className="main-container">
                                                {
                                                    isMobile ?
                                                        ''
                                                        :
                                                        <Sidebar />
                                                }
                                                <section className="main-content" style={{ left: isMobile ? '0px' : '' }}>
                                                    <Header />
                                                    <ToastContainer />
                                                    <div className="wrapper">
                                                        <AlquranRoutes />
                                                    </div>
                                                </section>
                                            </section>
                                        </Fragment>
                                    )}
                            </Fragment>
                        )}
                </Fragment>
            );
        }

    }
}

export default App;
