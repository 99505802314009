import React, { Fragment, PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class MobileView extends PureComponent {
    render() {
        document.title = "Download Mobile App - Al Quran Technobd";
        return (
            <Fragment>
                <div className="mobile-section">
                    <div className="top-section">
                        <div className="mobile-logo">
                            <img src={require('../../Assets/images/mobile/logo.png')} alt="" title="" />
                        </div>
                        <div className="mobile-thumb">
                            <img src={require('../../Assets/images/mobile/mobile.png')} alt="" title="" />
                        </div>
                    </div>
                    {/* End Top Section */}

                    <div className="bottom-section">
                        <div className="bottom-logo">
                            <img src={require('../../Assets/images/mobile/logo2.png')} alt="" title="" />
                        </div>
                        <div className="bottom-text">
                            <p>সম্পুর্ন বিজ্ঞাপন মুক্ত <br /> আল কোরআন অ্যাপ</p>
                        </div>
                        <div className="app-link">
                            <a href='https://apps.apple.com/gb/app/al-quran-hadit-in-bangla/id1491875487' target="_blank">
                                <img src={require('../../Assets/images/mobile/apple.png')} alt="" title="" />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.technobd.alquran' target="_blank">
                                <img src={require('../../Assets/images/mobile/google.png')} alt="" title="" />
                            </a>

                        </div>

                    </div>
                    {/* End Top Section */}
                </div>

            </Fragment>
        );
    }
}

export default withRouter(MobileView);
