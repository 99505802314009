import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST, convertEnglishToBanglaNumber, MATCH_REPLACE } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import history from '../../history';

class AlquranSearch extends PureComponent {

    scrollbars = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            query: '',

            sura_name: '',
            para_name: '',
            ayat_no: '',
            searchData: [],
            total: 0,
            total_ayat: 0,
            total_sura: 0,
            error: '',
            showAr: true,
            showBn: true,
            showBnU: false,

            offset: 1,
            per_page: 20,
            total_count: 0,
            pagination_loading: false,
        }
        document.title = "Search Al Quran - Al Quran Technobd";
    }


    componentDidMount() {
        const url = new URL(window.location.href)
        let params = new URLSearchParams(url.search)
        if (params) {
            if (params.has('query')) {
                this.setState({ query: params.get('query') })
                this.alQuranSearchV2(params.get('query'), 1);
            }
            if (params.has('sura_name') && params.has('ayat_no')) {
                this.setState({ sura_name: params.get('sura_name'), ayat_no: params.get('ayat_no') })
                this.advancedSearchV2(false, params.get('sura_name'), params.get('ayat_no'), 1);
            } else if (params.has('sura_name')) {
                this.setState({ sura_name: params.get('sura_name') })
                this.advancedSearchV2(false, params.get('sura_name'), false, 1);
            } else if (params.has('para_name') && params.has('ayat_no')) {
                this.setState({ para_name: params.get('para_name'), ayat_no: params.get('ayat_no') })
                this.advancedSearchV2(params.get('para_name'), false, params.get('ayat_no'), 1);
            } else if (params.has('para_name')) {
                this.setState({ para_name: params.get('para_name') })
                this.advancedSearchV2(params.get('para_name'), false, false, 1);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props != prevProps) {
            const url = new URL(window.location.href)
            let params = new URLSearchParams(url.search)
            if (params) {
                if (params.has('query')) {
                    this.setState({ query: params.get('query') })
                    this.alQuranSearchV2(params.get('query'), 1);
                }
            }
            if (params.has('sura_name') && params.has('ayat_no')) {
                this.setState({ sura_name: params.get('sura_name'), ayat_no: params.get('ayat_no') })
                this.advancedSearchV2(false, params.get('sura_name'), params.get('ayat_no'), 1);
            } else if (params.has('sura_name')) {
                this.setState({ sura_name: params.get('sura_name') })
                this.advancedSearchV2(false, params.get('sura_name'), false, 1);
            } else if (params.has('para_name') && params.has('ayat_no')) {
                this.setState({ para_name: params.get('para_name'), ayat_no: params.get('ayat_no') })
                this.advancedSearchV2(params.get('para_name'), false, params.get('ayat_no'), 1);
            } else if (params.has('para_name')) {
                this.setState({ para_name: params.get('para_name') })
                this.advancedSearchV2(params.get('para_name'), false, false, 1);
            }
        }
    }

    searchHandler = (e) => {
        e.preventDefault();
        const query = document.getElementById('query').value;
        if (query && (query.length > 0)) {
            history.push('/alquran-search?query=' + query);
        }
    }

    changeHandlerSearch = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    alQuranSearchV2 = (query, offset = 1) => {
        this.setState({ loading: true });
        AJAX_PUBLIC_REQUEST("POST", "api/alQuranSearchV2", { query: query, offset: offset }).then(results => {
            if (results.type === "success") {
                const searchData = results.data;
                this.setState({
                    searchData: searchData,
                    total: results.total,
                    total_ayat: results.total_ayat,
                    total_count: results.total_count,
                    total_sura: results.total_sura,
                    loading: false,
                    offset: this.state.offset + this.state.per_page,
                })
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    searchData: [],
                    total: 0,
                    total_ayat: 0,
                    total_count: 0,
                    total_sura: 0,
                    loading: false,
                })
            }
        });
    }

    advancedSearchV2 = (para_name = false, sura_name = false, ayat_no = false, offset = 1) => {
        this.setState({ loading: true });
        let data = { offset: offset };
        if (para_name && ayat_no) {
            data.para_name = para_name;
            data.ayat_no = ayat_no;
        } else if (para_name) {
            data.para_name = para_name;
        } else if (sura_name && ayat_no) {
            data.sura_name = sura_name;
            data.ayat_no = ayat_no;
        } else if (sura_name) {
            data.sura_name = sura_name;
        }
        AJAX_PUBLIC_REQUEST("POST", "api/advancedSearchV2", data).then(results => {
            if (results.type === "success") {
                const searchData = results.data;
                this.setState({
                    searchData: searchData,
                    total: results.total,
                    total_ayat: results.total_ayat,
                    total_count: results.total_count,
                    total_sura: results.total_sura,
                    loading: false,
                    offset: this.state.offset + this.state.per_page,
                })
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    searchData: [],
                    total: 0,
                    total_ayat: 0,
                    total_count: 0,
                    total_sura: 0,
                    loading: false,
                })
            }
        });
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.checked ? true : false })
    }

    handleScrollStop = async () => {
        if (this.scrollbars.current) {
            // console.log('viewScrollTop', this.scrollbars.current.viewScrollTop)
            // console.log('clientHeight', this.scrollbars.current.view.clientHeight)
            // console.log('offsetHeight', document.getElementById('Cusscrollbars-ul').offsetHeight)
            if ((this.scrollbars.current.viewScrollTop + this.scrollbars.current.view.clientHeight) === document.getElementById('Cusscrollbars-ul').offsetHeight) {
                if (!this.state.pagination_loading) {
                    if (this.state.total_count > this.state.offset) {
                        this.setState({ pagination_loading: true });
                        setTimeout(() => {
                            document.getElementById('scroll_loading').scrollIntoView()
                        }, 10)
                        const url = new URL(window.location.href)
                        let params = new URLSearchParams(url.search)
                        if (params && params.has('query')) {
                            let data = {
                                offset: this.state.offset,
                                query: this.state.query
                            }
                            AJAX_PUBLIC_REQUEST("POST", "api/alQuranSearchV2", data).then(async results => {
                                if (results.type === "success") {

                                    const added_search_result = results.data;
                                    const old_search_result = this.state.searchData;
                                    const new_search_result = old_search_result.concat(added_search_result);

                                    this.setState({
                                        searchData: new_search_result,
                                        offset: this.state.offset + this.state.per_page,
                                        pagination_loading: false,
                                    });
                                } else {
                                    this.setState({
                                        error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                                        pagination_loading: false,
                                    })
                                }
                            });
                        } else {


                            let data = { offset: this.state.offset };
                            if ((this.state.para_name != '') && (this.state.ayat_no != '')) {
                                data.para_name = this.state.para_name;
                                data.ayat_no = this.state.ayat_no;
                            } else if (this.state.para_name != '') {
                                data.para_name = this.state.para_name;
                            } else if ((this.state.sura_name != '') && (this.state.ayat_no != '')) {
                                data.sura_name = this.state.sura_name;
                                data.ayat_no = this.state.ayat_no;
                            } else if (this.state.sura_name != '') {
                                data.sura_name = this.state.sura_name;
                            }
                            AJAX_PUBLIC_REQUEST("POST", "api/advancedSearchV2", data).then(results => {
                                if (results.type === "success") {
                                    const added_search_result = results.data;
                                    const old_search_result = this.state.searchData;
                                    const new_search_result = old_search_result.concat(added_search_result);

                                    this.setState({
                                        searchData: new_search_result,
                                        offset: this.state.offset + this.state.per_page,
                                        pagination_loading: false,
                                    });
                                } else {
                                    this.setState({
                                        error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                                        pagination_loading: false,
                                    })
                                }
                            });
                        }

                    }
                    console.log('pagination_loading', this.state.pagination_loading);
                }
            }
        }
    }

    goToSuraAyat = (search_data) => {
        history.push(`/?sura_no=${search_data.sura_no}&sura_ayat_no=${search_data.sura_ayat_no}`);
    }

    render() {
        let has_query = false;
        let is_alquran_search = true;
        const url = new URL(window.location.href)
        let params = new URLSearchParams(url.search)
        if (params && params.has('query')) {
            has_query = true;
            is_alquran_search = false;
        }
        if (params && (params.has('sura_name') || params.has('para_name'))) {
            is_alquran_search = false;
        }
        return (
            <Fragment>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-md-1"></div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 plr-2-5">
                        <div className="namaz-timing-sellect">
                            <div className="para-title">{has_query || is_alquran_search ? 'আল কুরআন র্সাচ' : 'এডভান্সড র্সাচ'}</div>
                        </div>
                        {
                            has_query || is_alquran_search ?
                                <div className="custom-search-input">
                                    <Bootstrap.Form>
                                        <Bootstrap.Form.Control type="text" name="query" id="query" onChange={this.changeHandlerSearch} value={this.state.query} placeholder="সার্চ করুন" />
                                        <Bootstrap.Button variant="secondary" className="search-custom-btn" onClick={this.searchHandler} ></Bootstrap.Button>
                                    </Bootstrap.Form>
                                </div>
                                : ''
                        }

                        {
                            is_alquran_search ?
                                ''
                                :
                                <Fragment>
                                    {
                                        this.state.loading ?
                                            <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                            :
                                            <div className="namaz-timing">
                                                <div className="all-result-heading">
                                                    মোট ফলাফল {convertEnglishToBanglaNumber(this.state.total_count)}টি। সূরাঃ {convertEnglishToBanglaNumber(this.state.total_sura)}টি আয়াতঃ {convertEnglishToBanglaNumber(this.state.total_ayat)}টি

                                        {/* <div className="sellect">
                                            <div className="form-check-result">
                                                <label class="check-result"> <small> আরবি </small>
                                                    <input type="checkbox" name="showAr" onChange={this.changeHandler} defaultChecked={this.state.showAr} />
                                                    <span class="checkmark"></span>
                                                </label>

                                                <label class="check-result"> <small> বাংলা অর্থ </small>
                                                    <input type="checkbox" name="showBn" onChange={this.changeHandler} defaultChecked={this.state.showBn} />
                                                    <span class="checkmark"></span>
                                                </label>

                                                <label class="check-result"> <small> বাংলা উচ্চারন </small>
                                                    <input type="checkbox" name="showBnU" onChange={this.changeHandler} defaultChecked={this.state.showBnU} />
                                                    <span class="checkmark"></span>
                                                </label>

                                            </div>
                                        </div> */}
                                                </div>
                                                {
                                                    this.state.searchData.length > 0 ?
                                                        <div className="nano  h-90">
                                                            <div className="nano-content">
                                                                <div className="para-item">
                                                                    <Scrollbars onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                                        <ul id="Cusscrollbars-ul">

                                                                            {/* <li className="sellect">
                                                                <div className="form-check-result">
                                                                    <label class="check-result"> <small> আরবি </small>
                                                                        <input type="checkbox" checked="checked" />
                                                                        <span class="checkmark"></span>
                                                                    </label>

                                                                    <label class="check-result"> <small> বাংলা অর্থ </small>
                                                                        <input type="checkbox" />
                                                                        <span class="checkmark"></span>
                                                                    </label>

                                                                    <label class="check-result"> <small> বাংলা উচ্চারন </small>
                                                                        <input type="checkbox" />
                                                                        <span class="checkmark"></span>
                                                                    </label>

                                                                </div>
                                                            </li> */}
                                                                            <li className="sellect">
                                                                                <div className="form-check-result">
                                                                                    <label class="check-result"> <small> আরবি </small>
                                                                                        <input type="checkbox" name="showAr" onChange={this.changeHandler} defaultChecked={this.state.showAr} />
                                                                                        <span class="checkmark"></span>
                                                                                    </label>

                                                                                    <label class="check-result"> <small> বাংলা অর্থ </small>
                                                                                        <input type="checkbox" name="showBn" onChange={this.changeHandler} defaultChecked={this.state.showBn} />
                                                                                        <span class="checkmark"></span>
                                                                                    </label>

                                                                                    <label class="check-result"> <small> বাংলা উচ্চারন </small>
                                                                                        <input type="checkbox" name="showBnU" onChange={this.changeHandler} defaultChecked={this.state.showBnU} />
                                                                                        <span class="checkmark"></span>
                                                                                    </label>

                                                                                </div>
                                                                            </li>
                                                                            {
                                                                                this.state.searchData.map((searchData_single, index) => {
                                                                                    let bn_data = searchData_single.text_bn;
                                                                                    if (has_query) {
                                                                                        bn_data = MATCH_REPLACE(bn_data, this.state.query)
                                                                                    }
                                                                                    return (
                                                                                        <li key={`sid_${searchData_single.id}`} onClick={() => this.goToSuraAyat(searchData_single)}>
                                                                                            <div className="result-list">
                                                                                                <div className="result-list-no">{convertEnglishToBanglaNumber(index + 1)}</div>
                                                                                                <div className="result-list-inner">
                                                                                                    <div className="result-list-inner-no">
                                                                                                        <span>সূরা নংঃ{convertEnglishToBanglaNumber(searchData_single.sura_no)},</span>
                                                                                                        <span>সূরাঃ {searchData_single.sura_name},</span>
                                                                                                        <span>আয়াত নংঃ{convertEnglishToBanglaNumber(searchData_single.sura_ayat_no)}</span>
                                                                                                    </div>
                                                                                                    {
                                                                                                        this.state.showAr ?
                                                                                                            <p className="arabic-result" dir="rtl" lang="ar">{searchData_single.text_ar}</p>
                                                                                                            : ''
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.showBnU ?
                                                                                                            <p className="pronountiation-result">{searchData_single.bn_pronunciation_text}</p>
                                                                                                            : ''
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.showBn ?
                                                                                                            <p className="bangla_result">{Parser(bn_data)}</p>
                                                                                                            : ''
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                this.state.pagination_loading ?
                                                                                    <div id="scroll_loading" className="text-center message_scroll_loading">
                                                                                        <p className="text-center"><i className="fas fa-spinner fa-spin fa-2x fa-fw"></i></p>
                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                        </ul>
                                                                    </Scrollbars>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        :
                                                        <div className="loading section-loading">{this.state.error}</div>
                                                }

                                            </div>

                                    }
                                </Fragment>
                        }


                    </div>

                    <div>
                    </div>

                </div>





            </Fragment >
        );
    }
}

export default withRouter(AlquranSearch);
