import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { AJAX_PUBLIC_REQUEST } from "../../Constants/AppConstants";
import { API_KEY, API_URL } from "../../Constants/AppConstants";
class AccountDeletion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "", // State to store the email address
      submitted: false, // State to track if the form has been submitted
      Message: "", // State to store error message, if any
      errorMessage: "", // State to store error message, if any
      FormLoading: false
    };
  }
  // Event handler for input change
  handleInputChange = (e) => {
    this.setState({ email: e.target.value });
  };
  
  // Event handler for form submission
  handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    this.setState({ FormLoading: true });
    const { email } = this.state;
    const formData = new FormData(); // Create FormData object
    formData.append("user_email", this.state.email); // Append form fields to FormData object
    formData.append("api_key", API_KEY); // Append form fields to FormData object
    
    try {
      // Make your API call here
      const response = await fetch(API_URL + "api/accountDeletionRequest", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }
      const responseData = await response.json();
      console.log(response);
      console.log(responseData);
      if (responseData.code == "200") {
        this.setState({
          submitted: true,
          Message: responseData.message,
          errorMessage: "",
        });
      } else {
        let error_message = responseData.message;
        this.setState({ submitted: false, errorMessage: error_message });
      }
      // If API call is successful, update state to show success message
    } catch (error) {
      // If there's an error, update state to show error message
      this.setState({ submitted: false, errorMessage: error.message });
    }
    this.setState({ FormLoading: false });
  };

  render() {
    const { submitted, Message, errorMessage } = this.state;
    console.log(this.props.match.params.token);
    // If the form has been submitted successfully, show success message
    if (submitted) {
      return (
        <Fragment>
          <div className="account-deletion-page">
            <div class="form-center">
              <div class="form-container">
                <div class="form-dictionary">
                  <h2>Account Deletion Request</h2>
                  <p>{Message}</p>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }

    // If there's an error, show error message
    let additionalContent = null;
    console.log(errorMessage);
    if (errorMessage) {
      additionalContent = (
        <div className="account-deletion-error mb-2">
          <p className="mt-3">{errorMessage}</p>
        </div>
      );
    }

    // Default render: show the form
    return (
      <Fragment>
         <React.Fragment>
              <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                    <main className="auth-section" >

                       <div className="para-title text-center mt-3"> Account Deletion Request </div>

                      <div className="auth-section-inner auth-section-center">
                          <div className="account-deletion-page">
                   
                                <div className="account-deletion-page">
                                  <form onSubmit={this.handleSubmit}>
                                    <div className="form-container">
                                      <div className="form-dictionary">
                                     
                                        <p className="mt-3">
                                          You can request to delete your account and its data
                                          permanently. By submitting the form, an email will be sent to
                                          verify the request. Please follow the instructions further in
                                          the email.
                                        </p>
                                      </div>
                                      {additionalContent}
                                      <div className="form-group">
                                        <input
                                          type="email"
                                          className="form-control"
                                          placeholder="Enter email address"
                                          value={this.state.email}
                                          onChange={this.handleInputChange}
                                          required
                                        />
                                      </div>
                                      
                                      <button className="auth-button" type="submit"  style={{width:'166px'}} disabled={this.state.FormLoading}>
                                      {
                                        this.state.FormLoading?
                                        <React.Fragment>
                                          <Spinner
                                           animation="border"
                                            size="sm"
                                            style={{marginRight:'7px'}}
                                          />
                                          Please Wait...
                                          </React.Fragment>
                                          :
                                          <React.Fragment>
                                          Submit
                                          </React.Fragment>
                                      }
                                      </button>
                                      
             
                                      
                                      
                                    </div>
                                  </form>
                                </div>
                          </div>
                      </div>
                    </main>
                </div>
              </div>
            </React.Fragment>
      </Fragment>
    );
  }
}

export default withRouter(AccountDeletion);
