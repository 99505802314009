import React, { Fragment, PureComponent } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import $ from "jquery";
import { connect } from "react-redux";
import { GET_STORAGE, USER, AJAX_PUBLIC_REQUEST, AJAX_REQUEST } from "../../Constants/AppConstants";
import { logout } from '../../Store/actions/loginActions';
import Parser from "html-react-parser";
import history from "../../history";
import * as Bootstrap from 'react-bootstrap';

class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            user: JSON.parse(GET_STORAGE(USER)),
            page: "",
            menus: [],
            showusermenu: false,
            show_modal: false,
            query: '',

            //advanced search
            sura_name: '',
            sura_name2: '',
            ayat_no: '',
            ayat_no2: '',
            para_name: '',
            para_name2: '',
        };

        setInterval(
            function () {
                if (this.props.auth) {
                    if (this.props.auth.user) {
                        // if (this.props.auth.user.remember) {
                        //     AJAX_REQUEST("POST", "user/updateAccessToken", {}).then(results => {
                        //         if (parseInt(results.response.code) === 1000) {
                        //             // console.log(results.response.code);
                        //         }
                        //     });
                        // }
                    }
                }
            }.bind(this),
            540000
        );
    }

    logout = (e) => {
        e.preventDefault();
        // AJAX_REQUEST("POST", "user/logout", {}).then(results => {
        //     if (parseInt(results.response.code) === 1000) { } else {
        //         // console.log(results.response.message);
        //     }
        // });
        this.props.logout();
    }

    componentDidMount() {
        const url = new URL(window.location.href)
        let params = new URLSearchParams(url.search)
        if (params) {
            if (params.has('query')) {
                this.setState({ query: params.get('query') })
            }
        }
    }


    toggleModal = (value) => {
        this.setState({ show_modal: value });
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    submitHandler = (e) => {
        e.preventDefault();
        const query = document.getElementById('query').value;
        if (query && (query.length > 0)) {
            history.push('/alquran-search?query=' + query);
        }
    }

    submitAdvancedSearch = (e, finder = 'sura') => {
        e.preventDefault();
        if (finder == 'sura') {
            if (this.state.sura_name.length > 0) {
                this.setState({ show_modal: false })
                history.push('/alquran-search?sura_name=' + this.state.sura_name);
            }
        }
        if (finder == 'sura_ayat') {
            if ((this.state.sura_name2.length > 0) && (this.state.ayat_no != '')) {
                this.setState({ show_modal: false })
                history.push('/alquran-search?sura_name=' + this.state.sura_name2 + '&ayat_no=' + this.state.ayat_no);
            }
        }
        if (finder == 'para') {
            if (this.state.para_name.length > 0) {
                this.setState({ show_modal: false })
                history.push('/alquran-search?para_name=' + this.state.para_name);
            }
        }
        if (finder == 'para_ayat') {
            if ((this.state.para_name2.length > 0) && (this.state.ayat_no2 != '')) {
                this.setState({ show_modal: false })
                history.push('/alquran-search?para_name=' + this.state.para_name2 + '&ayat_no=' + this.state.ayat_no2);
            }
        }
    }

    render() {
        return (
            <React.Fragment>

                <header id="header">
                    <div className="header-inner">
                        <div className="row">
                            {/* <div className="col-md-12 wow fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                                <div className="search-section">
                                    <form onSubmit={this.submitHandler}>
                                        <div className="form-group">
                                            <button className="search-button" type="submit"> <i className="icofont-search-1"></i> </button>
                                            <input type="search" name="query" id="query" onChange={this.changeHandler} value={this.state.query} className="form-control search-control" placeholder="র্সাচ"></input>
                                            <button type="button" className="advanced-search" onClick={() => this.toggleModal(true)}>এডভান্সড র্সাচ</button>

                                        </div>
                                    </form>
                                </div>
                            </div> */}

                            <div className="col-md-12 wow fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                                <div className="header-logo">
                                    <NavLink to="/" className="">
                                        <img src={require('../../Assets/images/header-logo.png')} alt="" title="" />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <Bootstrap.Modal className="advanced-search-modal" size="md" aria-labelledby="contained-modal-title-vcenter" centered show={this.state.show_modal} onHide={() => this.toggleModal(false)}>
                    <Bootstrap.Modal.Header>
                        <Bootstrap.Modal.Title id="contained-modal-title-vcenter">এডভান্সড র্সাচ</Bootstrap.Modal.Title>
                    </Bootstrap.Modal.Header>
                    <Bootstrap.Modal.Body>
                        <div className="advanced-search">
                            <Bootstrap.Form.Row>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="9">
                                    <Bootstrap.Form.Label>সুরা</Bootstrap.Form.Label>
                                    <Bootstrap.Form.Control type="text" name="sura_name" onChange={this.changeHandler} value={this.state.sura_name} placeholder="সুরার নাম লিখুন..." />
                                </Bootstrap.Form.Group>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                                    <Bootstrap.Form.Label>&nbsp; &nbsp; &nbsp;</Bootstrap.Form.Label>
                                    <Bootstrap.Button type="submit" className="searchresult-btn" onClick={(e) => this.submitAdvancedSearch(e, 'sura')}> খুঁজুন </Bootstrap.Button>
                                </Bootstrap.Form.Group>
                            </Bootstrap.Form.Row>

                            <Bootstrap.Form.Row>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="6">
                                    <Bootstrap.Form.Label>সুরা ও আয়াত</Bootstrap.Form.Label>
                                    <Bootstrap.Form.Control type="text" name="sura_name2" onChange={this.changeHandler} value={this.state.sura_name2} placeholder="সুরার নাম লিখুন..." />
                                </Bootstrap.Form.Group>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                                    <Bootstrap.Form.Label>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Bootstrap.Form.Label>
                                    <Bootstrap.Form.Control type="number" name="ayat_no" onChange={this.changeHandler} value={this.state.ayat_no} placeholder="আয়াত নম্বর" />
                                </Bootstrap.Form.Group>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                                    <Bootstrap.Form.Label> &nbsp; &nbsp; &nbsp;</Bootstrap.Form.Label>
                                    <Bootstrap.Button type="submit" className="searchresult-btn" onClick={(e) => this.submitAdvancedSearch(e, 'sura_ayat')} > খুঁজুন </Bootstrap.Button>
                                </Bootstrap.Form.Group>
                            </Bootstrap.Form.Row>

                            <Bootstrap.Form.Row>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="9">
                                    <Bootstrap.Form.Label>পারা</Bootstrap.Form.Label>
                                    <Bootstrap.Form.Control type="text" name="para_name" onChange={this.changeHandler} value={this.state.para_name} placeholder="পারার নাম লিখুন..." />
                                </Bootstrap.Form.Group>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                                    <Bootstrap.Form.Label>&nbsp; &nbsp; &nbsp;</Bootstrap.Form.Label>
                                    <Bootstrap.Button type="submit" className="searchresult-btn" onClick={(e) => this.submitAdvancedSearch(e, 'para')}> খুঁজুন </Bootstrap.Button>
                                </Bootstrap.Form.Group>
                            </Bootstrap.Form.Row>

                            <Bootstrap.Form.Row>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="6">
                                    <Bootstrap.Form.Label>পারা ও আয়াত</Bootstrap.Form.Label>
                                    <Bootstrap.Form.Control type="text" name="para_name2" onChange={this.changeHandler} value={this.state.para_name2} placeholder="পারার নাম লিখুন..." />
                                </Bootstrap.Form.Group>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                                    <Bootstrap.Form.Label>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Bootstrap.Form.Label>
                                    <Bootstrap.Form.Control type="number" name="ayat_no2" onChange={this.changeHandler} value={this.state.ayat_no2} placeholder="আয়াত নম্বর" />
                                </Bootstrap.Form.Group>
                                <Bootstrap.Form.Group as={Bootstrap.Col} md="3">
                                    <Bootstrap.Form.Label>&nbsp; &nbsp;</Bootstrap.Form.Label>
                                    <Bootstrap.Button type="submit" className="searchresult-btn" onClick={(e) => this.submitAdvancedSearch(e, 'para_ayat')}> খুঁজুন </Bootstrap.Button>
                                </Bootstrap.Form.Group>
                            </Bootstrap.Form.Row>

                        </div>
                    </Bootstrap.Modal.Body>
                </Bootstrap.Modal>

            </React.Fragment>
        );
    }
}

Header.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default withRouter(
    connect(
        mapStateToProps,
        { logout }
    )(Header)
);