import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_REQUEST, AJAX_PUBLIC_REQUEST, GET_STORAGE, USER } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import history from '../../history'
import { toast } from "react-toastify";

class AlquranDownload extends PureComponent {

  scrollbars = React.createRef();

  constructor(props) {
    super(props)

    this.state = {
      sura_loading: true,
      suraList: [],
      error: ''
    }
    document.title = "Download - Al Quran Technobd";
  }

  componentDidMount() {
    this.getSuraList();
  }



  getSuraList = async (loading_needed = true) => {
    if (loading_needed) {
      this.setState({ sura_loading: true });
    }
    const cur_storage = await JSON.parse(GET_STORAGE(USER));
    if (cur_storage) {
      AJAX_REQUEST("POST", "api/getSuraListV2", {}).then(results => {
        if (results.type === "success") {
          const sura = results.data;
          this.setState({
            suraList: sura,
            sura_loading: false,
          });
        } else {
          toast.error(results.error ? results.error : results.message.bangla)
          this.setState({
            sura_loading: false,
          })
        }
      });
    } else {
      AJAX_PUBLIC_REQUEST("POST", "api/getSuraListV2", {}).then(results => {
        if (results.type === "success") {
          const sura = results.data;
          this.setState({
            suraList: sura,
            sura_loading: false,
          });
        } else {
          toast.error(results.error ? results.error : results.message.bangla)
          this.setState({
            sura_loading: false,
          })
        }
      });
    }
  }

  donwloadSura = (e, type, id) => {
    e.preventDefault()
    if (this.props.isAuthenticated) {
      AJAX_REQUEST("POST", "api/downloadSura", {
        sura_type: type,
        sura_id: id
      }).then(results => {
        if (results.type === "success") {
          const download = results.data;
          if (download.link) {
            const targeted_file = document.getElementById('download_file')
            targeted_file.href = download.link;
            targeted_file.click()
            this.getSuraList(false);
            // window.open(download.link);
          } else {
            toast.error('Unknown error occured')
          }
        } else {
          toast.error(results.error ? results.error : results.message.bangla)
        }
      });
    } else {
      toast.error('Please Login First')
      history.push('/login?redirect=aquran-download')
    }
  }

  render() {

    return (
      <Fragment>
        <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
          <a href="#" id="download_file" className="d-none" download>download</a>
          <div className="col-sm-12 col-md-12">
            <div className="download-section">
              <div className="download-section-title">আল কোরআন ডাউনলোড</div>
              <div className="download-section-inner">
                <div className="download-heading">
                  <div className="download-heading-left">
                    <h5>সূরা</h5>
                  </div>
                  <div className="download-heading-right">
                    <h5>আরবি</h5>
                    <h5>বাংলা</h5>
                  </div>
                </div>
                <div className="nano">
                  <div className="nano-content">
                    <div className="para-item download-item">
                      {
                        this.state.sura_loading ?
                          <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i></div>
                          :

                          <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>

                            <ul>

                              {
                                this.state.suraList.length > 0 ?
                                  <Fragment>
                                    {
                                      this.state.suraList.map((suraList_single, index) => {
                                        return (
                                          <li key={`sura_no_${suraList_single.sura_no}`}>
                                            <div className="download-item-list">
                                              <div className="para-number">
                                                <span>{suraList_single.bn_sura_no}</span>
                                              </div>
                                              <div className="para-number-no">
                                                {suraList_single.name_bn}
                                                {/* <span>{suraList_single.name_en}</span> */}
                                                <span className="meaning_bn">{suraList_single.meaning_bn}</span>
                                                <span className="total_ayat_bn">মোট আয়াতঃ {suraList_single.total_ayat_bn} টি</span>
                                              </div>
                                            </div>

                                            <div className="download-item-para">
                                              {
                                                suraList_single.ar_donwloaded == "yes" ?
                                                  <a href="#" onClick={(e) => this.donwloadSura(e, 'arabic', suraList_single.id)} className="arbe-download"><span> ডাউনলোড </span> <i class="fas fa-check-double"></i></a>
                                                  :
                                                  <a href="#" onClick={(e) => this.donwloadSura(e, 'arabic', suraList_single.id)} className="arbe-download"><i class="fas fa-cloud-download-alt"></i> <span> ডাউনলোড </span></a>
                                              }
                                              {
                                                suraList_single.bn_donwloaded == "yes" ?
                                                  <a href="#" onClick={(e) => this.donwloadSura(e, 'bangla', suraList_single.id)} className="bangla-download"> <span> ডাউনলোড </span> <i class="fas fa-check-double"></i></a>
                                                  :
                                                  <a href="#" onClick={(e) => this.donwloadSura(e, 'bangla', suraList_single.id)} className="bangla-download"><i class="fas fa-cloud-download-alt"></i> <span> ডাউনলোড </span> </a>
                                              }
                                            </div>
                                          </li>
                                        )
                                      })
                                    }
                                  </Fragment>
                                  :
                                  <div className="error-msg">{this.state.sura_error}</div>
                              }

                              {/* <li>
                              <div className="download-item-list">
                                <div className="para-number">
                                  <span>১</span>
                                </div>
                                <div className="para-number-no">
                                  আল ফাতিহা
                                          <span>Al-Fatihah</span>
                                </div>
                              </div>

                              <div className="download-item-para">
                                <button className="arbe-download" type="button"><i classname="fas fa-cloud-download-alt"></i>ডাউনলোড </button>
                                <button className="bangla-download" type="button"><i classname="fas fa-cloud-download-alt"></i>ডাউনলোড </button>
                              </div>
                            </li> */}

                            </ul>

                          </Scrollbars>

                      }

                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div>
            </div>

          </div>
        </div>

      </Fragment>
    );
  }
}

AlquranDownload.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

// export default withRouter(AlquranDownload);
export default withRouter(connect(mapStateToProps, null)(AlquranDownload));