import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

export class HadithDetailsSingle extends Component {

    scrollbars = React.createRef();

    render() {
        return (
            <Fragment>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-sm-12 col-md-12">
                        <div className="download-section new-hadith-search-list">
                            <div className="hadith-name-list">
                                <ul>
                                    <li>হাদিস</li>
                                    <li><i class="icofont-simple-right"></i></li>
                                    <li>সহীহ বুখারী</li>
                                    <li><i class="icofont-simple-right"></i></li>
                                    <li> উযূ (كتاب الوضوء)</li>
                                </ul>
                            </div>
                            <div className="download-section-inner max-width-100 box-shadow-none">
                                <div className="nano">
                                    <div className="nano-content">
                                        <div className="para-item download-item">
                                            <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                <div className="max-width-830 new-hadith-search-details-item">
                                                    <div className="heading">
                                                        <h4>পরিচ্ছেদঃ ৪/২. পবিত্রতা ব্যতীত সালাত কবূল হবে না।</h4>
                                                        <h5>৪/১. উযূর বর্ণনা।</h5>
                                                    </div>
                                                    <NavLink className="details-item-info" to='/#'><i class="fas fa-info-circle"></i></NavLink>
                                                    <div className="new-hadith-search-details-rtl">
                                                        <p>وَقَوْلِ اللهِ تَعَالَى (إِذَا قُمْتُمْ إِلَى الصَّلاَةِ فَاغْسِلُوا وُجُوهَكُمْ وَأَيْدِيَكُمْ إِلَى الْمَرَافِقِ وَامْسَحُوا بِرُءُوسِكُمْ وَأَرْجُلَكُمْ إِلَى الْكَعْبَيْنِ)</p>
                                                        <p>
                                                            قَالَ أَبُو عَبْد اللهِ وَبَيَّنَ النَّبِيُّ صلى الله عليه وسلم أَنَّ فَرْضَ الْوُضُوءِ مَرَّةً مَرَّةً وَتَوَضَّأَ أَيْضًا مَرَّتَيْنِ وَثَلاَثًا وَلَمْ يَزِدْ عَلَى ثَلاَثٍ وَكَرِهَ أَهْل
الْعِلْمِ الْإِسْرَافَ فِيهِ وَأَنْ يُجَاوِزُوا فِعْلَ النَّبِيِّ صلى الله عليه وسلم.</p>
                                                    </div>
                                                    <div className="new-hadith-search-details-bangla">
                                                        <p>আল্লাহ তা‘আলার বাণীঃ (ওহে যারা ঈমান এনেছ!) তোমরা যখন সালাতের জন্য দাঁড়াতে চাও তখন ধৌত করে নিবে নিজেদের মুখমন্ডল এবং হাত
কনুই পর্যন্ত আর মাসেহ করে নিবে নিজেদের মস্তক এবং ধৌত করে নিবে নিজেদের পা গ্রন্থি পর্যন্ত। (সূরাহ্ আল-মায়িদাহ্ ৫/৬)</p>
                                                        <p>আবূ ‘আবদুল্লাহ্ বুখারী (রহ.) বলেন, নবী সাল্লাল্লাহু আলাইহি ওয়াসাল্লাম বলেছেনঃ উযূর ফরজ হ’ল এক-একবার করে ধোয়া। তিনি দু’-দু’বার করে
                                                        এবং তিন-তিনবার করেও উযূ করেছেন, কিন্তু তিনবারের অধিক ধৌত করেন নি। পানির অপচয় করা এবং নবী সাল্লাল্লাহু আলাইহি ওয়াসাল্লাম -এর
                            ‘আমলের সীমা অতিক্রম করাকে ‘উলামায়ে কিরাম মাকরূহ বলেছেন।</p>
                                                        <p>১৩৫. আবূ হুরাইরাহ (রাযি.) হতে বর্ণিত। তিনি বলেনঃ আল্লাহর রাসূল সাল্লাল্লাহু আলাইহি ওয়াসাল্লাম বলেছেনঃ ‘যে ব্যক্তির হাদাস হয় তার সালাত
                                                        কবূল হবে না, যতক্ষণ না সে উযূ করে। হাযরা-মাওতের জনৈক ব্যক্তি বলল, ‘হে আবূ হুরাইরাহ! হাদাস কী?’ হাদাস কী?’ তিনি বললেন, ‘নিঃশব্দে
বা সশব্দে বায়ু বের হওয়া।’ (৬৯৫৪; মুসলিম ২/২, হাঃ ২২৫, আহমাদ ৮০৮৪) (আধুনিক প্রকাশনীঃ ১৩২, ইসলামিক ফাউন্ডেশনঃ ১৩৭)</p>

                                                    </div>
                                                </div>

                                            </Scrollbars>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >

        )
    }
}

export default HadithDetailsSingle
