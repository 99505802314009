import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST, TITLE, convertEnglishToBanglaNumber } from '../../Constants/AppConstants';


import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';
import history from '../../history'

class HadithsMuslim extends PureComponent {

    scrollbars = React.createRef();
    scrollbars1 = React.createRef();
    scrollbars2 = React.createRef();

    constructor(props) {
        super(props)

        this.state = {
            hadith_name_loading: true,
            hadith_loading: true,
            ohi_loading: true,
            porissed_loading: true,
            hadithData: [],
            ohiData: [],
            porissedData: '',

            active_hadith: '',
            activeHadithData: '',
            activeOhiData: '',
            active_hadith_id: '',
            ohi_name_bn: 'ওহীর সূচনা',
            defaultActiveKey: 1,

            offset: 1,
            per_page: 20,
            total: 0,
            pagination_loading: false,
            error: '',

            active_section: 'hadith_name',

            // query 
            query_hadith_id: '',
            query_chapter_no: '',
            query_hadith_no: '',
        }
        document.title = "Muslim Hadith - " + TITLE;
    }

    componentDidMount() {
        this.setState({ hadith_name_loading: false })
        this.getMuslimHadithV2();
        const url = new URL(window.location.href)
        let params = new URLSearchParams(url.search)
        if (params && params.has('hadith_id') && params.has('chapter_no') && params.has('hadith_no')) {
            this.setState({ query_hadith_id: params.get('hadith_id'), defaultActiveKey: parseInt(params.get('chapter_no')), query_chapter_no: params.get('chapter_no'), query_hadith_no: params.get('hadith_no') })
            this.getMuslimHadithV2(params.get('hadith_id'));
        }
    }

    getMuslimHadithV2 = (active_hadith_id = false) => {
        this.setState({ active_hadith: 'সহীহ মুসলিম', active_section: 'hadith_muslim', hadith_loading: true, ohi_loading: true, porissed_loading: true })
        AJAX_PUBLIC_REQUEST("POST", "api/getMuslimHadithV2", {}).then(results => {
            if (results.type === "success") {
                const hadithData = results.data;
                this.setState({
                    hadithData: hadithData,
                    hadith_loading: false,
                    ohi_loading: false,
                    porissed_loading: false,
                });
                if (hadithData.length > 0) {
                    if (active_hadith_id) {
                        const first_hadithData = hadithData.filter((hadithData_single, index) => {
                            return hadithData_single.id == active_hadith_id;
                        });
                        if (first_hadithData && (first_hadithData.length > 0)) {
                            this.setState({ activeHadithData: first_hadithData[0], offset: 1 + this.state.per_page })
                            this.updateactiveHadithDataQuery(first_hadithData[0].id, first_hadithData[0])
                            // this.getMuslimHadithChapterV2(first_hadithData[0].id, 1);
                        }
                    } else {
                        const first_hadithData = hadithData[0];
                        if (first_hadithData) {
                            this.setState({ activeHadithData: first_hadithData, offset: 1 + this.state.per_page })
                        }
                    }
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    hadith_loading: false,
                    ohi_loading: false,
                    porissed_loading: false
                })
            }
        });
    }

    updateactiveHadithDataQuery = (hadith_id, activeHadithData) => {
        this.scrollbars1.current && this.scrollbars1.current.scrollToTop();
        this.setState({ offset: this.state.offset + parseInt(activeHadithData.total_chapter), total: activeHadithData.total_chapter, activeHadithData: activeHadithData, active_section: 'ohi', });
        this.getMuslimHadithChapterV2(hadith_id, 0, true);
        this.updateOhiName(activeHadithData)
    }

    updateactiveHadithData = (hadith_id, activeHadithData) => {
        this.scrollbars1.current && this.scrollbars1.current.scrollToTop();
        this.setState({ offset: 21, total: activeHadithData.total_chapter, activeHadithData: activeHadithData, active_section: 'ohi', });
        this.getMuslimHadithChapterV2(hadith_id, 1);
        this.updateOhiName(activeHadithData)
    }

    updateOhiName = (activeHadithData = false) => {
        let ohi_name_bn = 'ওহীর সূচনা';
        let activeHadithDataParam = this.state.activeHadithData;
        if (activeHadithData) {
            activeHadithDataParam = activeHadithData;
        }
        let title_res = activeHadithDataParam.title.split("/");
        if (title_res.length > 0) {
            let title_res2 = title_res[1].split("(");
            if (title_res2.length > 0) {
                ohi_name_bn = title_res2[0]
            }
        }

        this.setState({ ohi_name_bn })
    }

    getMuslimHadithChapterV2 = (hadith_id, offset = 1, load_hadith = false) => {
        this.setState({ ohi_loading: true, porissed_loading: true })
        AJAX_PUBLIC_REQUEST("POST", "api/getMuslimHadithChapterV2", { hadith_id: hadith_id, offset: offset }).then(results => {
            if (results.type === "success") {
                const ohiData = results.data;

                if (ohiData.length > 0) {
                    const url = new URL(window.location.href)
                    let params = new URLSearchParams(url.search)
                    if (load_hadith) {
                        this.getMuslimHadithByIdV2(params.get('hadith_no'), false);
                    } else {
                        const first_ohiData = ohiData[0];
                        if (first_ohiData) {
                            this.setState({ activeOhiData: first_ohiData })
                            if (first_ohiData.hadith_id && (first_ohiData.hadith_id.length > 0)) {
                                this.setState({ defaultActiveKey: parseInt(first_ohiData.id) })
                                // this.getMuslimHadithByIdV2(first_ohiData.hadith_id[0], first_ohiData)
                            }
                        }
                    }
                }
                this.setState({
                    ohiData: ohiData,
                    ohi_loading: false,
                    porissed_loading: false,
                    total: results.total_chapter
                });
                if (load_hadith) {
                    setTimeout(() => {
                        document.getElementById('ohi_chapter_' + this.state.defaultActiveKey).scrollIntoView();
                    }, 100)
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    ohi_loading: false,
                    porissed_loading: false,
                    total: 0
                })
            }
        });
    }

    getMuslimHadithByIdV2 = (hadith_no, ohiData_single) => {
        this.setState({ porissed_loading: true, active_section: 'hadith_no' })
        AJAX_PUBLIC_REQUEST("POST", "api/getMuslimHadithByIdV2", { hadith_no: hadith_no }).then(results => {
            if (results.type === "success") {
                const porissedData = results.data;
                this.setState({
                    porissedData: porissedData,
                    active_hadith_id: hadith_no,
                    porissed_loading: false,
                });
                if (ohiData_single) {
                    this.setState({ activeOhiData: ohiData_single })
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                    porissed_loading: false
                })
            }
        });
    }

    getMuslimHadithV2 = () => {
        this.setState({ active_hadith: 'সহীহ মুসলিম', active_section: 'hadith_muslim', hadith_loading: true, ohi_loading: true, porissed_loading: true })
        AJAX_PUBLIC_REQUEST("POST", "api/getMuslimHadithV2", {}).then(results => {
            console.log('results.message', results);
            if (results.type === "success") {
                const hadithData = results.data;
                this.setState({
                    hadithData: hadithData,
                    hadith_loading: false,
                    ohi_loading: false,
                    porissed_loading: false,
                });
                if (hadithData.length > 0) {
                    const first_hadithData = hadithData[0];
                    if (first_hadithData) {
                        this.setState({ activeHadithData: first_hadithData, offset: 1 })
                        this.getMuslimHadithChapterV2(first_hadithData.id, 0);
                    }
                }
            } else {
                this.setState({
                    error: Parser("<p classNameName='text-danger'>" + results.message + "</p>"),
                    hadith_loading: false,
                    ohi_loading: false,
                    porissed_loading: false
                })
            }
        });
    }

    handleScrollStop = async () => {
        if (this.scrollbars1.current) {
            // console.log('viewScrollTop', this.scrollbars1.current.viewScrollTop)
            // console.log('clientHeight', this.scrollbars1.current.view.clientHeight)
            // console.log('offsetHeight', document.getElementById('Cusscrollbars1-ul').offsetHeight)
            if ((this.scrollbars1.current.viewScrollTop + this.scrollbars1.current.view.clientHeight) === document.getElementById('Cusscrollbars1-ul').offsetHeight) {
                if (!this.state.pagination_loading) {
                    if (this.state.total > this.state.offset) {
                        this.setState({ pagination_loading: true });
                        setTimeout(() => {
                            document.getElementById('scroll_loading').scrollIntoView()
                        }, 10)
                        let data = {
                            offset: this.state.offset,
                            hadith_id: this.state.activeHadithData != '' ? this.state.activeHadithData.id : ''
                        }
                        AJAX_PUBLIC_REQUEST("POST", "api/getMuslimHadithChapterV2", data).then(async results => {
                            if (results.type === "success") {

                                const added_ohi_list = results.data;
                                // let new_ohi_list = [];
                                // new_ohi_list = this.state.ohiData;
                                // await added_ohi_list.map(async (added_ohi_list_single, index) => {
                                //     new_ohi_list.push(added_ohi_list_single)
                                //     console.log('added_ohi_list_single', added_ohi_list_single)
                                //     // const found = await new_ohi_list.filter((new_ohi_list_single, index) => {
                                //     //     return new_ohi_list_single.id == added_ohi_list_single.id
                                //     // })
                                //     // if (!found) {
                                //     //     new_ohi_list.push(added_ohi_list_single)
                                //     // }
                                // })

                                // const old_ohi_list = this.state.ohiData;
                                // let new_ohi_list = this.state.ohiData;
                                // await added_ohi_list.map(async (added_ohi_list_single, index) => {
                                //     const found = await new_ohi_list.filter((new_ohi_list_single, index) => {
                                //         return new_ohi_list_single.id == added_ohi_list_single.id
                                //     })
                                //     if (!found) {
                                //         new_ohi_list.push(added_ohi_list_single)
                                //     }
                                // })
                                const old_ohi_list = this.state.ohiData;
                                const new_ohi_list = old_ohi_list.concat(added_ohi_list);

                                this.setState({
                                    ohiData: new_ohi_list,
                                    offset: this.state.offset + this.state.per_page,
                                    pagination_loading: false,
                                });
                            } else {
                                this.setState({
                                    error: Parser("<p classNameName='text-danger'>" + results.error + "</p>"),
                                    pagination_loading: false,
                                    player_loading: false
                                })
                            }
                        });
                    }
                    console.log('pagination_loading', this.state.pagination_loading);
                }
            }
        }
    }

    setActiveSection = (active_section) => {
        console.log('clicked')
        this.setState({ active_section: active_section, active_hadith: '' });
    }

    handleSelect = (chapter_id) => {
        this.setState({ defaultActiveKey: chapter_id })
    }

    goToHadith = (hadith) => {
        if (hadith == 'bukhari') {
            history.push('/hadiths/bukhari')
        }
        if (hadith == 'muslim') {
            history.push('/hadiths/muslim')
        }
        if (hadith == 'all') {
            history.push('/hadiths')
        }
    }

    render() {
        let ohiData_single_index_in = 1;
        return (
            <Fragment>
                <div className="row wow fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                    <div className="col-md-12">
                        <div className="hadith-name-list">
                            <ul>
                                {
                                    this.state.active_section == 'hadith_name' ?
                                        // <li onClick={() => this.setActiveSection('hadith_name')}>হাদিস সমুহ</li>
                                        <li onClick={() => this.goToHadith('all')}>হাদিস সমুহ</li>
                                        : ''
                                }
                                {
                                    this.state.active_section == 'hadith_bukhari' ?
                                        <Fragment>
                                            <li onClick={() => this.goToHadith('all')}>হাদিস সমুহ</li>
                                            <li><i class="icofont-simple-right"></i></li>
                                            <li onClick={() => this.setActiveSection('hadith_bukhari')}>বুখারী শরীফ</li>
                                        </Fragment>
                                        : ''
                                }
                                {
                                    this.state.active_section == 'hadith_muslim' ?
                                        <Fragment>
                                            <li onClick={() => this.goToHadith('all')}>হাদিস সমুহ</li>
                                            <li><i class="icofont-simple-right"></i></li>
                                            <li onClick={() => this.getMuslimHadithV2}>সহীহ মুসলিম</li>
                                        </Fragment>
                                        : ''
                                }
                                {
                                    this.state.active_section == 'ohi' ?
                                        <Fragment>
                                            <li onClick={() => this.goToHadith('all')}>হাদিস সমুহ</li>
                                            <li><i class="icofont-simple-right"></i></li>
                                            <li onClick={() => this.setActiveSection('hadith_muslim')}>সহীহ মুসলিম</li>
                                            <li><i class="icofont-simple-right"></i></li>
                                            <li onClick={() => this.setActiveSection('ohi')}>{this.state.ohi_name_bn}</li>
                                        </Fragment>
                                        : ''
                                }
                                {
                                    this.state.active_section == 'hadith_no' ?
                                        <Fragment>
                                            <li onClick={() => this.goToHadith('all')}>হাদিস সমুহ</li>
                                            <li><i class="icofont-simple-right"></i></li>
                                            <li onClick={() => this.setActiveSection('hadith_muslim')}>সহীহ মুসলিম</li>
                                            <li><i class="icofont-simple-right"></i></li>
                                            <li onClick={() => this.setActiveSection('ohi')}>{this.state.ohi_name_bn}</li>
                                            <li><i class="icofont-simple-right"></i></li>
                                            <li onClick={() => this.setActiveSection('hadith_no')}>পরিচ্ছেদ</li>
                                        </Fragment>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">


                    <div className={classnames('col-sm-5 col-md-5 col-lg-5 col-xl-5 plr-2-5', { 'd-none': this.state.active_section != 'hadith_name' })}>
                        {/* <div className="para-title"> হাদিস সমুহ </div> */}
                        <div className="nano nano-dua">
                            <div className="nano-content">
                                <div className="para-item">
                                    <ul>
                                        {
                                            this.state.hadith_name_loading ?
                                                <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                                :
                                                <Fragment>
                                                    {/* <li className={classnames(null, { 'sellect': this.state.active_hadith === 'বুখারী শরীফ' })} onClick={this.getMuslimHadithV2}> */}
                                                    <li className={classnames(null, { 'sellect': this.state.active_hadith === 'বুখারী শরীফ' })} onClick={() => this.goToHadith('bukhari')}>
                                                        <div className="para-number">
                                                            <span>১</span>
                                                        </div>
                                                        <div className="para-number-no">
                                                            বুখারী শরীফ
                                                        </div>
                                                    </li>
                                                    {/* <li className={classnames(null, { 'sellect': this.state.active_hadith === 'সহীহ মুসলিম' })} onClick={this.getMuslimHadithV2}> */}
                                                    <li className={classnames(null, { 'sellect': this.state.active_hadith === 'সহীহ মুসলিম' })} onClick={() => this.goToHadith('muslim')}>
                                                        <div className="para-number">
                                                            <span>২</span>
                                                        </div>
                                                        <div className="para-number-no">
                                                            সহীহ মুসলিম
                                                        </div>
                                                    </li>
                                                </Fragment>
                                        }

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>


                    <Fragment>
                        <div className={classnames('col-sm-5 col-md-5 col-lg-5 col-xl-5 plr-2-5', { 'd-none': this.state.active_section != 'hadith_muslim' })}>
                            {/* <div className="para-title"> {this.state.active_hadith} </div> */}
                            {
                                this.state.hadith_loading ?
                                    <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                    :
                                    <div className="nano nano-dua">
                                        <div className="nano-content">
                                            <div className="para-item hadiths-item">
                                                <Scrollbars className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                    <ul>
                                                        {
                                                            this.state.hadithData.length > 0 ?
                                                                <Fragment>
                                                                    {
                                                                        this.state.hadithData.map((hadithData_single, index) => {
                                                                            const active_hadith_id = this.state.activeHadithData.id ? this.state.activeHadithData.id : '';
                                                                            return (
                                                                                <li key={`hadith_id_${hadithData_single.id}`} onClick={() => this.updateactiveHadithData(hadithData_single.id, hadithData_single)} className={classnames(null, { 'sellect': parseInt(hadithData_single.id) === parseInt(active_hadith_id) })}>
                                                                                    <div className="surah-item-left">
                                                                                        {/* <div className="para-number surah-number">
                                                                                                    <span>{hadithData_single.sl_bn}</span>
                                                                                                </div> */}
                                                                                        <div className="surah-number-no">
                                                                                            {hadithData_single.title}
                                                                                            <span>সর্বমোট হাদিস : {hadithData_single.total_bangla}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </Fragment>
                                                                : ''
                                                        }
                                                    </ul>
                                                </Scrollbars>
                                            </div>

                                        </div>
                                    </div>
                            }
                        </div>

                        <div className={classnames('col-sm-5 col-md-5 col-lg-5 col-xl-5 plr-2-5', { 'd-none': (this.state.active_section != 'ohi' && this.state.active_section != 'hadith_no') })}>
                            {/* <div className="para-title"> ওহীর সূচনা </div> */}
                            {
                                this.state.ohi_loading ?
                                    <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                    :
                                    <div className="nano nano-dua">
                                        <div className="nano-content">
                                            <div className="para-item hadiths-item">
                                                <Scrollbars onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars1" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars1}>
                                                    {
                                                        this.state.ohiData.length > 0 ?
                                                            <Bootstrap.Accordion onSelect={this.handleSelect} defaultActiveKey={this.state.defaultActiveKey} className="revelation">
                                                                <div id="Cusscrollbars1-ul">
                                                                    {
                                                                        this.state.ohiData.map((ohiData_single, ohiData_single_index) => {
                                                                            ohiData_single_index_in++;
                                                                            const active_ohi_id = this.state.activeOhiData.id ? this.state.activeOhiData.id : '';
                                                                            let first_hadith_id = "১";
                                                                            let last_hadith_id = "১";
                                                                            if (ohiData_single.hadith_id_bn && ohiData_single.hadith_id_bn.length > 0) {
                                                                                first_hadith_id = ohiData_single.hadith_id_bn[0];
                                                                            }
                                                                            if (ohiData_single.hadith_id_bn && ohiData_single.hadith_id_bn.length > 1) {
                                                                                last_hadith_id = ohiData_single.hadith_id_bn[ohiData_single.hadith_id_bn.length - 1];
                                                                            } else {
                                                                                last_hadith_id = first_hadith_id;
                                                                            }
                                                                            return (
                                                                                <Bootstrap.Card key={`ohi_id_${ohiData_single.id}`} id={`ohi_chapter_${ohiData_single.id}`} className={classnames(null, { 'card_active': (parseInt(ohiData_single.id) == this.state.defaultActiveKey) })}>
                                                                                    <Bootstrap.Card.Header className={classnames(null, { 'sellect': parseInt(ohiData_single.id) === parseInt(active_ohi_id) })}>
                                                                                        <Bootstrap.Accordion.Toggle as={Bootstrap.Button} variant="Button" eventKey={parseInt(ohiData_single.id)} className="revelation-button show">
                                                                                            <div className="revelation-icon"><span>{ohiData_single.sl_bn}</span></div>
                                                                                            <div className="revelation-text">
                                                                                                <div className="chapter-block">
                                                                                                    <div className="chapter-block-left">পরিচ্ছেদ {convertEnglishToBanglaNumber(ohiData_single.id)} </div>
                                                                                                    <div className="chapter-block-right">হাদিস নাম্বার {first_hadith_id}-{last_hadith_id}</div>
                                                                                                </div>
                                                                                                <div className="chapter-title">
                                                                                                    {ohiData_single.chapter_set_title}
                                                                                                </div>
                                                                                                <span className="collapse-icon">
                                                                                                    <i class="fas fa-chevron-circle-down"></i>
                                                                                                    {/* <i class="fas fa-chevron-circle-up"></i> */}
                                                                                                </span>
                                                                                            </div>
                                                                                        </Bootstrap.Accordion.Toggle>
                                                                                    </Bootstrap.Card.Header>
                                                                                    <Bootstrap.Accordion.Collapse eventKey={parseInt(ohiData_single.id)}>
                                                                                        <Bootstrap.Card.Body className="revelation-Body revelation-Body-new">
                                                                                            <ul>
                                                                                                {
                                                                                                    ohiData_single.hadith_id && ohiData_single.hadith_id_bn && (ohiData_single.hadith_id.length > 0) && (ohiData_single.hadith_id_bn.length > 0) && (ohiData_single.hadith_id.length == ohiData_single.hadith_id_bn.length) ?
                                                                                                        <Fragment>
                                                                                                            {
                                                                                                                ohiData_single.hadith_id.map((hadith_id_single, index) => {
                                                                                                                    return (<li className={classnames(null, { 'sellect': this.state.active_hadith_id == hadith_id_single })} key={`bn_${hadith_id_single}`} onClick={() => this.getMuslimHadithByIdV2(hadith_id_single, ohiData_single)}><span>হদিস নম্বর - {ohiData_single.hadith_id_bn[index]}</span></li>)
                                                                                                                })
                                                                                                            }
                                                                                                        </Fragment>
                                                                                                        : ''
                                                                                                }
                                                                                            </ul>
                                                                                        </Bootstrap.Card.Body>
                                                                                    </Bootstrap.Accordion.Collapse>
                                                                                </Bootstrap.Card>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Bootstrap.Accordion>
                                                            : ''
                                                    }
                                                    {
                                                        this.state.pagination_loading ?
                                                            <div id="scroll_loading" className="text-center message_scroll_loading">
                                                                <p className="text-center"><i className="fas fa-spinner fa-spin fa-2x fa-fw"></i></p>
                                                            </div>
                                                            : ''
                                                    }
                                                </Scrollbars>
                                            </div>

                                        </div>
                                    </div>
                            }
                        </div>


                        <div className={classnames('col-sm-7 col-md-7 col-lg-7 col-xl-7 plr-2-5', { 'd-none': this.state.active_section != 'hadith_no' })}>
                            {/* <div className="para-title">পরিচ্ছেদ</div> */}
                            {
                                this.state.porissed_loading ?
                                    <div className="loading section-loading"> <i class="fas fa-spinner fa-spin"></i> </div>
                                    :
                                    <div className="nano nano-dua">
                                        <div className="nano-content">
                                            <div className="para-item ayata-item">
                                                {
                                                    this.state.porissedData != '' ?
                                                        <Scrollbars className="CusScrollbars" id="CusScrollbars2" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars2}>
                                                            <ul>
                                                                <li className="sellect">
                                                                    <div className="ayata-item-box">
                                                                        <div className="hadiths-details">
                                                                            <div className="hadiths-title">{this.state.porissedData.bangla_title}</div>
                                                                            <div className="hadiths-text" dir="rtl" lang="ar">
                                                                                <strong>আরবি</strong>
                                                                                {this.state.porissedData.arabic_details}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="sellect">
                                                                    <div className="ayata-item-box">
                                                                        <div className="hadiths-details">
                                                                            <div className="hadiths-text">
                                                                                <strong>বাংলা অর্থ</strong>
                                                                                {this.state.porissedData.bangla_details}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </Scrollbars>

                                                        :
                                                        <div className="error"> No data found </div>
                                                }

                                            </div>
                                        </div>
                                    </div>

                            }

                        </div>

                    </Fragment>



                    <div>
                    </div>

                </div>

            </Fragment>
        );
    }
}

export default withRouter(HadithsMuslim);