import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import Parser from "html-react-parser";
import { AJAX_PUBLIC_REQUEST } from "../../Constants/AppConstants";

import * as Bootstrap from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars";

import ReactPlayer from "react-player";
import Duration from "../Common/Duration";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import classnames from "classnames";

class aboutUs extends PureComponent {
  scrollbars = React.createRef();

  render() {
    document.title = "About Us - Al Quran Technobd";
    return (
      <Fragment>
        <div
          className="row wow mlr-2-5 fadeInUp"
          data-wow-duration="500ms"
          data-wow-delay="900ms"
        >
          <div className="col-sm-12 col-md-12">
            <div className="download-section">
              <div className="download-section-title">About Us</div>
              <div className="download-section-inner">
                <div className="nano nano-dua">
                  <div className="nano-content">
                    <div className="para-item">
                      <Scrollbars
                        onScroll={this.handleScroll}
                        onScrollStop={this.handleScrollStop}
                        className="CusScrollbars"
                        id="CusScrollbars"
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        style={{ height: "100%" }}
                        ref={this.scrollbars}
                      >
                        <div className="disclaimer">
                          <p>
                            Welcome to alquran-bangla.com, an initiative of{" "}
                            <a
                              href="https://technobd.com/"
                              target="_blank"
                              style={{
                                color: "#a66d00",
                                textDecoration: "underline",
                              }}
                            >
                              Technobd Limited
                            </a>
                            , dedicated to being the beacon of spiritual
                            guidance for Bengali-speaking Muslims around the
                            world. At{" "}
                            <a
                              href="https://technobd.com/"
                              target="_blank"
                              style={{
                                color: "#a66d00",
                                textDecoration: "underline",
                              }}
                            >
                              Technobd Limited
                            </a>
                            , we recognize the significance of our faith and the
                            profound responsibilities that come with being a
                            Muslim in today's era. With this understanding, we
                            embarked on this noble journey to bridge our
                            cherished Islamic traditions with the modern digital
                            world, resulting in the creation of this invaluable
                            platform.
                          </p>
                          <p>
                            <strong style={{ fontSize: "20px" }}>
                              Our Purpose
                            </strong>
                          </p>{" "}
                          <p>
                            In the vastness of life's challenges, the Holy Quran
                            stands as an unwavering beacon of light, providing
                            solace, guidance, and wisdom. Recognizing the
                            language barriers that often distance believers from
                            the profound messages of the Quran,{" "}
                            <a
                              href="https://technobd.com/"
                              target="_blank"
                              style={{
                                color: "#a66d00",
                                textDecoration: "underline",
                              }}
                            >
                              Technobd Limited
                            </a>{" "}
                            has dedicated itself to bringing the teachings and
                            recitations of the Holy Quran closer to the
                            Bengali-speaking community worldwide. Through
                            alquran-bangla.com, our aspiration is to strengthen
                            faith, foster a deeper understanding of Islam, and
                            ignite the flame of spirituality in millions of
                            hearts.
                          </p>
                          <p>
                            <strong style={{ fontSize: "20px" }}>
                              Features of alquran-bangla.com
                            </strong>
                          </p>{" "}
                          <p>
                            <b>Dual-Language Quran:</b> Experience the Holy
                            Quran in its original Arabic and a carefully
                            translated Bengali version, ensuring the essence of
                            the divine word remains unaltered.
                          </p>
                          <p>
                            <b>Recitations by Renowned Qaris:</b> Delve into the
                            divine melodies of the Quran with recitations from
                            17 eminent Qaris, each bringing the scripture to
                            life with their unique voices.
                          </p>
                          <p>
                            <b>In-depth Tafsir in Bengali:</b> Navigate the
                            depths of the Holy Quran's teachings with our
                            comprehensive Tafsir, tailored exclusively for our
                            Bengali audience.
                          </p>
                          <p>
                            <b>Global Prayer Times:</b> Stay spiritually
                            connected and in tune with your prayer routines, no
                            matter where you are, with accurate prayer timings
                            for locations across the globe.
                          </p>
                          <p>
                            <b>Qibla Locator:</b> An essential tool for the
                            devout, our platform aids you in finding the Qibla's
                            direction, ensuring your prayers always face the
                            Holy Kaaba.
                          </p>
                          <p>
                            As we tread forward in this age of technological
                            innovation, let alquran-bangla.com, a proud creation
                            of{" "}
                            <a
                              href="https://technobd.com/"
                              target="_blank"
                              style={{
                                color: "#a66d00",
                                textDecoration: "underline",
                              }}
                            >
                              Technobd Limited
                            </a>
                            , serve as your spiritual ally. We warmly invite you
                            to embark on this journey of faith, introspection,
                            and spiritual ascension with us.
                          </p>
                        </div>
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(aboutUs);
