import validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function PasswordReset(data) {
  let errors = {};

  if (validator.isEmpty(data.user_email)) {
    errors.user_email = 'Email field is required';
  } else {
    if (!validator.isEmail(data.user_email)) {
      errors.user_email = 'Email field is not valid';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
    isFormValid: isEmpty(errors)
  }
}