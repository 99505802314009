import React, { PureComponent, Fragment } from 'react';
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import history from '../../history';
// import { logout } from '../../Store/actions/loginActions';
// import validateLogin from '../../Validations/Login';
import validateSignup from '../../Validations/Registration';
// import { userLoginRequest } from '../../Store/actions/loginActions';
import { userSignupRequest } from '../../Store/actions/signupActions';
// import AlertWrapper from '../Common/AlertWrapper';
// import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
// import AlertWrapperWarning from '../Common/AlertWrapperWarning';
import { Scrollbars } from 'react-custom-scrollbars';
// import "../../Assets/css/captcha.css";
import { toast } from "react-toastify";

import { AJAX_REQUEST, REMOVE_STORAGE, USER, GET_COOKIE, GET_STORAGE, TITLE } from '../../Constants/AppConstants';


class Login extends PureComponent {

    scrollbars = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            user_name: '',
            user_email: '',
            user_password: '',
            user_retype_password: '',
            loading: true,
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true
        }
        document.title = "Registration - " + TITLE;
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async componentDidMount() {
        document.querySelector("body").scrollIntoView();
        const cur_storage = await JSON.parse(GET_STORAGE(USER));
        if (cur_storage) {
            history.push('/profile');
        }
        this.setState({
            loading: false
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const form_data = {
            user_name: this.state.user_name,
            user_email: this.state.user_email,
            user_password: this.state.user_password,
            user_retype_password: this.state.user_retype_password,
            device_uuid: '43aa12c9696dfbc7'
        }
        const val_return = validateSignup(form_data);
        this.setState(val_return);
        console.log(val_return)
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true });
            this.props.userSignupRequest(form_data).then(results => {
                if (results.type === "success") {
                    toast.success(results.success ? results.success : results.message.bangla)
                    const url = new URL(window.location.href);
                    console.log('url', url);
                    const redirect = url.searchParams.get("redirect");
                    console.log('redirect', redirect);
                    if (redirect) {
                        history.push('/login?redirect=' + redirect)
                    } else {
                        history.push('/login');
                    }
                } else {
                    toast.error(results.error ? results.error : results.message.bangla)
                    this.setState({
                        // server_message: results.message,
                        isLoading: false,
                        isFormValid: false
                    });
                }
            }
            );
        } else {
            Object.entries(val_return.errors).map(([key, value]) => {
                toast.error(value)
            })
        }
    }

    render() {
        const { server_message, success_alert_wrapper_show, errors, isLoading, warning_wrapper_show, captchaCode } = this.state;
        const errors_data = server_message;

        if (this.props.isAuthenticated) {
            history.push('/');
        }

        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <React.Fragment>
                            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                                <div className="col-md-1"></div>
                                <div className="col-md-10">
                                    <main className="auth-section">
                                        <div className="para-title text-center">রেজিস্ট্রেশন করুন</div>
                                        <div className="auth-section-inner">
                                            <div className="nano nano-dua">
                                                <div className="nano-content">
                                                    <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                                                        <form className="action_form" onSubmit={this.onSubmit}>
                                                            <div className="reg-message">
                                                                <p>কোন কিছু পরিবর্তন বা সংযোজন হলে আপনাকে জানানোর জন্য নিচের তথ্য ব্যবহার করা হবে।</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="text" className={classnames("form-control", { 'pl_error_input': errors.user_name })} name="user_name" id="user_name" value={this.state.user_name} onChange={this.changeHandler} placeholder="আপনার নাম লিখুন" />
                                                            </div>
                                                            <div className="form-group">
                                                                <input type="text" className={classnames("form-control", { 'pl_error_input': errors.user_email })} name="user_email" id="user_email" value={this.state.user_email} onChange={this.changeHandler} placeholder="আপনার ইমেইল লিখুন" />
                                                            </div>
                                                            <div className="form-group">
                                                                <input className={classnames("form-control", { 'pl_error_input': errors.user_password })} type="password" name="user_password" id="user_password" value={this.state.user_password} onChange={this.changeHandler} placeholder="পাসওয়ার্ড দিন" />
                                                            </div>
                                                            <div className="form-group">
                                                                <input className={classnames("form-control", { 'pl_error_input': errors.user_retype_password })} type="password" name="user_retype_password" id="user_retype_password" value={this.state.user_retype_password} onChange={this.changeHandler} placeholder="পুনরায় পাসওয়ার্ড দিন" />
                                                            </div>
                                                            <div className="form-group auth-button-group text-center">
                                                                <button type="submit" disabled={this.state.isLoading} className="auth-button" name="" value="রেজিস্ট্রেশন">{this.state.isLoading ? 'অপেক্ষা করুন...' : 'রেজিস্ট্রেশন'}</button>
                                                            </div>
                                                            <div className="register-link text-center">
                                                                একাউন্ট আছে?  <NavLink activeClassName='active' to="/login">  লগিন করুন</NavLink>
                                                            </div>
                                                        </form>

                                                    </Scrollbars>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </main>
                                </div>


                            </div>
                        </React.Fragment>
                }
            </Fragment>
        );
    }
}

Login.propTypes = {
    // userLoginRequest: PropTypes.func.isRequired,
    userSignupRequest: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
    // logout: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}

// export default connect(mapStateToProps, { logout })(Login);

export default connect(mapStateToProps, { userSignupRequest })(Login);