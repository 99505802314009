import React, { Fragment, PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { AJAX_PUBLIC_REQUEST } from "../../Constants/AppConstants";
import { API_KEY, API_URL } from "../../Constants/AppConstants";
import { SET_STORAGE,DELETE_COOKIE,GET_STORAGE,DELETION_USER} from '../../Constants/AppConstants';
import history from '../../history';
import { toast } from "react-toastify";
class AccountRestore extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      Message: "",
      ErrorMessage: "",
    };
  }
  
  componentDidMount() {
    // Check if token is defined and has a value
    const delation_user = JSON.parse(GET_STORAGE(DELETION_USER));;
    if (typeof delation_user.token != 'undefined') {
      this.setState({ token: delation_user.token,Message :delation_user.Message});
    }else{
      history.push('/login');
    }
  }
  

  AcoountRestore = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    const { token } = this.state;
    const formData = new FormData(); // Create FormData object
    formData.append("api_key", API_KEY); // Append form fields to FormData object

    try {
      this.setState({ isLoading: true });
      // Make your API call here
      const response = await fetch(API_URL + "api/accountDeletionUndo", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }
      const responseData = await response.json();
      console.log(response);
      console.log(responseData);
      if (responseData.code == "200") {
          DELETE_COOKIE(DELETION_USER);
          toast.success(responseData.message)
          history.push("/login");
        
      } else {
        this.setState({ErrorMessage: responseData.message });
      }
      // If API call is successful, update state to show success message
    } catch (error) {
      // If there's an error, update state to show error message
      this.setState({ ErrorMessage: error.message });
    }
  };

  render() {
    // If the form has been submitted successfully, show success message
      return (
        <Fragment>
          <React.Fragment>
              <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                  <div className="col-md-1"></div>
                  <div className="col-md-10">
                      <main className="auth-section " >
                          {/* <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                          <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} /> */}
                          <div className="para-title text-center mt-3">ডিলিটেড অ্যাকাউন্ট</div>
                          <div className="auth-section-inner auth-section-center ">
                          <div className="account-deletion-page">
                              <form >
                                <div className="form-container">
                                  <div className="form-dictionary">
                                    <p className="mt-3">
                                      {this.state.Message}
                                    </p>
                                  </div>

                                  <button type="button" onClick={this.AcoountRestore} className="auth-button">
                                    Undo
                                  </button>
                                  {this.state.ErrorMessage?
                                  <React.Fragment>
                                    <p>{this.state.ErrorMessage}</p>
                                  </React.Fragment>
                                  :""}
                                </div>
                              </form>
                            </div>
                              
                          </div>
                          <div className="clearfix"></div>
                      </main>
                  </div>

              </div>
          </React.Fragment>
                
        </Fragment>
      );

    // Default render: show the form
    return (
      <Fragment>
        <div className="account-deletion-page">
          <p>Loading....</p>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AccountRestore);
