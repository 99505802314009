import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ReactImageFallback from "react-image-fallback";
import Parser from 'html-react-parser';
import { AJAX_PUBLIC_REQUEST } from '../../Constants/AppConstants';
import history from '../../history'

import * as Bootstrap from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import { Scrollbars } from 'react-custom-scrollbars';

import ReactPlayer from 'react-player';
import Duration from '../Common/Duration';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull';
import classnames from 'classnames';

class Disclaimer extends PureComponent {

  scrollbars = React.createRef();

  goToSearchDetails = (e) => {
    e.preventDefault()
    history.push('/new-hadith-search-list')
  }

  render() {
    document.title = "Disclaimer - Al Quran Technobd";
    return (
      <Fragment>
        <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
          <div className="col-sm-12 col-md-12">
            <div className="download-section new-hadith-search">
              <div className="download-section-title text-left">হাদিসে র্সাচ</div>
              <div className="download-section-inner max-width-100">
                <div className="new-hadith-search-form">
                  <Bootstrap.Form>
                    <Bootstrap.Form.Control type="text" placeholder="হাদিসে সার্চ করুন" />
                    <Bootstrap.Button variant="secondary" className="search-custom-btn-new" onClick={this.goToSearchDetails}>
                      <i class="fas fa-search"></i>
                    </Bootstrap.Button>
                  </Bootstrap.Form>
                </div>
                <div className="nano">
                  <div className="nano-content">
                    <div className="para-item download-item">
                      <Scrollbars onScroll={this.handleScroll} onScrollStop={this.handleScrollStop} className="CusScrollbars" id="CusScrollbars" autoHide autoHideTimeout={1000} autoHideDuration={200} style={{ height: '100%' }} ref={this.scrollbars}>
                        <div className="new-hadith-search-content">
                          <ul>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>নামায</strong>
                                  <span>১২৩ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>সালাতের ওয়াক্ত সমূহ</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>ওহীর সূচনা</strong>
                                  <span>১৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>ওজু</strong>
                                  <span>১২৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>সলম (অগ্রিম ক্রয় বিক্রয়)</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>জুম’আ</strong>
                                  <span>১৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>ঈমান</strong>
                                  <span>১৩৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>কুরআন তিলাওয়াতের সিজদা</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>সাক্ষ্য দান</strong>
                                  <span>১৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>সলম (অগ্রিম ক্রয় বিক্রয়)</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>জুম’আ</strong>
                                  <span>১৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>লাইলাতুল কদর এর ফযিলত</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>নামায</strong>
                                  <span>১২৩ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>সালাতের ওয়াক্ত সমূহ</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>ওহীর সূচনা</strong>
                                  <span>১৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>ওজু</strong>
                                  <span>১২৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>সলম (অগ্রিম ক্রয় বিক্রয়)</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>জুম’আ</strong>
                                  <span>১৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>ঈমান</strong>
                                  <span>১৩৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>কুরআন তিলাওয়াতের সিজদা</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>সাক্ষ্য দান</strong>
                                  <span>১৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>সলম (অগ্রিম ক্রয় বিক্রয়)</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>জুম’আ</strong>
                                  <span>১৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <div>
                                  <strong>লাইলাতুল কদর এর ফযিলত</strong>
                                  <span>১২৩৪৫ টি হাদিস</span>
                                </div>
                              </a>
                            </li>

                          </ul>
                        </div>
                      </Scrollbars>
                    </div>

                  </div>
                </div>
              </div>

            </div>

            <div>
            </div>

          </div>
        </div>

      </Fragment>
    );
  }
}

export default withRouter(Disclaimer);