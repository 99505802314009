import validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function contactPageValidation(data) {
  let errors = {};

  if (validator.isEmpty(data.name)) {
    errors.name = 'The name field is required';
  }
  if (validator.isEmpty(data.phone)) {
    errors.phone = 'The phone field is required';
  }
  if (validator.isEmpty(data.email)) {
    errors.email = 'The email field is required';
  }
  if (!validator.isEmail(data.email)) {
    errors.email = 'Email is invalid';
  }
  if (validator.isEmpty(data.message)) {
    errors.message = 'The message field is required';
  }
  return {
    errors,
    isValid: isEmpty(errors),
    isFormValid: isEmpty(errors)
  }
}