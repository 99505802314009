import React, { PureComponent } from 'react';
import { API_KEY } from '../../Constants/AppConstants';
import history from "../../history";

import PropTypes from "prop-types";
import classnames from 'classnames';
import { toast } from "react-toastify";
import validatePasswordReset from '../../Validations/PasswordReset';

class PasswordResetForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            user_email: '',
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: ''
        }
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const form_data = {
            user_email: this.state.user_email
        }
        const val_return = validatePasswordReset(form_data);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true });
            this.props.passwordResetRequest(form_data).then
                (results => {
                    if (results.type === "success") {
                        toast.success(results.success ? results.success : results.message.bangla)
                        this.setState({
                            isLoading: false,
                            user_email: ''
                        });
                    } else {
                        toast.error(results.error ? results.error : results.message.bangla)
                        this.setState({
                            // server_message: results.response.message,
                            isLoading: false,
                            isFormValid: false
                        });
                    }
                }
                );
        } else {
            Object.entries(val_return.errors).map(([key, value]) => {
                toast.error(value)
            })
        }
    }

    render() {
        const { errors, server_message } = this.state;
        const errors_data = server_message;
        return (
            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    <main className="auth-section">
                        <div className="para-title text-center">নতুন পাসওয়ার্ড</div>
                        <div className="auth-section-inner">

                            <form method="post" className="action_form" onSubmit={this.onSubmit}>
                                <div className="form-group">
                                    <input className={classnames("form-control", { 'pl_error_input': errors.user_email })} value={this.state.user_email} type="email" name="user_email" id="user_email" onChange={this.changeHandler} autoComplete="username" placeholder="আপনার ইমেইল লিখুন" />
                                </div>
                                <div className="form-group">
                                    <p className="new-password-note">আপনার ইমেইলে একটি লিংক সেন্ড করা হবে যা দিয়ে আপনি আপনার নতুন পাসওয়ার্ড সেট করতে পারবেন।</p>
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" disabled={this.state.isLoading} className="auth-button" value="নতুন পাসওয়ার্ড">{this.state.isLoading ? 'অপেক্ষা করুন...' : 'নতুন পাসওয়ার্ড'}</button>
                                </div>
                            </form>
                        </div>
                        <div className="clearfix"></div>
                    </main>
                </div>

            </div>
        );
    }
}

PasswordResetForm.propTypes = {
    passwordResetRequest: PropTypes.func.isRequired
}

export default PasswordResetForm;