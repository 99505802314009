import React, { PureComponent } from 'react';
import { API_KEY, API_URL } from '../../Constants/AppConstants';
import history from "../../history";
import { matchPath } from 'react-router';
import $ from "jquery";

import PropTypes from "prop-types";
import classnames from 'classnames';
import { toast } from "react-toastify";
import newValidatePasswordReset from '../../Validations/NewPasswordReset';
import FlashMessagesList from '../FlashMessages/FlashMessagesList';
import AlertWrapper from '../Common/AlertWrapper';

class NewPasswordResetForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            code: '',
            new_password: '',
            confirm_password: '',
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: ''
        }
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    componentDidMount() {
        const match = matchPath(history.location.pathname, {
            path: '/password-reset/:code',
            exact: true,
            strict: false
        })
        this.setState({ code: match.params.code })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const form_data = {
            code: this.state.code,
            new_password: this.state.new_password,
            confirm_password: this.state.confirm_password
        }
        const val_return = newValidatePasswordReset(form_data);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true });
            this.props.newPasswordResetRequest(form_data).then
                (results => {
                    if (results.type === "success") {
                        toast.success(results.success ? results.success : results.message.bangla)
                        history.push('/login');
                    } else {
                        toast.error(results.error ? results.error : results.message.bangla)
                        this.setState({
                            // server_message: results.response.message,
                            isLoading: false,
                            isFormValid: false
                        });
                    }
                }
                );
        } else {
            Object.entries(val_return.errors).map(([key, value]) => {
                toast.error(value)
            })
        }
    }

    render() {

        const { errors, server_message } = this.state;
        const errors_data = server_message;
        return (
            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                <div className="col-md-1"></div>
                <div className="col-md-10">
                    <main className="auth-section">
                        <div className="para-title text-center">পাসওয়ার্ড পরিবর্তন</div>
                        <div className="auth-section-inner">
                            <form method="post" className="action_form" onSubmit={this.onSubmit}>
                                <div className="form-group mb-3">
                                    <input className={classnames("form-control", { 'pl_error_input': errors.new_password })} type="password" name="new_password" id="new_password" onChange={this.changeHandler} autoComplete="false" placeholder="নতুন পাসওয়ার্ড লিখুন" />
                                </div>
                                <div className="form-group mb-3">
                                    <input className={classnames("form-control", { 'pl_error_input': errors.confirm_password })} type="password" name="confirm_password" id="confirm_password" onChange={this.changeHandler} autoComplete="false" placeholder="নতুন পাসওয়ার্ড পুনরায় লিখুন" />
                                </div>
                                <div className="form-group mt-5 text-center">
                                    <button type="submit" disabled={this.state.isLoading} className="auth-button" value="সেভ করুন">{this.state.isLoading ? 'অপেক্ষা করুন...' : 'সেভ করুন'}</button>
                                </div>
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

NewPasswordResetForm.propTypes = {
    newPasswordResetRequest: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired
}

export default NewPasswordResetForm;