import validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateSignup(data) {
  let errors = {};

  if (validator.isEmpty(data.user_name)) {
    errors.user_name = 'User Name field is required';
  }
  if (validator.isEmpty(data.user_email)) {
    errors.user_email = 'Email field is required';
  }
  if (!validator.isEmail(data.user_email)) {
    errors.user_email = 'Email is invalid';
  }
  if (validator.isEmpty(data.user_password)) {
    errors.user_password = 'Password field is required';
  }
  if (validator.isEmpty(data.user_retype_password)) {
    errors.user_retype_password = 'Retype Password field is required';
  } else {
    if (!validator.isEmpty(data.user_password) && (data.user_password != data.user_retype_password)) {
      errors.user_retype_password = 'Retype Password field is not matched';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors),
    isFormValid: isEmpty(errors)
  }
}