import React, { Fragment, PureComponent } from 'react';
import { API_KEY, API_URL } from '../../Constants/AppConstants';
import history from "../../history";
import { matchPath } from 'react-router';
import $ from "jquery";

import PropTypes from "prop-types";
import classnames from 'classnames';
import { toast } from "react-toastify";
import ProfilePasswordReset from '../../Validations/ProfilePasswordReset';
import FlashMessagesList from '../FlashMessages/FlashMessagesList';
import AlertWrapper from '../Common/AlertWrapper';
import { AJAX_REQUEST, TITLE, GET_STORAGE, USER } from '../../Constants/AppConstants';

class ProfilePasswordChange extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            old_password: '',
            new_password: '',
            retype_password: '',
            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: ''
        }
        document.title = "Change Password - " + TITLE;
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async componentDidMount() {
        const cur_storage = await JSON.parse(GET_STORAGE(USER));
        if (!cur_storage) {
            history.push('/login');
        }
        this.setState({ loading: false })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const form_data = {
            old_password: this.state.old_password,
            new_password: this.state.new_password,
            retype_password: this.state.retype_password
        }
        const val_return = ProfilePasswordReset(form_data);
        this.setState(val_return);
        if (val_return.isValid) {
            this.setState({ errors: {}, isLoading: true });
            const request_result = AJAX_REQUEST("POST", "api/changePasswordV2", form_data);
            request_result.then(results => {
                if (results.type === "success") {
                    toast.success(results.success ? results.success : results.message.bangla)
                    history.push('/profile');
                } else {
                    toast.error(results.error ? results.error : results.message.bangla)
                    this.setState({
                        // server_message: results.response.message,
                        isLoading: false,
                        isFormValid: false
                    });
                }
            }
            );
        } else {
            Object.entries(val_return.errors).map(([key, value]) => {
                toast.error(value)
            })
        }
    }

    render() {

        const { errors, server_message } = this.state;
        const errors_data = server_message;
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <main>
                                        <div className="page-content entry-content">
                                            <div className="row wow mlr-2-5 fadeInUp" data-wow-duration="500ms" data-wow-delay="900ms">
                                                <div className="col-md-1"></div>
                                                <div className="col-md-10">
                                                    <main className="auth-section">
                                                        <div className="para-title text-center">পাসওয়ার্ড পরিবর্তন</div>
                                                        <div className="auth-section-inner">
                                                            <form method="post" className="action_form" onSubmit={this.onSubmit}>
                                                                <div className="form-group mb-3">
                                                                    <input className={classnames("form-control", { 'pl_error_input': errors.old_password })} type="password" name="old_password" id="old_password" onChange={this.changeHandler} autoComplete="false" placeholder="আপনার পুরনো পাসওয়ার্ড লিখুন" />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <input className={classnames("form-control", { 'pl_error_input': errors.new_password })} type="password" name="new_password" id="new_password" onChange={this.changeHandler} autoComplete="false" placeholder="নতুন পাসওয়ার্ড লিখুন" />
                                                                </div>
                                                                <div className="form-group mb-3">
                                                                    <input className={classnames("form-control", { 'pl_error_input': errors.retype_password })} type="password" name="retype_password" id="retype_password" onChange={this.changeHandler} autoComplete="false" placeholder="নতুন পাসওয়ার্ড পুনরায় লিখুন" />
                                                                </div>
                                                                <div className="form-group mt-5 text-center">
                                                                    <button type="submit" disabled={this.state.isLoading} className="auth-button" value="সেভ করুন">{this.state.isLoading ? 'অপেক্ষা করুন...' : 'সেভ করুন'}</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </div>
                }
            </Fragment>
        );
    }
}

ProfilePasswordChange.propTypes = {
    newPasswordResetRequest: PropTypes.func.isRequired,
    addFlashMessage: PropTypes.func.isRequired
}

export default ProfilePasswordChange;