import validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function NewPasswordReset(data) {
  let errors = {};

  if (validator.isEmpty(data.new_password)) {
    errors.new_password = 'New Password field is required';
  }
  if (validator.isEmpty(data.confirm_password)) {
    errors.confirm_password = 'Confirm password field is required';
  }
  if (data.new_password !== data.confirm_password) {
    errors.confirm_password = 'Confirm password did not match';
  }

  return {
    errors,
    isValid: isEmpty(errors),
    isFormValid: isEmpty(errors)
  }
}